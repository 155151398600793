/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';


import { Observable } from 'rxjs';
import * as models from '../model/models';
// TODO: correctly add the JSON so that it can be read
import { HttpClient } from '../../shared/components/helpers/http-client';
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedDataService } from '../common/shared-data.service';
// import { USE_MOCK_SERVICE } from '../constants';
import { SharedFunctionService } from '../common/shared-function.service';
import { environment } from 'src/environments/environment';
import {map} from 'rxjs/operators'
import { HttpHeaders } from '@angular/common/http';

/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class DealerParticipationbService {
  public defaultHeaders = new HttpHeaders();;
  protected basePath = environment.BASE_SERVICE_URL;
  protected getVinInfoServicePath = environment.GET_VIN_INFO_SERVICE_URL;
  protected dealerParticipantServicePath = environment.DEALER_PARTICIPANT_SERVICE_URL;
  protected buySellServicePath = environment.BUYSELL_SERVICE_URL;
  // To populate the Base Path
  // protected basePath = BASE_SERVICE_URL + '/dealer-inventory';
  /***
   * Constructor of Inventory Detail Service to inject the angluar http
   */
  constructor( private logger: Logger,
    public sharedDataService: SharedDataService,
    protected httpClient: HttpClient,
    private sharedFuncService: SharedFunctionService) { }

  /***
 * This method is used to save the Vehicle Inventory Detail data
 * for the given VIN number
 */
  public saveDealerParticipation(dealerParticipant: models.DealerParticipant):
    Observable<models.DealerParticipant> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path = this.dealerParticipantServicePath;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions  = {
      method: 'PUT',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.DealerParticipant>(observer => {
        observer.next(dealerParticipant);
        observer.complete();
      });
    } else {
      return this.httpClient.put(path, dealerParticipant, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 201 || response.status === 200) {
            return response.body;
          } else
            return false;
        }));
    }
  }

  /***
 * This method is used to save the buy sell data
 * for the given dealers
 */
  public saveBuySell(dealerParticipant: any):
    Observable<models.DealerParticipant> {
    const path = this.buySellServicePath;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    let requestOptions  = {
      method: 'POST',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.DealerParticipant>(observer => {
        observer.next(dealerParticipant);
        observer.complete();
      });
    } else {
      return this.httpClient.post(path, dealerParticipant, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 201 || response.status === 200) {
            return response.body;
          } else
            return false;
        }));
    }
  }
}
