import { cloneDeep, isNil } from 'lodash';


import { ActionBase } from '../actions/actionbase';
import * as inventorySummaryActions from '../actions/vehicle-report.action';
import { UCInventorySummaryReport } from '../services/model/InventorySummaryReport';
import { Message, MessageType } from '../services/model/Message';
import { UCInventorySummaryView } from '../services/view-model/inventory-summary-view';
import * as moment from 'moment'

const timezone = 'America/Los_Angeles';
/**
 * Interface used to maintain the state for the inventory summary screen
 */
export interface UCReportState {
  inventoryData: UCInventorySummaryView;
}


export interface UCGstSetDataState {
  inventoryData: UCInventorySummaryView;
}
/**
 * Varibale used to store the initial state of UCInventoryState
 */
const initialState: UCReportState = {
  inventoryData: null
};


const initialState1: UCGstSetDataState = {
  inventoryData: null
};

/**
 * Method used to set the default initial state of UCInventoryState
 */
function setDefaulValue(): UCInventorySummaryView {
  let inventoryData: UCInventorySummaryView = <UCInventorySummaryView>new Object();
  inventoryData.inventories = [];
  inventoryData.message = null;
  return inventoryData;
}


export function gstreducer(state = initialState1, action: ActionBase): UCReportState {
  switch (action.type) {
    case inventorySummaryActions.ACTION_TYPES.GET_GST_SET_DATA_SUCCESS: {
      let gstData: UCInventorySummaryReport = {};
      gstData.reportData = {};
      gstData.reportData.data = [];
      if (action.payload.length > 0) {
        action.payload.map(data => {
          if (!isNil(data) && !isNil(data.reportData) && !isNil(data.reportData.data)) {
            gstData.reportData.data.push(data.reportData.data);
          }
        });
        gstData.type = action.payload[0].type;
      } else {
        gstData = action.payload;
      }

      let processedGst = cloneDeep(tempFrameGSTDetails(gstData));
      return {
        inventoryData: (<any>Object).assign({}, state.inventoryData,
          {
            GstData: processedGst,
            message: null
          })
      };

    }
    case inventorySummaryActions.ACTION_TYPES.GET_GST_SET_DATA_FAILURE: {
      return {
        inventoryData: (<any>Object).assign({}, state.inventoryData,
          {
            GstData: []
          })
      };
    }
    case inventorySummaryActions.ACTION_TYPES.CLEAR_ALL_ACTION: {
      return { inventoryData: setDefaulValue() };
    }
    default: {
      return state;
    }
  }
}

/**
 * Reducer method to handle the inventory summary screen actions
 */

export function reducer(state = initialState, action: ActionBase): UCReportState {
  switch (action.type) {
    case inventorySummaryActions.ACTION_TYPES.GET_INVENTORY_REPORT_SUCCESS: {
      let totCount;
      let startDate, p1StartDate = null, p2StartDate = null;
      let endDate, reportType;
      let vehicleData: UCInventorySummaryReport = {};
      vehicleData.reportData = {};
      vehicleData.reportData.data = [];
      if (action.payload.length > 0) {
        totCount = cloneDeep(action.payload[0].reportData.count);
        startDate = cloneDeep(action.payload[0].reportData.startDate);
        vehicleData.type = action.payload[0].type;
        endDate = cloneDeep(action.payload[0].reportData.endDate);
        if (!isNil(action.payload.reportData) && !isNil(action.payload.reportData.p1StartDate))
          p1StartDate = cloneDeep(action.payload.reportData.p1StartDate);
        if (!isNil(action.payload.reportData) && !isNil(action.payload.reportData.p2StartDate))
          p2StartDate = cloneDeep(action.payload.reportData.p2StartDate);
        action.payload.map(data => {
          if (!isNil(data) && !isNil(data.reportData) && !isNil(data.reportData.data)) {
            for (let i = 0; i < data.reportData.data.length; i++) {
              vehicleData.reportData.data.push(data.reportData.data[i]);
            }
          }
        });
      } else {
        totCount = cloneDeep(action.payload.reportData.count);
        startDate = cloneDeep(action.payload.reportData.startDate);
        endDate = cloneDeep(action.payload.reportData.endDate);
        if (!isNil(action.payload.reportData) && !isNil(action.payload.reportData.p1StartDate))
          p1StartDate = cloneDeep(action.payload.reportData.p1StartDate);
        if (!isNil(action.payload.reportData) && !isNil(action.payload.reportData.p2StartDate))
          p2StartDate = cloneDeep(action.payload.reportData.p2StartDate);
        vehicleData = action.payload;
      }
      let inventoryData = (<any>Object).assign({}, state.inventoryData,
        {
          inventories: cloneDeep(tempFrameInventoryDetails(vehicleData)),
          count: totCount,
          startDate: startDate,
          type: reportType,
          endDate: endDate,
          p1StartDate: p1StartDate,
          p2StartDate: p2StartDate,
          message: null
        });
      return { inventoryData: inventoryData };
    }
    case inventorySummaryActions.ACTION_TYPES.GET_INVENTORY_REPORT_FAIL: {
      let message: Message = {};
      message.message = 'No data available for the selected report.';
      message.type = MessageType.WARNING;
      let inventoryData = (<any>Object).assign({}, state.inventoryData,
        {
          message: message
        });
      return { inventoryData: inventoryData };
    }
    case inventorySummaryActions.ACTION_TYPES.CLEAR_ALL_ACTION: {
      return { inventoryData: setDefaulValue() };
    }
    default: {
      return state;
    }
  }
}

/**
 * Method to get the state of VehicleInventoryState for inventory summary screen
 */
export function getVehicleReport(state: UCReportState) {
  return state.inventoryData;
}

function tempFrameGSTDetails(inventories: any): UCInventorySummaryReport[] {
  let returnArray: UCInventorySummaryReport[] = [];
  inventories.reportData = inventories.reportData.data;
  let tempArray = [];
  // for (let i = 0; i < inventories.reportData.length; i++) {
  inventories.reportData.forEach(element => {
    let certificationObj, salesObjPercent, certObjPercent, totalLexusDaily,
      totalLexusMonthly, salesAvail, lexusTotalObj;

    certificationObj = (element.currentMonthRegionObjective * 1.1);

    if (Number(element.currentMonthRegionObjective) !== 0) {
      salesObjPercent = Number(
        (element.monthlyCertifiedSalesCount / element.currentMonthRegionObjective)
        * 100).toFixed(1);
    } else {
      salesObjPercent = 0;
    }

    if (Number(element.currentMonthRegionObjective) !== 0) {
      certObjPercent = Number(
        (element.monthlyCertifiedInventory / certificationObj) * 100).toFixed(1);
    } else {
      certObjPercent = 0;
    }

    totalLexusDaily = Number(element.dailyCertifiedSalesCount) +
      Number(element.dailyNonCertifiedSalesCount);
    totalLexusMonthly = Number(element.monthlyCertifiedSalesCount) +
      Number(element.monthlyNonCertifiedSalesCount);

    if (Number(element.currentMonthRegionObjective) !== 0) {
      lexusTotalObj =
        Number((totalLexusMonthly / element.currentMonthRegionObjective) * 100).toFixed(1);
    } else {
      lexusTotalObj = 0;
    }

    if (Number(element.certifiedInventory + element.monthlyCertifiedSalesCount) !== 0) {
      salesAvail = Number(((element.monthlyCertifiedSalesCount) /
        (element.certifiedInventory + element.monthlyCertifiedSalesCount) * 100)).toFixed(1);
    } else {
      salesAvail = 0;
    }

    if (!isNil(element.regionName)) {
      let item: any = {
        'regionNumber': element.regionNumber,
        'regionName': element.regionName,
        'modelDescription': null,
        'dailyCertifiedSalesCount': element.dailyCertifiedSalesCount,
        'monthlyCertifiedSalesCount': element.monthlyCertifiedSalesCount,
        'monthlyCertifiedReversalCount': element.monthlyCertifiedReversalCount,
        'dailyCertifiedInventory': element.dailyCertifiedInventory,
        'monthlyCertifiedInventory': element.monthlyCertifiedInventory,
        'inventoryLessThan60Days': element.inventoryLessThan60Days,
        'certificationTotal': element.certifiedInventory,
        'inventoryTotal': element.inventoryTotal,
        'salesObjective': element.currentMonthRegionObjective,
        'certificationObjective': Number.parseFloat(certificationObj).toFixed(0),
        'salesObjPercent': salesObjPercent,
        'certObjPercent': certObjPercent,
        'totalLexusDaily': totalLexusDaily,
        'totalLexusMonthly': totalLexusMonthly,
        'lexusTotalObj': element.currentMonthRegionObjective,
        'salesAvail': salesAvail,
        'certifiedInventory': element.certifiedInventory,
        'monthlyNonCertifiedSalesCount': element.monthlyNonCertifiedSalesCount,
        'dailyNonCertifiedSalesCount': element.dailyNonCertifiedSalesCount
      };
      tempArray.push(item);
    }
  });

  return tempArray;
}


function tempFrameInventoryDetails(inventories: any): UCInventorySummaryReport[] {
  let returnArray: UCInventorySummaryReport[] = [];
  let inventoryType = inventories.type;
  if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '2')) {
    inventories.reportData = inventories.reportData.data;
    for (let i = 0; i < inventories.reportData.length; i++) {
      let item: any = {
        'dealerCd': inventories.reportData[i].dealerCode,
        'count': inventories.reportData[i].count
      };
      returnArray.push(item);
    }
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '20')) {
    inventories.reportData = inventories.reportData.data;
    for (let i = 0; i < inventories.reportData.length; i++) {
      let item: any = {
        'dealerCd': inventories.reportData[i].dealerCode,
        'count': inventories.reportData[i].monthlySalesCountForTRAC
      };
      returnArray.push(item);
    }
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '21')) {
    inventories.reportData = inventories.reportData.data;
    for (let i = 0; i < inventories.reportData.length; i++) {
      let item: any = {
        'dealerCd': inventories.reportData[i].dealerCode,
        'count': inventories.reportData[i].yearlySalesCountForTRAC
      };
      returnArray.push(item);
    }
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '19')) {
    inventories.reportData = inventories.reportData.data;
    for (let count = 0; count < inventories.reportData.length; count++) {
      if (!isNil(inventories.reportData[count])) {
        let invObj = inventories.reportData[count];
        let rdrStatus, rdrDate, revDate;
        if (invObj.rdrStatus === 'H') {
          rdrStatus = 'SOLD';
          rdrDate = convertDateDisplay(invObj.createdDate);
          revDate = null;
        } else if (invObj.rdrStatus === 'R') {
          rdrStatus = 'RDR IN PROGRESS';
          rdrDate = convertDateDisplay(invObj.createdDate);
          revDate = null;
        } else {
          rdrStatus = 'REVERSED';
          rdrDate = convertDateDisplay(invObj.createdDate);
          revDate = convertDateDisplay(invObj.updatedDate);
        }
        let item: any = {
          'dealerCd': invObj.dealerCode,
          'dealerName': null,
          'districtNo': null,
          'salePersonId': invObj.salePersonId,
          'salePersonUID': invObj.salePersonUID,
          'vin': invObj.vin,
          'status': rdrStatus,
          'certificationNumber': invObj.certificationNumber,
          'saleDate': convertDateDisplay(invObj.retailDate),
          'rdrDate': rdrDate,
          'revDate': revDate,
        };
        returnArray.push(item);
      }
    }

    const groupBy = key => array =>
      inventories.reportData.reduce((objectsByKeyValue, obj) => {
        if (!isNil(obj)) {
          const value = obj[key];
          if (!isNil(obj[key])) {
            objectsByKeyValue[value] =
              (objectsByKeyValue[value] || []).concat(obj);
          }
          return objectsByKeyValue;
        }
      }, {});

    const groupByConsultant = groupBy('salePersonId');
    const groupByRegion = groupBy('regionNumber');
    let consultantGrouping = groupByConsultant(inventories.reportData);
    let regionGrouping = groupByRegion(inventories.reportData);
    let consultantGroupKeys = Object.keys(consultantGrouping);
    let consultantGroupValues: any[] = Object.values(consultantGrouping);
    let regionGroupKeys = Object.keys(regionGrouping);
    let regionGroupValues: any[] = Object.values(regionGrouping);
    let i, j;

    for (i = 0; i < consultantGroupKeys.length; i++) {
      let item1: any = {
        'salePersonUID': 'NA',
        'display': consultantGroupValues[i][0].salePersonId
      };
      let totals: any[] = consultantGroupValues[i]
        .reduce((details, item) => {
          details[item.rdrStatus] = details[item.rdrStatus] || 0;
          details[item.rdrStatus]++;
          return details;
        }, {});

      for (j = 0; j < Object.keys(totals).length; j++) {
        item1[Object.keys(totals)[j]] = Object.values(totals)[j];
        if (!isNil(item1['H']) && item1['V']) {
          item1['total'] = item1['H'] - item1['V'];
        } else if (!isNil(item1['H'])) {
          item1['total'] = item1['H'];
        } else if (!isNil(item1['V'])) {
          item1['total'] = item1['V'];
        }
      }

      returnArray.push(item1);
    }

    for (i = 0; i < regionGroupKeys.length; i++) {
      let item1: any = {
        'salePersonUID': 'NA',
        'display': regionGroupValues[i][0].regionName
      };
      let totals: any[] = regionGroupValues[i]
        .reduce((details, item) => {
          details[item.rdrStatus] = details[item.rdrStatus] || 0;
          details[item.rdrStatus]++;
          return details;
        }, {});

      for (j = 0; j < Object.keys(totals).length; j++) {
        item1[Object.keys(totals)[j]] = Object.values(totals)[j];
        if (!isNil(item1['H']) && item1['V']) {
          item1['total'] = item1['H'] - item1['V'];
        } else if (!isNil(item1['H'])) {
          item1['total'] = item1['H'];
        } else if (!isNil(item1['V'])) {
          item1['total'] = item1['V'];
        }
      }

      returnArray.push(item1);
    }

  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '18')) {
    inventories.reportData = inventories.reportData.data;
    let monthlySales = inventories.reportData.reduce(function (map, invoice) {
      let dealer = invoice.dealerCode;
      let mSalesCount = +invoice.monthlySalesCount;
      map[dealer] = (map[dealer] || 0) + mSalesCount;
      return map;
    }, {});

    let monthlyReversal = inventories.reportData.reduce(function (map, invoice) {
      let dealer = invoice.dealerCode;
      let mSalesCount = +invoice.monthlyReversalCount;
      map[dealer] = (map[dealer] || 0) + mSalesCount;
      return map;
    }, {});

    let monthlyTotal = inventories.reportData.reduce(function (map, invoice) {
      let dealer = invoice.dealerCode;
      let mSalesCount = +(invoice.monthlySalesCount - invoice.monthlyReversalCount);
      map[dealer] = (map[dealer] || 0) + mSalesCount;
      return map;
    }, {});

    let yearlySales = inventories.reportData.reduce(function (map, invoice) {
      let dealer = invoice.dealerCode;
      let mSalesCount = +invoice.yearlySalesCount;
      map[dealer] = (map[dealer] || 0) + mSalesCount;
      return map;
    }, {});

    let yearlyReversal = inventories.reportData.reduce(function (map, invoice) {
      let dealer = invoice.dealerCode;
      let mSalesCount = +invoice.yearlyReversalCount;
      map[dealer] = (map[dealer] || 0) + mSalesCount;
      return map;
    }, {});

    let yearlyTotal = inventories.reportData.reduce(function (map, invoice) {
      let dealer = invoice.dealerCode;
      let mSalesCount = +(invoice.yearlySalesCount - invoice.yearlyReversalCount);
      map[dealer] = (map[dealer] || 0) + mSalesCount;
      return map;
    }, {});

    let array: any = Object.keys(monthlySales).map(function (dealerCode) {
      return {
        'dealerCd': dealerCode,
        'districtNo': null,
        'salePersonUID': '',
        'salePersonId': 'TOTAL DEALER',
        'monthlySalesCount': monthlySales[dealerCode],
        'monthlyReversalCount': monthlyReversal[dealerCode],
        'monthlyTotal': monthlyTotal[dealerCode],
        'yearlySalesCount': yearlySales[dealerCode],
        'yearlyReversalCount': yearlyReversal[dealerCode],
        'yearlyTotal': yearlyTotal[dealerCode],
      };
    });

    for (let i = 0; i < inventories.reportData.length; i++) {
      let item: any = {
        'dealerCd': inventories.reportData[i].dealerCode,
        'districtNo': null,
        'salePersonUID': inventories.reportData[i].salePersonUID,
        'salePersonId': inventories.reportData[i].salePersonId,
        'monthlySalesCount': inventories.reportData[i].monthlySalesCount,
        'monthlyReversalCount': inventories.reportData[i].monthlyReversalCount,
        'monthlyTotal': (inventories.reportData[i].monthlySalesCount -
          inventories.reportData[i].monthlyReversalCount),
        'yearlyReversalCount': inventories.reportData[i].yearlyReversalCount,
        'yearlySalesCount': inventories.reportData[i].yearlySalesCount,
        'yearlyTotal': (inventories.reportData[i].yearlySalesCount -
          inventories.reportData[i].yearlyReversalCount)
      };
      returnArray.push(item);
    }

    for (let i = 0; i < array.length; i++) {
      returnArray.push(array[i]);
    }

  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '6' || inventories.type === '7' || inventories.type === '8'
      || inventories.type === '9')) {
    for (let i = 0; i < inventories.reportData.data.length; i++) {
      inventories.reportData.data[i].modelYearBuckets.forEach(element => {
        let avgTurnRate;
        let mixPercent;
        let inventoryMix;
        let avgReconditionCost;
        let avgTransPrice;

        if (element.turnRateDenominator !== 0 &&
          !isNaN((element.turnRateNumerator / element.turnRateDenominator))) {
          avgTurnRate = (element.turnRateNumerator / element.turnRateDenominator);
        } else avgTurnRate = 0;

        if (!isNaN((element.modelYearwiseTotalRDR /
          inventories.reportData.data[i].modelwiseTotalRDR))) {
          mixPercent =
            (element.modelYearwiseTotalRDR / inventories.reportData.totalRDR);
        } else mixPercent = 0;

        if (!isNaN((element.modelYearwiseTotalInventory /
          inventories.reportData.data[i].modelwiseTotalInventory))) {
          inventoryMix = (element.modelYearwiseTotalInventory /
            inventories.reportData.totalInventory);
        } else inventoryMix = 0;

        if (!isNaN(element.averageRetailAmount / element.modelYearwiseTotalRDR)) {
          avgTransPrice = (element.averageRetailAmount / element.modelYearwiseTotalRDR);
        } else avgTransPrice = 0;

        if (!isNaN(element.averageReconditionCost / element.sumOfReconditionCost)) {
          avgReconditionCost = (element.averageReconditionCost / element.sumOfReconditionCost);
        } else avgReconditionCost = 0;

        let item: any = {
          'model': inventories.reportData.data[i].modelDescription,
          'modelYear': element.modelYear,
          'totalRDR': element.modelYearwiseTotalRDR,
          'totalInventory': element.modelYearwiseTotalInventory,
          'avgTransPrice': avgTransPrice.toString(),
          'avgReconCost': avgReconditionCost.toString(),
          'avgTurnRate': parseFloat(avgTurnRate).toFixed(2),
          'mixPercent': mixPercent.toString(),
          'inventoryMix': inventoryMix.toString(),
        };
        returnArray.push(item);
      });
    }
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '11' || inventories.type === '12')) {
    inventories.reportData = inventories.reportData.data;
    for (let i = 0; i < inventories.reportData.length; i++) {
      inventories.reportData[i].modelYearBuckets.forEach(element => {
        let item: any = {
          'model': inventories.reportData[i].modelDescription,
          'modelYear': element.modelYear,
          'certifiedSales': element.certifiedSalsCount,
          'avgCertPrice': element.averageCertifiedRetailAmount,
          'nonCertifiedSales': element.nonCertifiedSalesCount,
          'avgNonCertPrice': element.averageNonCertifiedRetailAmount,
          'totalCount': element.totalCount,
        };
        returnArray.push(item);
      });
    }
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '13')) {
    inventories.reportData = inventories.reportData.data;
    for (let i = 0; i < inventories.reportData.length; i++) {
      inventories.reportData[i].modelDescriptions.forEach(element => {
        let avgcertAvail;
        let avgRTLAvail;
        let totalSalesDaily;
        let totalSalesMonthly;
        let totalSalesYearly;
        let perMonthObj;

        totalSalesDaily = Number(element.dailyCertifiedSalesCount) +
          Number(element.dailyNonCertifiedSalesCount);
        totalSalesMonthly = Number(element.monthlyCertifiedSalesCount) +
          Number(element.monthlyNonCertifiedSalesCount);
        totalSalesYearly = Number(element.yearlyCertifiedSalesCount) +
          Number(element.yearlyNonCertifiedSalesCount);

        if (!isNaN((element.certifiedInventory / element.currentInventory))) {
          avgcertAvail = Number((element.certifiedInventory / element.currentInventory)) * 100;
          avgcertAvail = Number.parseFloat(avgcertAvail).toFixed(1);
        } else avgcertAvail = 0;

        avgRTLAvail = (totalSalesMonthly / (totalSalesMonthly + element.currentInventory));
        if (totalSalesMonthly === 0 || (totalSalesMonthly + element.currentInventory) === 0) {
          avgRTLAvail = 0;
        } else {
          avgRTLAvail = Number.parseFloat(avgRTLAvail).toFixed(1);
        }

        if (totalSalesMonthly !== 0 && element.currentMonthDealerObjective !== 0 &&
          !isNaN(Number((totalSalesMonthly /
            inventories.reportData[i].currentMonthDealerObjective)))) {
          perMonthObj = Number((totalSalesMonthly /
            inventories.reportData[i].currentMonthDealerObjective) * 100).toFixed(2);
        } else perMonthObj = 0;

        let item: any = {
          'dealerCd': inventories.reportData[i].dealerCode,
          'districtNumber': inventories.reportData[i].districtNumber,
          'regionName': inventories.reportData[i].regionName,
          'modelDescription': element.modelDescription,
          'monthlyNonCertifiedSalesCount': element.monthlyNonCertifiedSalesCount,
          'yearlyWholeSalesCount': element.yearlyWholeSalesCount,
          'yearlyNonCertifiedSalesCount': element.yearlyNonCertifiedSalesCount,
          'yearlyCertifiedSalesCount': element.yearlyCertifiedSalesCount,
          'dailyCertifiedSalesCount': element.dailyCertifiedSalesCount,
          'dailyNonCertifiedSalesCount': element.dailyNonCertifiedSalesCount,
          'currentInventory': element.currentInventory,
          'monthlyCertifiedSalesCount': element.monthlyCertifiedSalesCount,
          'monthlyWholeSalesCount': element.monthlyWholeSalesCount,
          'dailyWholeSalesCount': element.dailyWholeSalesCount,
          'certifiedInventory': element.certifiedInventory,
          'avgcertAvail': avgcertAvail,
          'avgRTLAvail': avgRTLAvail,
          'totalLexusDaily': totalSalesDaily,
          'totalLexusMonthly': totalSalesMonthly,
          'totalLexusYearly': totalSalesYearly,
          'dealerName': null,
          'monthObj': inventories.reportData[i].currentMonthDealerObjective,
          'perMonthObj': perMonthObj
        };
        returnArray.push(item);
      });
    }
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '14')) {
    inventories.reportData = inventories.reportData.data;
    for (let i = 0; i < inventories.reportData.length; i++) {
      let item: any = {
        'dealerCd': inventories.reportData[i].dealerCode,
        'dealerName': null,
        'yearlyCertifiedInventory': inventories.reportData[i].yearlyCertifiedInventory,
        'yearlyCertifiedSalesCount': inventories.reportData[i].yearlyCertifiedSalesCount,
        'monthlyCertifiedSalesCount': inventories.reportData[i].monthlyCertifiedSalesCount,
        'monthlyCertifiedInventory': inventories.reportData[i].monthlyCertifiedInventory
      };
      returnArray.push(item);
    }
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '10')) {
    inventories.reportData = inventories.reportData.data;
    for (let i = 0; i < inventories.reportData.length; i++) {
      let salesDiff;
      let certDiff;
      let salesPercent;
      let certPercent;
      let certSalesRatio;
      let isVisible = false;
      salesPercent = (inventories.reportData[i].lastMonthRDR !== 0 &&
        inventories.reportData[i].currentMonthRDR !== 0) ?
        Number((inventories.reportData[i].currentMonthRDR /
          inventories.reportData[i].lastMonthRDR) * 100) : 0;
      certPercent = (inventories.reportData[i].lastMonthCertification !== 0 &&
        inventories.reportData[i].currentMonthCertification !== 0) ?
        Number((inventories.reportData[i].currentMonthCertification /
          inventories.reportData[i].lastMonthCertification) * 100) : 0;
      salesDiff = Number(inventories.reportData[i].currentMonthRDR -
        inventories.reportData[i].lastMonthRDR);
      certDiff = Number(inventories.reportData[i].currentMonthCertification -
        inventories.reportData[i].lastMonthCertification);
      certSalesRatio = (inventories.reportData[i].currentMonthCertification !== 0 &&
        inventories.reportData[i].currentMonthRDR !== 0) ?
        Number(inventories.reportData[i].currentMonthCertification /
          inventories.reportData[i].currentMonthCertifiedRDR) : 0;
      if ((inventories.reportData[i].lastMonthRDR -
        inventories.reportData[i].currentMonthRDR) >= 10) {
        isVisible = true;
      }
      let item: any = {
        'dealerCd': inventories.reportData[i].dealerCode,
        'dealerName': null,
        'lastMonthCertification': inventories.reportData[i].lastMonthCertification,
        'lastMonthRDR': inventories.reportData[i].lastMonthRDR,
        'currentMonthRDR': inventories.reportData[i].currentMonthRDR,
        'currentMonthCertification': inventories.reportData[i].currentMonthCertification,
        'currentInventory': inventories.reportData[i].currentInventory,
        'inventoryLessThan60Days': inventories.reportData[i].inventoryLessThan60Days,
        'salesDiff': salesDiff,
        'certDiff': certDiff,
        'salesPercent': Number(salesPercent).toFixed(0),
        'certPercent': certPercent,
        'isVisible': isVisible,
        'certSalesRatio': parseFloat(certSalesRatio).toFixed(2)
      };
      returnArray.push(item);
    }
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '15')) {
    inventories.reportData = inventories.reportData.data;
    inventories.reportData.forEach(element => {
      let item: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'RETAIL DAILY',
        'certified': element.dailyCertifiedSalesCount,
        'nonCertified': element.dailyNonCertifiedSalesCount,
        'total': element.dailyCertifiedSalesCount + element.dailyNonCertifiedSalesCount
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {
        item[element.modelDescriptions[i].modelDescription] =
          element.modelDescriptions[i].dailyCertifiedSalesCount;
      }
      returnArray.push(item);

      let item11: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'RETAIL MONTH OBJ.',
        'certified': element.currentMonthDealerObjective,
        'nonCertified': null,
        'total': null
      };
      returnArray.push(item11);

      let monthObjPercent;
      if (element.currentMonthDealerObjective !== 0 &&
        element.monthlyCertifiedSalesCount !== 0 &&
        !isNaN(Number((element.monthlyCertifiedSalesCount /
          element.currentMonthDealerObjective) * 100))) {
        monthObjPercent = Number((element.monthlyCertifiedSalesCount /
          element.currentMonthDealerObjective) * 100).toFixed(2);
      } else monthObjPercent = 0;
      let item12: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'RETAIL % OF MONTH OBJ.',
        'certified': monthObjPercent,
        'nonCertified': null,
        'total': null
      };
      returnArray.push(item12);

      let item2: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'RETAIL MTD',
        'certified': element.monthlyCertifiedSalesCount,
        'nonCertified': element.monthlyNonCertifiedSalesCount,
        'total': element.monthlyCertifiedSalesCount + element.monthlyNonCertifiedSalesCount
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {
        item2[element.modelDescriptions[i].modelDescription] =
          (element.modelDescriptions[i].monthlyCertifiedSalesCount +
            element.modelDescriptions[i].monthlyNonCertifiedSalesCount);
      }
      returnArray.push(item2);
      let item3: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'RETAIL YTD',
        'certified': element.yearlyCertifiedSalesCount,
        'nonCertified': element.yearlyNonCertifiedSalesCount,
        'total': element.yearlyCertifiedSalesCount + element.yearlyNonCertifiedSalesCount
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {
        item3[element.modelDescriptions[i].modelDescription] =
          element.modelDescriptions[i].yearlyCertifiedSalesCount;
      }
      returnArray.push(item3);
      let item4: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'RESALE DAILY',
        'certified': element.dailyCertifiedWholeSalesCount,
        'nonCertified': element.dailyNonCertifiedWholeSalesCount,
        'total': element.dailyCertifiedWholeSalesCount + element.dailyNonCertifiedWholeSalesCount
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {
        item4[element.modelDescriptions[i].modelDescription] =
          element.modelDescriptions[i].dailyCertifiedWholeSalesCount;
      }
      returnArray.push(item4);
      let item5: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'RESALE MTD',
        'certified': element.monthlyCertifiedWholeSalesCount,
        'nonCertified': element.monthlyNonCertifiedWholeSalesCount,
        'total': element.monthlyCertifiedWholeSalesCount + element.monthlyCertifiedWholeSalesCount
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {
        item5[element.modelDescriptions[i].modelDescription] =
          element.modelDescriptions[i].monthlyCertifiedWholeSalesCount;
      }
      returnArray.push(item5);
      let item6: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'RESALE YTD',
        'certified': element.yearlyCertifiedWholeSalesCount,
        'nonCertified': element.yearlyNonCertifiedWholeSalesCount,
        'total': element.yearlyCertifiedWholeSalesCount + element.yearlyNonCertifiedWholeSalesCount
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {
        item6[element.modelDescriptions[i].modelDescription] =
          element.modelDescriptions[i].yearlyCertifiedWholeSalesCount;
      }
      returnArray.push(item6);

      let item8: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'CERTIFIED SALES',
        'certified': null,
        'nonCertified': null,
        'total': null
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {
        item8[element.modelDescriptions[i].modelDescription] =
          element.modelDescriptions[i].monthlyCertifiedSalesCount;
      }
      returnArray.push(item8);
      let item9: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': '% CERTIFIED SALES/ TOTAL INVENTORY AVAIL',
        'certified': null,
        'nonCertified': null,
        'total': null
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {

        if (element.modelDescriptions[i].currentInventory !== 0 &&
          element.modelDescriptions[i].monthlyCertifiedSalesCount !== 0) {
          let percent = Number((element.modelDescriptions[i].monthlyCertifiedSalesCount /
            (element.modelDescriptions[i].monthlyCertifiedSalesCount +
              element.modelDescriptions[i].currentInventory)) * 100);
          item9[element.modelDescriptions[i].modelDescription] =
            percent.toFixed(1);
        } else {
          item9[element.modelDescriptions[i].modelDescription] = 0;
        }
      }
      returnArray.push(item9);
      let certRTL;
      let nonCertRTL;
      let totalRTL;

      if (!isNaN(Number(element.monthlyCertifiedSalesCount /
        (element.monthlyCertifiedSalesCount + element.currentInventory)))) {
        certRTL = Number(element.monthlyCertifiedSalesCount /
          (element.monthlyCertifiedSalesCount + element.currentInventory));
      } else {
        certRTL = 0;
      }

      if (!isNaN(Number(element.monthlyNonCertifiedSalesCount /
        (element.monthlyNonCertifiedSalesCount + element.currentInventory)))) {
        nonCertRTL = Number(element.monthlyNonCertifiedSalesCount /
          (element.monthlyNonCertifiedSalesCount + element.currentInventory));
      } else {
        nonCertRTL = 0;
      }

      if (!isNaN(Number((element.monthlyNonCertifiedSalesCount +
        element.monthlyCertifiedSalesCount) /
        ((element.monthlyNonCertifiedSalesCount +
          element.monthlyCertifiedSalesCount) + element.currentInventory) * 100))) {
        totalRTL = Number((element.monthlyNonCertifiedSalesCount +
          element.monthlyCertifiedSalesCount) /
          ((element.monthlyNonCertifiedSalesCount +
            element.monthlyCertifiedSalesCount) + element.currentInventory) * 100);
      } else {
        totalRTL = 0;
      }

      let item10: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': '% RETAIL MTD / TOTAL INVENTORY AVAIL',
        'certified': Number(certRTL).toFixed(1),
        'nonCertified': Number(nonCertRTL).toFixed(1),
        'total': Number(totalRTL).toFixed(1)
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {

        if (element.modelDescriptions[i].monthlyCertifiedSalesCount !== 0 &&
          element.modelDescriptions[i].currentInventory !== 0) {
          let percent = Number(((element.modelDescriptions[i].monthlyCertifiedSalesCount +
            element.modelDescriptions[i].monthlyNonCertifiedSalesCount) /
            (element.modelDescriptions[i].monthlyCertifiedSalesCount +
              element.modelDescriptions[i].monthlyNonCertifiedSalesCount +
              element.modelDescriptions[i].currentInventory)) * 100);
          item10[element.modelDescriptions[i].modelDescription] =
            percent.toFixed(1);
        } else {
          item10[element.modelDescriptions[i].modelDescription] = 0;
        }
      }
      returnArray.push(item10);

      let item7: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'type': 'TOTAL INVENTORY AVAIL',
        'certified': element.currentCertifiedInventory,
        'nonCertified': element.currentNonCertifiedInventory,
        'total': element.currentInventory
      };
      for (let i = 0; i < element.modelDescriptions.length; i++) {
        item7[element.modelDescriptions[i].modelDescription] =
          element.modelDescriptions[i].currentInventory;
      }
      returnArray.push(item7);

    });
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '16')) {
    inventories.reportData = inventories.reportData.data;
    let total = 0;
    let item: any = {
      'type': 'SALES',
      'metric': 'CYTD TCUV SALES'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item.hasOwnProperty(inventories.reportData[i].regionName)) {
          item[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlySalesCount;
        } else {
          item[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlySalesCount;
        }

        total += inventories.reportData[i].yearlySalesCount;
      }
    }
    item['TOTAL'] = total;
    returnArray.push(item);

    total = 0;
    let item1: any = {
      'type': 'SALES',
      'metric': 'OVER 65K MILES'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item1.hasOwnProperty(inventories.reportData[i].regionName)) {
          item1[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlySalesCountFor65k2012;
        } else {
          item1[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlySalesCountFor65k2012;
        }
        total += inventories.reportData[i].yearlySalesCountFor65k2012;
      }
    }
    item1['TOTAL'] = total;
    returnArray.push(item1);

    total = 0;
    let item2: any = {
      'type': 'SALES',
      'metric': 'MY2012&2011'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item2.hasOwnProperty(inventories.reportData[i].regionName)) {
          item2[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlySalesCountFor20112012;
        } else {
          item2[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlySalesCountFor20112012;
        }
        total += inventories.reportData[i].yearlySalesCountFor20112012;
      }
    }
    item2['TOTAL'] = total;
    returnArray.push(item2);

    total = 0;
    let item4: any = {
      'type': 'SALES',
      'metric': 'OVER65&MY2012&2011'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item4.hasOwnProperty(inventories.reportData[i].regionName)) {
          item4[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlySalesCountFor65k20112012;
        } else {
          item4[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlySalesCountFor65k20112012;
        }
        total += inventories.reportData[i].yearlySalesCountFor65k20112012;
      }
    }
    item4['TOTAL'] = total;
    returnArray.push(item4);

    total = 0;
    let item5: any = {
      'type': 'SALES',
      'metric': 'COMBINED'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item5.hasOwnProperty(inventories.reportData[i].regionName)) {
          item5[inventories.reportData[i].regionName] +=
            (inventories.reportData[i].yearlySalesCountFor65k2012 +
              inventories.reportData[i].yearlySalesCountFor20112012 +
              inventories.reportData[i].yearlySalesCountFor65k20112012);
        } else {
          item5[inventories.reportData[i].regionName] =
            (inventories.reportData[i].yearlySalesCountFor65k2012 +
              inventories.reportData[i].yearlySalesCountFor20112012 +
              inventories.reportData[i].yearlySalesCountFor65k20112012);
        }
        total += (inventories.reportData[i].yearlySalesCountFor65k2012 +
          inventories.reportData[i].yearlySalesCountFor20112012 +
          inventories.reportData[i].yearlySalesCountFor65k20112012);
      }
    }
    item5['TOTAL'] = total;
    returnArray.push(item5);

    total = 0;
    let item6: any = {
      'type': 'SALES',
      'metric': 'TRAC'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item6.hasOwnProperty(inventories.reportData[i].regionName)) {
          item6[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlySalesCountForTRAC;
        } else {
          item6[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlySalesCountForTRAC;
        }
        total += inventories.reportData[i].yearlySalesCountForTRAC;
      }
    }
    item6['TOTAL'] = total;
    returnArray.push(item6);

    total = 0;
    let item7: any = {
      'type': 'INVENTORY',
      'metric': 'YTD CERTIFICATION'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item7.hasOwnProperty(inventories.reportData[i].regionName)) {
          item7[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlyCertifiedCount;
        } else {
          item7[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlyCertifiedCount;
        }
        total += inventories.reportData[i].yearlyCertifiedCount;
      }
    }
    item7['TOTAL'] = total;
    returnArray.push(item7);

    total = 0;
    let item8: any = {
      'type': 'INVENTORY',
      'metric': 'OVER 65K MILES'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item8.hasOwnProperty(inventories.reportData[i].regionName)) {
          item8[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlyCertifiedCountFor65k2012;
        } else {
          item8[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlyCertifiedCountFor65k2012;
        }
        total += inventories.reportData[i].yearlyCertifiedCountFor65k2012;
      }
    }
    item8['TOTAL'] = total;
    returnArray.push(item8);

    total = 0;
    let item9: any = {
      'type': 'INVENTORY',
      'metric': 'MY2012&2011'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item9.hasOwnProperty(inventories.reportData[i].regionName)) {
          item9[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlyCertifiedCountFor20112012;
        } else {
          item9[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlyCertifiedCountFor20112012;
        }
        total += inventories.reportData[i].yearlyCertifiedCountFor20112012;
      }
    }
    item9['TOTAL'] = total;
    returnArray.push(item9);

    total = 0;
    let item10: any = {
      'type': 'INVENTORY',
      'metric': 'OVER65&MY2012&2011'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item10.hasOwnProperty(inventories.reportData[i].regionName)) {
          item10[inventories.reportData[i].regionName] +=
            inventories.reportData[i].yearlyCertifiedCountFor65k20112012;
        } else {
          item10[inventories.reportData[i].regionName] =
            inventories.reportData[i].yearlyCertifiedCountFor65k20112012;
        }
        total += inventories.reportData[i].yearlyCertifiedCountFor65k20112012;
      }
    }
    item10['TOTAL'] = total;
    returnArray.push(item10);

    total = 0;
    let item11: any = {
      'type': 'INVENTORY',
      'metric': 'COMBINED'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item11.hasOwnProperty(inventories.reportData[i].regionName)) {
          item11[inventories.reportData[i].regionName] +=
            (inventories.reportData[i].yearlyCertifiedCountFor65k2012 +
              inventories.reportData[i].yearlyCertifiedCountFor20112012 +
              inventories.reportData[i].yearlyCertifiedCountFor65k20112012);
        } else {
          item11[inventories.reportData[i].regionName] =
            (inventories.reportData[i].yearlyCertifiedCountFor65k2012 +
              inventories.reportData[i].yearlyCertifiedCountFor20112012 +
              inventories.reportData[i].yearlyCertifiedCountFor65k20112012);
        }
        total += (inventories.reportData[i].yearlyCertifiedCountFor65k2012 +
          inventories.reportData[i].yearlyCertifiedCountFor20112012 +
          inventories.reportData[i].yearlyCertifiedCountFor65k20112012);
      }
    }
    item11['TOTAL'] = total;
    returnArray.push(item11);
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '17')) {
    inventories.reportData = inventories.reportData.data;
    let total = 0;
    let item: any = {
      'type': 'SALES',
      'metric': 'MTD TCUV SALES'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item.hasOwnProperty(inventories.reportData[i].regionName)) {
          item[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlySalesCount;
        } else {
          item[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlySalesCount;
        }
        total += inventories.reportData[i].monthlySalesCount;
      }
    }
    item['TOTAL'] = total;
    returnArray.push(item);

    total = 0;
    let item1: any = {
      'type': 'SALES',
      'metric': 'OVER 65K MILES'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item1.hasOwnProperty(inventories.reportData[i].regionName)) {
          item1[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlySalesCountFor65k2012;
        } else {
          item1[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlySalesCountFor65k2012;
        }
        total += inventories.reportData[i].monthlySalesCountFor65k2012;
      }
    }
    item1['TOTAL'] = total;
    returnArray.push(item1);

    total = 0;
    let item2: any = {
      'type': 'SALES',
      'metric': 'MY2012&2011'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item2.hasOwnProperty(inventories.reportData[i].regionName)) {
          item2[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlySalesCountFor20112012;
        } else {
          item2[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlySalesCountFor20112012;
        }
        total += inventories.reportData[i].monthlySalesCountFor20112012;
      }
    }
    item2['TOTAL'] = total;
    returnArray.push(item2);

    total = 0;
    let item4: any = {
      'type': 'SALES',
      'metric': 'OVER65&MY2012&2011'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item4.hasOwnProperty(inventories.reportData[i].regionName)) {
          item4[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlySalesCountFor65k20112012;
        } else {
          item4[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlySalesCountFor65k20112012;
        }
        total += inventories.reportData[i].monthlySalesCountFor65k20112012;
      }
    }
    item4['TOTAL'] = total;
    returnArray.push(item4);

    total = 0;
    let item5: any = {
      'type': 'SALES',
      'metric': 'COMBINED'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item5.hasOwnProperty(inventories.reportData[i].regionName)) {
          item5[inventories.reportData[i].regionName] +=
            (inventories.reportData[i].monthlySalesCountFor65k2012 +
              inventories.reportData[i].monthlySalesCountFor20112012 +
              inventories.reportData[i].monthlySalesCountFor65k20112012);
        } else {
          item5[inventories.reportData[i].regionName] =
            (inventories.reportData[i].monthlySalesCountFor65k2012 +
              inventories.reportData[i].monthlySalesCountFor20112012 +
              inventories.reportData[i].monthlySalesCountFor65k20112012);
        }
        total += (inventories.reportData[i].monthlySalesCountFor65k2012 +
          inventories.reportData[i].monthlySalesCountFor20112012 +
          inventories.reportData[i].monthlySalesCountFor65k20112012);
      }
    }
    item5['TOTAL'] = total;
    returnArray.push(item5);

    total = 0;
    let item6: any = {
      'type': 'SALES',
      'metric': 'TRAC'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item6.hasOwnProperty(inventories.reportData[i].regionName)) {
          item6[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlySalesCountForTRAC;
        } else {
          item6[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlySalesCountForTRAC;
        }
        total += inventories.reportData[i].monthlySalesCountForTRAC;
      }
    }
    item6['TOTAL'] = total;
    returnArray.push(item6);

    total = 0;
    let item7: any = {
      'type': 'INVENTORY',
      'metric': 'MTD CERTIFICATION'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item7.hasOwnProperty(inventories.reportData[i].regionName)) {
          item7[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlyCertifiedCount;
        } else {
          item7[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlyCertifiedCount;
        }
        total += inventories.reportData[i].monthlyCertifiedCount;
      }
    }
    item7['TOTAL'] = total;
    returnArray.push(item7);

    total = 0;
    let item8: any = {
      'type': 'INVENTORY',
      'metric': 'OVER 65K MILES'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item8.hasOwnProperty(inventories.reportData[i].regionName)) {
          item8[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlyCertifiedCountFor65k2012;
        } else {
          item8[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlyCertifiedCountFor65k2012;
        }
        total += inventories.reportData[i].monthlyCertifiedCountFor65k2012;
      }
    }
    item8['TOTAL'] = total;
    returnArray.push(item8);

    total = 0;
    let item9: any = {
      'type': 'INVENTORY',
      'metric': 'MY2012&2011'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item9.hasOwnProperty(inventories.reportData[i].regionName)) {
          item9[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlyCertifiedCountFor20112012;
        } else {
          item9[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlyCertifiedCountFor20112012;
        }
        total += inventories.reportData[i].monthlyCertifiedCountFor20112012;
      }
    }
    item9['TOTAL'] = total;
    returnArray.push(item9);

    total = 0;
    let item10: any = {
      'type': 'INVENTORY',
      'metric': 'OVER65&MY2012&2011'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item10.hasOwnProperty(inventories.reportData[i].regionName)) {
          item10[inventories.reportData[i].regionName] +=
            inventories.reportData[i].monthlyCertifiedCountFor65k20112012;
        } else {
          item10[inventories.reportData[i].regionName] =
            inventories.reportData[i].monthlyCertifiedCountFor65k20112012;
        }
        total += inventories.reportData[i].monthlyCertifiedCountFor65k20112012;
      }
    }
    item10['TOTAL'] = total;
    returnArray.push(item10);

    total = 0;
    let item11: any = {
      'type': 'INVENTORY',
      'metric': 'COMBINED'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item11.hasOwnProperty(inventories.reportData[i].regionName)) {
          item11[inventories.reportData[i].regionName] +=
            (inventories.reportData[i].monthlyCertifiedCountFor65k2012 +
              inventories.reportData[i].monthlyCertifiedCountFor20112012 +
              inventories.reportData[i].monthlyCertifiedCountFor65k20112012);
        } else {
          item11[inventories.reportData[i].regionName] =
            (inventories.reportData[i].monthlyCertifiedCountFor65k2012 +
              inventories.reportData[i].monthlyCertifiedCountFor20112012 +
              inventories.reportData[i].monthlyCertifiedCountFor65k20112012);
        }
        total += (inventories.reportData[i].monthlyCertifiedCountFor65k2012 +
          inventories.reportData[i].monthlyCertifiedCountFor20112012 +
          inventories.reportData[i].monthlyCertifiedCountFor65k20112012);
      }
    }
    item11['TOTAL'] = total;
    returnArray.push(item11);
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '24')) {
    inventories.reportData = inventories.reportData.data;
    const groupBy = key => array =>
      inventories.reportData.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

    const groupByDistrict = groupBy('districtNumber');
    const groupByRegion = groupBy('regionNumber');

    let districtGrouping = groupByDistrict(inventories.reportData);
    let areaGrouping = groupByRegion(inventories.reportData);
    let districtArray = [];
    let areaArray = [];
    let i, j;
    let distGroupKeys = Object.keys(districtGrouping);
    let distGroupValues: any[] = Object.values(districtGrouping);
    let areaGroupKeys = Object.keys(areaGrouping);
    let areaGroupValues: any[] = Object.values(areaGrouping);

    for (i = 0; i < distGroupKeys.length; i++) {
      let item1: any = {
        'type': 'MTD DISTRICT',
        'dealerCd': 'NA',
        'display': distGroupValues[i][0].districtNumber
      };
      for (j = 0; j < distGroupValues[i].length; j++) {
        for (let k = 0; k < distGroupValues[i][j].modelDescriptions.length; k++) {
          if (item1.hasOwnProperty(
            distGroupValues[i][j].modelDescriptions[k].modelDescription)) {
            item1[distGroupValues[i][j].modelDescriptions[k].modelDescription] +=
              distGroupValues[i][j].modelDescriptions[k].monthlySalesCount;
          } else {
            item1[distGroupValues[i][j].modelDescriptions[k].modelDescription] =
              distGroupValues[i][j].modelDescriptions[k].monthlySalesCount;
          }
        }
      }

      returnArray.push(item1);
    }

    for (i = 0; i < areaGroupKeys.length; i++) {
      let item2: any = {
        'type': 'MTD AREA',
        'dealerCd': 'NA',
        'display': areaGroupValues[i][0].regionName
      };
      for (j = 0; j < areaGroupValues[i].length; j++) {
        for (let k = 0; k < areaGroupValues[i][j].modelDescriptions.length; k++) {
          if (item2.hasOwnProperty(
            areaGroupValues[i][j].modelDescriptions[k].modelDescription)) {
            item2[areaGroupValues[i][j].modelDescriptions[k].modelDescription] +=
              areaGroupValues[i][j].modelDescriptions[k].monthlySalesCount;
          } else {
            item2[areaGroupValues[i][j].modelDescriptions[k].modelDescription] =
              areaGroupValues[i][j].modelDescriptions[k].monthlySalesCount;
          }
        }
      }

      returnArray.push(item2);
    }

    let item3: any = {
      'type': 'MTD NATIONAL',
      'dealerCd': 'NA',
      'display': null
    };
    inventories.reportData.forEach(element => {
      for (let value = 0; value < element.modelDescriptions.length; value++) {
        if (item3.hasOwnProperty(element.modelDescriptions[value].modelDescription)) {
          item3[element.modelDescriptions[value].modelDescription] +=
            element.modelDescriptions[value].monthlySalesCount;
        } else {
          item3[element.modelDescriptions[value].modelDescription] =
            element.modelDescriptions[value].monthlySalesCount;
        }
      }
    });
    returnArray.push(item3);

    for (i = 0; i < distGroupKeys.length; i++) {
      let item1: any = {
        'type': 'YTD DISTRICT',
        'dealerCd': 'NA',
        'display': distGroupValues[i][0].districtNumber
      };
      for (j = 0; j < distGroupValues[i].length; j++) {
        for (let k = 0; k < distGroupValues[i][j].modelDescriptions.length; k++) {
          if (item1.hasOwnProperty(
            distGroupValues[i][j].modelDescriptions[k].modelDescription)) {
            item1[distGroupValues[i][j].modelDescriptions[k].modelDescription] +=
              distGroupValues[i][j].modelDescriptions[k].yearlySalesCount;
          } else {
            item1[distGroupValues[i][j].modelDescriptions[k].modelDescription] =
              distGroupValues[i][j].modelDescriptions[k].yearlySalesCount;
          }
        }
      }

      returnArray.push(item1);
    }

    for (i = 0; i < areaGroupKeys.length; i++) {
      let item2: any = {
        'type': 'YTD AREA',
        'dealerCd': 'NA',
        'display': areaGroupValues[i][0].regionName
      };
      for (j = 0; j < areaGroupValues[i].length; j++) {
        for (let k = 0; k < areaGroupValues[i][j].modelDescriptions.length; k++) {
          if (item2.hasOwnProperty(
            areaGroupValues[i][j].modelDescriptions[k].modelDescription)) {
            item2[areaGroupValues[i][j].modelDescriptions[k].modelDescription] +=
              areaGroupValues[i][j].modelDescriptions[k].yearlySalesCount;
          } else {
            item2[areaGroupValues[i][j].modelDescriptions[k].modelDescription] =
              areaGroupValues[i][j].modelDescriptions[k].yearlySalesCount;
          }
        }
      }

      returnArray.push(item2);
    }

    let item6: any = {
      'type': 'YTD NATIONAL',
      'dealerCd': 'NA',
      'display': null
    };
    inventories.reportData.forEach(element => {
      for (let value = 0; value < element.modelDescriptions.length; value++) {
        if (item6.hasOwnProperty(element.modelDescriptions[value].modelDescription)) {
          item6[element.modelDescriptions[value].modelDescription] +=
            element.modelDescriptions[value].yearlySalesCount;
        } else {
          item6[element.modelDescriptions[value].modelDescription] =
            element.modelDescriptions[value].yearlySalesCount;
        }
      }
    });
    returnArray.push(item6);

    inventories.reportData.forEach(element => {
      let item: any = {
        'dealerCd': element.dealerCode,
        'dealerName': null,
        'regionName': element.regionName,
        'districtNumber': element.districtNumber
      };
      for (let value = 0; value < element.modelDescriptions.length; value++) {
        item[element.modelDescriptions[value].modelDescription] =
          element.modelDescriptions[value].monthlySalesCount;
      }
      returnArray.push(item);
    });
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '25')) {
    inventories.reportData = inventories.reportData.data;
    let date = new Date();
    let total = 0;
    let item: any = {
      'type': 'DAILY SALES',
      'metric': (date.getMonth() + 1) + '/' + (date.getDate()) + '/' +
        (date.getFullYear()) + ' SALES'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item.hasOwnProperty(inventories.reportData[i].regionName)) {
          item[inventories.reportData[i].regionName] +=
            inventories.reportData[i].dailySalesCount;
        } else {
          item[inventories.reportData[i].regionName] =
            inventories.reportData[i].dailySalesCount;
        }

        total += inventories.reportData[i].dailySalesCount;
      }
    }
    item['TOTAL'] = total;
    returnArray.push(item);

    total = 0;
    let item1: any = {
      'type': 'MTD SALES',
      'metric': 'ACTUAL MTD'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item1.hasOwnProperty(inventories.reportData[i].regionName)) {
          item1[inventories.reportData[i].regionName] +=
            inventories.reportData[i].currentMonthSalesCount;
        } else {
          item1[inventories.reportData[i].regionName] =
            inventories.reportData[i].currentMonthSalesCount;
        }
        total += inventories.reportData[i].currentMonthSalesCount;
      }
    }
    item1['TOTAL'] = total;
    returnArray.push(item1);

    total = 0;
    let salesUs = 0;
    let item2: any = {
      'type': 'MTD SALES',
      'metric': 'SALES % U.S.'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (inventories.reportData[i].currentMonthSalesCount !== 0 && item1['TOTAL'] !== 0) {
        salesUs = (inventories.reportData[i].currentMonthSalesCount /
          item1['TOTAL']) * 100;
      } else salesUs = 0;
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item2.hasOwnProperty(inventories.reportData[i].regionName)) {
          item2[inventories.reportData[i].regionName] =
            (Number(item2[inventories.reportData[i].regionName]) +
              Number(salesUs)).toFixed(1);
        } else {
          item2[inventories.reportData[i].regionName] =
            Number(salesUs).toFixed(1);
        }

        total += salesUs;
      }
    }
    item2['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item2);

    total = 0;
    let item19: any = {
      'type': 'MTD SALES',
      'metric': 'MONTHLY OBJ.'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item19.hasOwnProperty(inventories.reportData[i].regionName)) {
          item19[inventories.reportData[i].regionName] =
            inventories.reportData[i].currentMonthRegionObjective;
        } else {
          item19[inventories.reportData[i].regionName] =
            inventories.reportData[i].currentMonthRegionObjective;
        }

        total += inventories.reportData[i].currentMonthRegionObjective;
      }
    }
    item19['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item19);

    total = 0;
    let objPercent = 0;
    let item3: any = {
      'type': 'MTD SALES',
      'metric': 'MONTHLY % OBJ.'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (inventories.reportData[i].currentMonthSalesCount !== 0 &&
        inventories.reportData[i].currentMonthRegionObjective !== 0) {
        objPercent = (inventories.reportData[i].currentMonthSalesCount /
          inventories.reportData[i].currentMonthRegionObjective) * 100;
      } else objPercent = 0;
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item3.hasOwnProperty(inventories.reportData[i].regionName)) {
          item3[inventories.reportData[i].regionName] =
            (Number(item3[inventories.reportData[i].regionName]) +
              Number(objPercent)).toFixed(1);
        } else {
          item3[inventories.reportData[i].regionName] =
            Number(objPercent).toFixed(1);
        }

        total += objPercent;
      }
    }
    item3['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item3);

    let item18: any = {
      'type': 'MTD SALES',
      'metric': 'MONTHLY % OBJ. U.S. RANK'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item18.hasOwnProperty(inventories.reportData[i].regionName)) {
          if (!isNil(sortObj(item3, inventories.reportData[i].regionName))) {
            item18[inventories.reportData[i].regionName] =
              sortObj(item3, inventories.reportData[i].regionName) + 1;
          } else {
            item18[inventories.reportData[i].regionName] = 0;
          }
        } else {
          if (!isNil(sortObj(item3, inventories.reportData[i].regionName))) {
            item18[inventories.reportData[i].regionName] =
              sortObj(item3, inventories.reportData[i].regionName) + 1;
          } else {
            item18[inventories.reportData[i].regionName] = 0;
          }
        }

      }
    }
    returnArray.push(item18);

    total = 0;
    let priorMonth = 0;
    let item15: any = {
      'type': 'MTD SALES',
      'metric': '% PRIOR MONTH'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (inventories.reportData[i].priorMonthSalesCount !== 0 &&
        inventories.reportData[i].priorMonthRegionObjective !== 0) {
        priorMonth = (inventories.reportData[i].priorMonthSalesCount /
          inventories.reportData[i].priorMonthRegionObjective) * 100;
      } else priorMonth = 0;
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item15.hasOwnProperty(inventories.reportData[i].regionName)) {
          item15[inventories.reportData[i].regionName] =
            (Number(item15[inventories.reportData[i].regionName]) +
              Number(priorMonth)).toFixed(1);
        } else {
          item15[inventories.reportData[i].regionName] =
            Number(priorMonth).toFixed(1);
        }

        total += priorMonth;
      }
    }
    item15['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item15);

    total = 0;
    let priorYear = 0;
    let item16: any = {
      'type': 'MTD SALES',
      'metric': '% PRIOR YEAR'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (inventories.reportData[i].currentMonthSalesCount !== 0 &&
        inventories.reportData[i].currentMonthRegionObjective !== 0) {
        priorYear = (inventories.reportData[i].currentMonthSalesCount /
          inventories.reportData[i].currentMonthRegionObjective) * 100;
      } else priorYear = 0;
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item16.hasOwnProperty(inventories.reportData[i].regionName)) {
          item16[inventories.reportData[i].regionName] =
            (Number(item16[inventories.reportData[i].regionName]) +
              Number(priorYear)).toFixed(1);
        } else {
          item16[inventories.reportData[i].regionName] =
            Number(priorYear).toFixed(1);
        }

        total += priorYear;
      }
    }
    item16['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item16);

    total = 0;
    let item5: any = {
      'type': 'INVENTORY',
      'metric': 'DEALER STOCK'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item5.hasOwnProperty(inventories.reportData[i].regionName)) {
          item5[inventories.reportData[i].regionName] +=
            (inventories.reportData[i].currentMonthInventoryCount);
        } else {
          item5[inventories.reportData[i].regionName] =
            (inventories.reportData[i].currentMonthInventoryCount);
        }
        total += (inventories.reportData[i].currentMonthInventoryCount);
      }
    }
    item5['TOTAL'] = total;

    let salesAvail = 0;
    total = 0;
    let item14: any = {
      'type': 'MTD SALES',
      'metric': 'SALES/AVAIL'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item1[inventories.reportData[i].regionName] !== 0 &&
          item5[inventories.reportData[i].regionName] !== 0) {
          salesAvail = Number((item1[inventories.reportData[i].regionName] /
            (item5[inventories.reportData[i].regionName] +
              item1[inventories.reportData[i].regionName])) * 100);
        } else salesAvail = 0;
        item14[inventories.reportData[i].regionName] = Number(salesAvail).toFixed(1);

        if (item1['TOTAL'] !== 0 &&
          item5['TOTAL'] !== 0) {
          total = Number((item1['TOTAL'] /
            (item1['TOTAL'] + item5['TOTAL'])) * 100);
        } else total = 0;
      }
    }
    item14['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item14);

    total = 0;
    let item4: any = {
      'type': 'INVENTORY',
      'metric': 'CERTIFICATIONS'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item4.hasOwnProperty(inventories.reportData[i].regionName)) {
          item4[inventories.reportData[i].regionName] +=
            inventories.reportData[i].currentMonthCertificationCount;
        } else {
          item4[inventories.reportData[i].regionName] =
            inventories.reportData[i].currentMonthCertificationCount;
        }
        total += inventories.reportData[i].currentMonthCertificationCount;
      }
    }
    item4['TOTAL'] = total;
    returnArray.push(item4);

    returnArray.push(item5);

    total = 0;
    let dealerUs = 0;
    let item6: any = {
      'type': 'INVENTORY',
      'metric': 'DEALER STOCK %U.S.'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (inventories.reportData[i].currentMonthSalesCount !== 0 && item5['TOTAL'] !== 0) {
        dealerUs = (inventories.reportData[i].currentMonthInventoryCount /
          item5['TOTAL']) * 100;
      } else dealerUs = 0;
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item6.hasOwnProperty(inventories.reportData[i].regionName)) {
          item6[inventories.reportData[i].regionName] =
            (Number(item6[inventories.reportData[i].regionName]) +
              Number(dealerUs)).toFixed(1);
        } else {
          item6[inventories.reportData[i].regionName] =
            Number(dealerUs).toFixed(1);
        }
        total += dealerUs;
      }
    }
    item6['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item6);

    total = 0;
    let dealerDs = 0;
    let item17: any = {
      'type': 'INVENTORY',
      'metric': 'DEALER STOCK D/S'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (inventories.reportData[i].currentMonthSalesCount !== 0 &&
        inventories.reportData[i].currentMonthInventoryCount !== 0) {
        dealerDs = (inventories.reportData[i].currentMonthInventoryCount /
          inventories.reportData[i].currentMonthSalesCount)
          * (daysInThisMonth() - inventories.reportData[i].businessHolidaysCount);
      } else dealerDs = 0;
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item17.hasOwnProperty(inventories.reportData[i].regionName)) {
          item17[inventories.reportData[i].regionName] =
            (Number(item17[inventories.reportData[i].regionName]) +
              Number(dealerDs)).toFixed(1);
        } else {
          item17[inventories.reportData[i].regionName] =
            Number(dealerDs).toFixed(1);
        }
        total += dealerDs;
      }
    }
    item17['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item17);

    total = 0;
    let item7: any = {
      'type': 'MONTH END SALES',
      'metric': date.getFullYear() + ' ACTUAL - MTD'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item7.hasOwnProperty(inventories.reportData[i].regionName)) {
          item7[inventories.reportData[i].regionName] +=
            inventories.reportData[i].currentMonthSalesCount;
        } else {
          item7[inventories.reportData[i].regionName] =
            inventories.reportData[i].currentMonthSalesCount;
        }
        total += inventories.reportData[i].currentMonthSalesCount;
      }
    }
    item7['TOTAL'] = total;
    returnArray.push(item7);

    total = 0;
    let item8: any = {
      'type': 'MONTH END SALES',
      'metric': date.getFullYear() + ' ACTUAL - PMTD'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item8.hasOwnProperty(inventories.reportData[i].regionName)) {
          item8[inventories.reportData[i].regionName] +=
            inventories.reportData[i].priorMonthSalesCount;
        } else {
          item8[inventories.reportData[i].regionName] =
            inventories.reportData[i].priorMonthSalesCount;
        }
        total += inventories.reportData[i].priorMonthSalesCount;
      }
    }
    item8['TOTAL'] = total;
    returnArray.push(item8);

    total = 0;
    let item9: any = {
      'type': 'MONTH END SALES',
      'metric': date.getFullYear() - 1 + ' ACTUAL - MTD'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item9.hasOwnProperty(inventories.reportData[i].regionName)) {
          item9[inventories.reportData[i].regionName] +=
            inventories.reportData[i].priorYearCurrentMonthSalesCount;
        } else {
          item9[inventories.reportData[i].regionName] =
            inventories.reportData[i].priorYearCurrentMonthSalesCount;
        }
        total += inventories.reportData[i].priorYearCurrentMonthSalesCount;
      }
    }
    item9['TOTAL'] = total;
    returnArray.push(item9);

    total = 0;
    let item10: any = {
      'type': 'MONTH END SALES',
      'metric': date.getFullYear() - 1 + ' ACTUAL - PMTD'
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item10.hasOwnProperty(inventories.reportData[i].regionName)) {
          item10[inventories.reportData[i].regionName] +=
            inventories.reportData[i].priorYearPriorMonthSalesCount;
        } else {
          item10[inventories.reportData[i].regionName] =
            inventories.reportData[i].priorYearPriorMonthSalesCount;
        }
        total += inventories.reportData[i].priorYearPriorMonthSalesCount;
      }
    }
    item10['TOTAL'] = total;
    returnArray.push(item10);

    total = 0;
    let item11: any = {
      'type': 'CYTD SALES',
      'metric': date.getFullYear()
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item11.hasOwnProperty(inventories.reportData[i].regionName)) {
          item11[inventories.reportData[i].regionName] +=
            (inventories.reportData[i].currentYearSalesCount);
        } else {
          item11[inventories.reportData[i].regionName] =
            (inventories.reportData[i].currentYearSalesCount);
        }
        total += (inventories.reportData[i].currentYearSalesCount);
      }
    }
    item11['TOTAL'] = total;
    returnArray.push(item11);

    total = 0;
    let item12: any = {
      'type': 'CYTD SALES',
      'metric': date.getFullYear() - 1
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item12.hasOwnProperty(inventories.reportData[i].regionName)) {
          item12[inventories.reportData[i].regionName] +=
            inventories.reportData[i].priorYearSalesCount;
        } else {
          item12[inventories.reportData[i].regionName] =
            inventories.reportData[i].priorYearSalesCount;
        }
        total += inventories.reportData[i].priorYearSalesCount;
      }
    }
    item12['TOTAL'] = total;
    returnArray.push(item12);

    total = 0;
    let ytdDiff = 0;
    let cYear = date.getFullYear();
    let item13: any = {
      'type': 'CYTD SALES',
      'metric': cYear.toString() + ' % ' + (cYear - 1)
    };
    for (let i = 0; i < inventories.reportData.length; i++) {
      if (inventories.reportData[i].currentYearSalesCount !== 0 &&
        inventories.reportData[i].priorYearSalesCount !== 0) {
        ytdDiff = Number((inventories.reportData[i].currentYearSalesCount /
          inventories.reportData[i].priorYearSalesCount) * 100);
      } else ytdDiff = 0;
      if (!isNil(inventories.reportData[i].regionName)) {
        if (item11[inventories.reportData[i].regionName] !== 0 &&
          item12[inventories.reportData[i].regionName] !== 0) {
          ytdDiff = Number((item11[inventories.reportData[i].regionName] /
            item12[inventories.reportData[i].regionName]) * 100);
        } else ytdDiff = 0;
        item13[inventories.reportData[i].regionName] = Number(ytdDiff).toFixed(1);

        if (item11['TOTAL'] !== 0 &&
          item12['TOTAL'] !== 0) {
          total = Number((item11['TOTAL'] /
            item12['TOTAL']) * 100);
        } else total = 0;
      }
    }
    item13['TOTAL'] = Number(total).toFixed(1);
    returnArray.push(item13);
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '26')) {
    for (let i = 0; i < inventories.reportData.created.length; i++) {
      let item14: any = {
        'vin': inventories.reportData.created[i].vin,
        'dealerCd': inventories.reportData.created[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData.created[i].regionName,
        'districtNumber': inventories.reportData.created[i].districtNumber,
        'invStatus': 'ADDED',
        'rdrStatus': null,
        'comment': null,
        'customerName': null
      };
      returnArray.push(item14);
    }

    for (let i = 0; i < inventories.reportData.updated.length; i++) {
      let item15: any = {
        'vin': inventories.reportData.updated[i].vin,
        'dealerCd': inventories.reportData.updated[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData.updated[i].regionName,
        'districtNumber': inventories.reportData.updated[i].districtNumber,
        'invStatus': 'UPDATED',
        'rdrStatus': null,
        'comment': null,
        'customerName': null
      };
      returnArray.push(item15);
    }

    for (let i = 0; i < inventories.reportData.deleted.length; i++) {
      let item16: any = {
        'vin': inventories.reportData.deleted[i].vin,
        'dealerCd': inventories.reportData.deleted[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData.deleted[i].regionName,
        'districtNumber': inventories.reportData.deleted[i].districtNumber,
        'invStatus': 'DELETED',
        'rdrStatus': null,
        'comment': null,
        'customerName': null
      };
      returnArray.push(item16);
    }

    for (let i = 0; i < inventories.reportData.certifications.length; i++) {
      let item17: any = {
        'vin': inventories.reportData.certifications[i].vin,
        'dealerCd': inventories.reportData.certifications[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData.certifications[i].regionName,
        'districtNumber': inventories.reportData.certifications[i].districtNumber,
        'invStatus': 'CERTIFIED',
        'rdrStatus': null,
        'comment': null,
        'customerName': null
      };
      returnArray.push(item17);
    }

    for (let i = 0; i < inventories.reportData.failedCertifications.length; i++) {
      let item18: any = {
        'vin': inventories.reportData.failedCertifications[i].vin,
        'dealerCd': inventories.reportData.failedCertifications[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData.failedCertifications[i].regionName,
        'districtNumber': inventories.reportData.failedCertifications[i].districtNumber,
        'invStatus': 'CERTIFICATION FAILED',
        'rdrStatus': null,
        'comment': null,
        'customerName': null
      };
      returnArray.push(item18);
    }

    for (let i = 0; i < inventories.reportData.rdr.length; i++) {
      let customer;
      if (!isNil(inventories.reportData.rdr[i].buyerFirstName)) {
        customer = inventories.reportData.rdr[i].buyerFirstName
          + ' ' + inventories.reportData.rdr[i].buyerLastName;
      } else {
        customer = inventories.reportData.rdr[i].buyerLastName;
      }
      // tslint:disable-next-line:no-shadowed-variable
      let item: any = {
        'vin': inventories.reportData.rdr[i].vin,
        'dealerCd': inventories.reportData.rdr[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData.rdr[i].regionName,
        'districtNumber': inventories.reportData.rdr[i].districtNumber,
        'invStatus': 'RDR CREATED',
        'rdrStatus': null,
        'comment': null,
        'customerName': customer
      };
      returnArray.push(item);
    }

    for (let i = 0; i < inventories.reportData.reversals.length; i++) {
      // tslint:disable-next-line:no-shadowed-variable
      let item: any = {
        'vin': inventories.reportData.reversals[i].vin,
        'dealerCd': inventories.reportData.reversals[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData.reversals[i].regionName,
        'districtNumber': inventories.reportData.reversals[i].districtNumber,
        'invStatus': 'RDR REVERSED',
        'rdrStatus': null,
        'comment': null,
        'customerName': null
      };
      returnArray.push(item);
    }

    for (let i = 0; i < inventories.reportData.wholesaleRdr.length; i++) {
      let customer;
      if (!isNil(inventories.reportData.wholesaleRdr[i].buyerFirstName)) {
        customer = inventories.reportData.wholesaleRdr[i].buyerFirstName
          + ' ' + inventories.reportData.wholesaleRdr[i].buyerLastName;
      } else {
        customer = inventories.reportData.wholesaleRdr[i].buyerLastName;
      }
      let item19: any = {
        'vin': inventories.reportData.wholesaleRdr[i].vin,
        'dealerCd': inventories.reportData.wholesaleRdr[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData.wholesaleRdr[i].regionName,
        'districtNumber': inventories.reportData.wholesaleRdr[i].districtNumber,
        'invStatus': 'RDR WHOLESALED',
        'rdrStatus': null,
        'comment': null,
        'customerName': customer
      };
      returnArray.push(item19);
    }

    let item: any = {
      'vin': 'TOTAL INVENTORY ADDED',
      'dealerCd': inventories.reportData.created.length,
    };
    returnArray.push(item);

    let item1: any = {
      'vin': 'TOTAL INVENTORY UPDATED',
      'dealerCd': inventories.reportData.updated.length,
    };
    returnArray.push(item1);

    let item2: any = {
      'vin': 'TOTAL INVENTORY DELETED',
      'dealerCd': inventories.reportData.deleted.length,
    };
    returnArray.push(item2);

    let item3: any = {
      'vin': 'TOTAL CERTIFIED',
      'dealerCd': inventories.reportData.certifications.length,
    };
    returnArray.push(item3);

    let item4: any = {
      'vin': 'TOTAL CERTIFICATION FAILED',
      'dealerCd': inventories.reportData.failedCertifications.length,
    };
    returnArray.push(item4);

    let item5: any = {
      'vin': 'TOTAL RDR CREATED',
      'dealerCd': inventories.reportData.rdr.length,
    };
    returnArray.push(item5);

    let item6: any = {
      'vin': 'TOTAL RDR REVERSED',
      'dealerCd': inventories.reportData.reversals.length,
    };
    returnArray.push(item6);

    let item7: any = {
      'vin': 'TOTAL RDR WHOLESALED',
      'dealerCd': inventories.reportData.wholesaleRdr.length,
    };
    returnArray.push(item7);

  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '27')) {
    let tempArray = [];
    inventories.reportData = inventories.reportData.data;

    // for (let i = 0; i < inventories.reportData.length; i++) {
    inventories.reportData.forEach(element => {
      let certificationObj, salesObjPercent, certObjPercent, totalLexusDaily,
        totalLexusMonthly, salesAvail, lexusTotalObjPercent;

      certificationObj = (element.currentMonthRegionObjective * 1.1);
      totalLexusDaily = Number(element.dailyCertifiedSalesCount) +
        Number(element.dailyNonCertifiedSalesCount);
      totalLexusMonthly = Number(element.monthlyCertifiedSalesCount) +
        Number(element.monthlyNonCertifiedSalesCount);

      if (Number(element.currentMonthRegionObjective) !== 0) {
        salesObjPercent = Number(
          (element.monthlyCertifiedSalesCount / element.currentMonthRegionObjective) * 100);
        certObjPercent = Number(
          (element.monthlyCertifiedInventory / certificationObj) * 100);
        lexusTotalObjPercent =
          Number((totalLexusMonthly / element.currentMonthRegionObjective) * 100);
      } else {
        salesObjPercent = 0;
        certObjPercent = 0;
        lexusTotalObjPercent = 0;
      }

      if (Number(element.certifiedInventory + element.monthlyCertifiedSalesCount) !== 0) {
        salesAvail = Number(((element.monthlyCertifiedSalesCount) /
          (element.certifiedInventory + element.monthlyCertifiedSalesCount) * 100));
      } else {
        salesAvail = 0;
      }

      if (!isNil(element.regionName)) {
        let item: any = {
          'regionNumber': element.regionNumber,
          'regionName': element.regionName,
          'modelDescription': element.modelDescription,
          'dailyCertifiedSalesCount': element.dailyCertifiedSalesCount,
          'monthlyCertifiedSalesCount': element.monthlyCertifiedSalesCount,
          'dailyNonCertifiedSalesCount': element.dailyNonCertifiedSalesCount,
          'monthlyNonCertifiedSalesCount': element.monthlyNonCertifiedSalesCount,
          'dailyCertifiedReversalCount': element.dailyCertifiedReversalCount,
          'monthlyCertifiedReversalCount': element.monthlyCertifiedReversalCount,
          'dailyNonCertifiedReversalCount': element.dailyNonCertifiedReversalCount,
          'monthlyNonCertifiedReversalCount': element.monthlyNonCertifiedReversalCount,
          'dailyCertifiedInventory': element.dailyCertifiedInventory,
          'monthlyCertifiedInventory': element.monthlyCertifiedInventory,
          'inventoryLessThan60Days': element.inventoryLessThan60Days,
          'certificationTotal': element.certifiedInventory,
          'inventoryTotal': element.inventoryTotal,
          'salesObjective': Number(element.currentMonthRegionObjective),
          'certificationObjective': Number.parseFloat(certificationObj).toFixed(0),
          'salesObjPercent': salesObjPercent,
          'certObjPercent': certObjPercent,
          'totalLexusDaily': totalLexusDaily,
          'totalLexusMonthly': totalLexusMonthly,
          'lexusTotalObj': Number(element.currentMonthRegionObjective),
          'lexusTotalObjPercent': lexusTotalObjPercent,
          'salesAvail': salesAvail,
          'certifiedInventory': element.certifiedInventory
        };
        // if (element.regionNumber === '50' || element.regionNumber === '83') {
        //   item = {
        //     'regionNumber': element.regionNumber,
        //     'regionName': element.regionName,
        //     'modelDescription': null,
        //     'dailyCertifiedSalesCount': 0,
        //     'monthlyCertifiedSalesCount': 0,
        //     'dailyNonCertifiedSalesCount': 0,
        //     'monthlyNonCertifiedSalesCount': 0,
        //     'dailyCertifiedReversalCount': 0,
        //     'monthlyCertifiedReversalCount': 0,
        //     'dailyNonCertifiedReversalCount': 0,
        //     'monthlyNonCertifiedReversalCount': 0,
        //     'dailyCertifiedInventory': 0,
        //     'monthlyCertifiedInventory': 0,
        //     'inventoryLessThan60Days': 0,
        //     'certificationTotal': 0,
        //     'inventoryTotal': 0,
        //     'salesObjective': 0,
        //     'certificationObjective': 0,
        //     'salesObjPercent': 0,
        //     'certObjPercent': 0,
        //     'totalLexusDaily': 0,
        //     'totalLexusMonthly': 0,
        //     'lexusTotalObj': 0,
        //     'lexusTotalObjPercent': 0,
        //     'salesAvail': 0,
        //     'certifiedInventory': 0
        //   };
        // }
        tempArray.push(item);
      }
    });
    // }

    const groupBy = key => array =>
      tempArray.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
    const groupByRegion = groupBy('regionNumber');
    let regionGrouping = groupByRegion(tempArray);
    let regionGroupKeys = Object.keys(regionGrouping);
    let regionGroupValues: any[] = Object.values(regionGrouping);

    for (let i = 0; i < regionGroupKeys.length; i++) {
      if (regionGroupValues[i][0].regionNumber === '83')
        continue;
      let item1: any = {
        'region': regionGroupValues[i][0].regionNumber
      };
      for (let j = 0; j < regionGroupValues[i].length; j++) {
        item1['regionName'] = regionGroupValues[i][j].regionName;

        item1['salesObjective'] =
          regionGroupValues[i][j].salesObjective;

        item1['certificationObjective'] =
          regionGroupValues[i][j].certificationObjective;

        item1['lexusTotalObj'] =
          regionGroupValues[i][j].lexusTotalObj;

        if (item1.hasOwnProperty('dailyCertifiedSalesCount')) {
          item1['dailyCertifiedSalesCount'] +=
            regionGroupValues[i][j].dailyCertifiedSalesCount;
        } else {
          item1['dailyCertifiedSalesCount'] =
            regionGroupValues[i][j].dailyCertifiedSalesCount;
        }

        if (item1.hasOwnProperty('monthlyCertifiedSalesCount')) {
          item1['monthlyCertifiedSalesCount'] +=
            (regionGroupValues[i][j].monthlyCertifiedSalesCount -
              regionGroupValues[i][j].monthlyCertifiedReversalCount);
        } else {
          item1['monthlyCertifiedSalesCount'] =
            (regionGroupValues[i][j].monthlyCertifiedSalesCount -
              regionGroupValues[i][j].monthlyCertifiedReversalCount);
        }

        if (item1.hasOwnProperty('dailyCertifiedInventory')) {
          item1['dailyCertifiedInventory'] +=
            regionGroupValues[i][j].dailyCertifiedInventory;
        } else {
          item1['dailyCertifiedInventory'] =
            regionGroupValues[i][j].dailyCertifiedInventory;
        }

        if (item1.hasOwnProperty('monthlyCertifiedInventory')) {
          item1['monthlyCertifiedInventory'] +=
            regionGroupValues[i][j].monthlyCertifiedInventory;
        } else {
          item1['monthlyCertifiedInventory'] =
            regionGroupValues[i][j].monthlyCertifiedInventory;
        }

        if (item1.hasOwnProperty('certificationTotal')) {
          item1['certificationTotal'] +=
            regionGroupValues[i][j].certificationTotal;
        } else {
          item1['certificationTotal'] =
            regionGroupValues[i][j].certificationTotal;
        }

        if (item1.hasOwnProperty('inventoryTotal')) {
          item1['inventoryTotal'] +=
            regionGroupValues[i][j].inventoryTotal;
        } else {
          item1['inventoryTotal'] =
            regionGroupValues[i][j].inventoryTotal;
        }

        if (item1.hasOwnProperty('inventoryLessThan60Days')) {
          item1['inventoryLessThan60Days'] +=
            regionGroupValues[i][j].inventoryLessThan60Days;
        } else {
          item1['inventoryLessThan60Days'] =
            regionGroupValues[i][j].inventoryLessThan60Days;
        }

        if (item1.hasOwnProperty('totalLexusDaily')) {
          item1['totalLexusDaily'] +=
            (regionGroupValues[i][j].dailyCertifiedSalesCount +
              regionGroupValues[i][j].dailyNonCertifiedSalesCount -
              regionGroupValues[i][j].dailyCertifiedReversalCount -
              regionGroupValues[i][j].dailyNonCertifiedReversalCount);
        } else {
          item1['totalLexusDaily'] =
            (regionGroupValues[i][j].dailyCertifiedSalesCount +
              regionGroupValues[i][j].dailyNonCertifiedSalesCount -
              regionGroupValues[i][j].dailyCertifiedReversalCount -
              regionGroupValues[i][j].dailyNonCertifiedReversalCount);
        }

        if (item1.hasOwnProperty('totalLexusMonthly')) {
          item1['totalLexusMonthly'] +=
            ((regionGroupValues[i][j].monthlyCertifiedSalesCount +
              regionGroupValues[i][j].monthlyNonCertifiedSalesCount) -
              regionGroupValues[i][j].monthlyCertifiedReversalCount -
              regionGroupValues[i][j].monthlyNonCertifiedReversalCount);
        } else {
          item1['totalLexusMonthly'] =
            ((regionGroupValues[i][j].monthlyCertifiedSalesCount +
              regionGroupValues[i][j].monthlyNonCertifiedSalesCount) -
              regionGroupValues[i][j].monthlyCertifiedReversalCount -
              regionGroupValues[i][j].monthlyNonCertifiedReversalCount);
        }

        if (item1.hasOwnProperty('salesAvail')) {
          item1['salesAvail'] =
            (regionGroupValues[i][j].certifiedInventory +
              regionGroupValues[i][j].monthlyCertifiedSalesCount) !== 0 ?
              Number(Number(item1['salesAvail']) +
                Number((regionGroupValues[i][j].monthlyCertifiedSalesCount /
                  (regionGroupValues[i][j].certifiedInventory +
                    regionGroupValues[i][j].monthlyCertifiedSalesCount)) * 100)).toFixed(1) : 0;
        } else {
          item1['salesAvail'] =
            (regionGroupValues[i][j].certifiedInventory +
              regionGroupValues[i][j].monthlyCertifiedSalesCount) !== 0 ?
              Number((regionGroupValues[i][j].monthlyCertifiedSalesCount /
                (regionGroupValues[i][j].certifiedInventory +
                  regionGroupValues[i][j].monthlyCertifiedSalesCount)) * 100).toFixed(1) : 0;
        }

        if (item1.hasOwnProperty('lexusTotalObjPercent')) {
          item1['lexusTotalObjPercent'] =
            regionGroupValues[i][j].salesObjective !== 0 ?
              Number(Number(item1['lexusTotalObjPercent']) +
                Number((regionGroupValues[i][j].totalLexusMonthly /
                  regionGroupValues[i][j].salesObjective) * 100)).toFixed(1) : 0;
        } else {
          item1['lexusTotalObjPercent'] =
            regionGroupValues[i][j].salesObjective !== 0 ?
              Number((regionGroupValues[i][j].totalLexusMonthly /
                regionGroupValues[i][j].salesObjective) * 100).toFixed(1) : 0;
        }

        if (item1.hasOwnProperty('salesObjPercent')) {
          item1['salesObjPercent'] =
            regionGroupValues[i][j].salesObjective !== 0 ?
              Number(Number(item1['salesObjPercent']) +
                Number(((regionGroupValues[i][j].monthlyCertifiedSalesCount -
                  regionGroupValues[i][j].monthlyCertifiedReversalCount) /
                  regionGroupValues[i][j].salesObjective) * 100)).toFixed(1) : 0;
        } else {
          item1['salesObjPercent'] =
            regionGroupValues[i][j].salesObjective !== 0 ?
              Number(((regionGroupValues[i][j].monthlyCertifiedSalesCount -
                regionGroupValues[i][j].monthlyCertifiedReversalCount) /
                regionGroupValues[i][j].salesObjective) * 100).toFixed(1) : 0;
        }

        if (item1.hasOwnProperty('certObjPercent')) {
          item1['certObjPercent'] = regionGroupValues[i][j].certificationObjective !== '0' ?
            Number(Number(item1['certObjPercent']) +
              Number((regionGroupValues[i][j].monthlyCertifiedInventory /
                regionGroupValues[i][j].certificationObjective) * 100)).toFixed(1) : 0;
        } else {
          item1['certObjPercent'] = regionGroupValues[i][j].certificationObjective !== '0' ?
            Number((regionGroupValues[i][j].monthlyCertifiedInventory /
              regionGroupValues[i][j].certificationObjective) * 100).toFixed(1) : 0;
        }
      }

      returnArray.push(item1);
    }

    let copyReturnArray;
    copyReturnArray = returnArray;

    let item2: any = {
      'region': ''
    };
    for (let j = 0; j < copyReturnArray.length; j++) {
      item2['regionName'] = 'NATIONAL TOT';

      if (item2.hasOwnProperty('salesObjective')) {
        item2['salesObjective'] +=
          copyReturnArray[j].salesObjective;
      } else {
        item2['salesObjective'] =
          copyReturnArray[j].salesObjective;
      }

      if (item2.hasOwnProperty('lexusTotalObj')) {
        item2['lexusTotalObj'] +=
          copyReturnArray[j].lexusTotalObj;
      } else {
        item2['lexusTotalObj'] =
          copyReturnArray[j].lexusTotalObj;
      }

      if (item2.hasOwnProperty('certificationObjective')) {
        item2['certificationObjective'] =
          Number(Number(item2['certificationObjective']) +
            Number(copyReturnArray[j].certificationObjective)).toFixed(0);
      } else {
        item2['certificationObjective'] =
          copyReturnArray[j].certificationObjective;
      }

      if (item2.hasOwnProperty('salesAvail')) {
        item2['salesAvail'] =
          Number(Number(item2['salesAvail']) +
            Number(copyReturnArray[j].salesAvail) / copyReturnArray.length).toFixed(0);
      } else {
        item2['salesAvail'] =
          (copyReturnArray[j].salesAvail / copyReturnArray.length);
      }

      if (item2.hasOwnProperty('dailyCertifiedSalesCount')) {

        item2['dailyCertifiedSalesCount'] +=
          copyReturnArray[j].dailyCertifiedSalesCount;
      } else {
        item2['dailyCertifiedSalesCount'] =
          copyReturnArray[j].dailyCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('monthlyCertifiedSalesCount')) {
        item2['monthlyCertifiedSalesCount'] +=
          copyReturnArray[j].monthlyCertifiedSalesCount;
      } else {
        item2['monthlyCertifiedSalesCount'] =
          copyReturnArray[j].monthlyCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('dailyCertifiedInventory')) {
        item2['dailyCertifiedInventory'] +=
          copyReturnArray[j].dailyCertifiedInventory;
      } else {
        item2['dailyCertifiedInventory'] =
          copyReturnArray[j].dailyCertifiedInventory;
      }

      if (item2.hasOwnProperty('monthlyCertifiedInventory')) {
        item2['monthlyCertifiedInventory'] +=
          copyReturnArray[j].monthlyCertifiedInventory;
      } else {
        item2['monthlyCertifiedInventory'] =
          copyReturnArray[j].monthlyCertifiedInventory;
      }

      if (copyReturnArray[j].hasOwnProperty('reginalPercent')) {
        copyReturnArray[j]['reginalPercent'] = item2['monthlyCertifiedInventory'] !== 0 ?
          Number(Number(copyReturnArray[j]['monthlyCertifiedInventory'] /
            item2['monthlyCertifiedInventory']) * 100).toFixed(1) : 0;
      } else {
        copyReturnArray[j]['reginalPercent'] = item2['monthlyCertifiedInventory'] !== 0 ?
          Number(Number(copyReturnArray[j]['monthlyCertifiedInventory'] /
            item2['monthlyCertifiedInventory']) * 100).toFixed(1) : 0;
      }

      if (item2.hasOwnProperty('totalLexusDaily')) {

        item2['totalLexusDaily'] +=
          copyReturnArray[j].totalLexusDaily;
      } else {
        item2['totalLexusDaily'] =
          copyReturnArray[j].totalLexusDaily;
      }

      if (item2.hasOwnProperty('totalLexusMonthly')) {
        item2['totalLexusMonthly'] +=
          copyReturnArray[j].totalLexusMonthly;
      } else {
        item2['totalLexusMonthly'] =
          copyReturnArray[j].totalLexusMonthly;
      }

      if (item2.hasOwnProperty('certificationTotal')) {
        item2['certificationTotal'] +=
          copyReturnArray[j].certificationTotal;
      } else {
        item2['certificationTotal'] =
          copyReturnArray[j].certificationTotal;
      }

      if (item2.hasOwnProperty('inventoryTotal')) {
        item2['inventoryTotal'] +=
          copyReturnArray[j].inventoryTotal;
      } else {
        item2['inventoryTotal'] =
          copyReturnArray[j].inventoryTotal;
      }

      if (item2.hasOwnProperty('inventoryLessThan60Days')) {
        item2['inventoryLessThan60Days'] +=
          copyReturnArray[j].inventoryLessThan60Days;
      } else {
        item2['inventoryLessThan60Days'] =
          copyReturnArray[j].inventoryLessThan60Days;
      }

      // if (item2.hasOwnProperty('salesObjPercent')) {
      //   item2['salesObjPercent'] =
      //     Number(Number(item2['salesObjPercent']) +
      //       Number(copyReturnArray[j].salesObjPercent)).toFixed(1);
      // } else {
      //   item2['salesObjPercent'] =
      //     Number(copyReturnArray[j].salesObjPercent).toFixed(1);
      // }

      // if (item2.hasOwnProperty('certObjPercent')) {
      //   item2['certObjPercent'] =
      //     Number(Number(item2['certObjPercent']) +
      //       Number(copyReturnArray[j].certObjPercent)).toFixed(1);
      // } else {
      //   item2['certObjPercent'] =
      //     Number(copyReturnArray[j].certObjPercent).toFixed(1);
      // }
    }

    for (let j = 0; j < copyReturnArray.length; j++) {
      if (copyReturnArray[j].hasOwnProperty('reginalPercent')) {
        copyReturnArray[j]['reginalPercent'] = item2['monthlyCertifiedSalesCount'] !== 0 ?
          Number(Number(copyReturnArray[j]['monthlyCertifiedSalesCount'] /
            item2['monthlyCertifiedSalesCount']) * 100).toFixed(1) : 0;
      } else {
        copyReturnArray[j]['reginalPercent'] = item2['monthlyCertifiedSalesCount'] !== 0 ?
          Number(Number(copyReturnArray[j]['monthlyCertifiedSalesCount'] /
            item2['monthlyCertifiedSalesCount']) * 100).toFixed(1) : 0;
      }

      if (item2.hasOwnProperty('reginalPercent')) {
        item2['reginalPercent'] =
          Number(Number(item2['reginalPercent']) +
            Number(copyReturnArray[j].reginalPercent)).toFixed(1);
      } else {
        item2['reginalPercent'] =
          Number(copyReturnArray[j].reginalPercent).toFixed(1);
      }
    }

    if (item2.hasOwnProperty('salesObjPercent')) {
      item2['salesObjPercent'] =
        item2['salesObjective'] !== 0 ?
          Number(Number(item2['salesObjPercent']) +
            Number((item2['monthlyCertifiedSalesCount'] /
              item2['salesObjective']) * 100)).toFixed(1) : 0;
    } else {
      item2['salesObjPercent'] =
        item2['salesObjective'] !== 0 ?
          Number((item2['monthlyCertifiedSalesCount'] /
            item2['salesObjective']) * 100).toFixed(1) : 0;
    }

    if (item2.hasOwnProperty('lexusTotalObjPercent')) {
      item2['lexusTotalObjPercent'] =
        item2['lexusTotalObj'] !== 0 ?
          Number(Number(item2['lexusTotalObjPercent']) +
            Number((item2['totalLexusMonthly'] /
              item2['lexusTotalObj']) * 100)).toFixed(1) : 0;
    } else {
      item2['lexusTotalObjPercent'] =
        item2['lexusTotalObj'] !== 0 ?
          Number((item2['totalLexusMonthly'] /
            item2['lexusTotalObj']) * 100).toFixed(1) : 0;
    }

    if (item2.hasOwnProperty('certObjPercent')) {
      item2['certObjPercent'] = item2['certificationObjective'] !== '0' ?
        Number(Number(item2['certObjPercent']) +
          Number((item2['monthlyCertifiedInventory'] /
            item2['certificationObjective']) * 100)).toFixed(1) : 0;
    } else {
      item2['certObjPercent'] = item2['certificationObjective'] !== '0' ?
        Number((item2['monthlyCertifiedInventory'] /
          item2['certificationObjective']) * 100).toFixed(1) : 0;
    }

    returnArray.push(item2);
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '33')) {

    let tempArray = [];
    inventories.reportData = inventories.reportData.data;
    // for (let i = 0; i < inventories.reportData.length; i++) {
    inventories.reportData.forEach(element => {
      /*let certificationObj, salesObjPercent, certObjPercent, totalLexusDaily,
        totalLexusMonthly, salesAvail, lexusTotalObj;

      certificationObj = (element.currentMonthRegionObjective * 1.1);

      if (Number(element.currentMonthRegionObjective) !== 0) {
        salesObjPercent = Number(
          (element.monthlyCertifiedSalesCount / element.currentMonthRegionObjective) * 100);
      } else {
        salesObjPercent = 0;
      }

      if (Number(element.currentMonthRegionObjective) !== 0) {
        certObjPercent = Number(
          (element.monthlyCertifiedInventory / certificationObj) * 100);
      } else {
        certObjPercent = 0;
      }

      totalLexusDaily = Number(element.dailyCertifiedSalesCount) +
        Number(element.dailyNonCertifiedSalesCount);
      totalLexusMonthly = Number(element.monthlyCertifiedSalesCount) +
        Number(element.monthlyNonCertifiedSalesCount);

      if (Number(element.currentMonthRegionObjective) !== 0) {
        lexusTotalObj =
          Number((totalLexusMonthly / element.currentMonthRegionObjective) * 100);
      } else {
        lexusTotalObj = 0;
      }

      if (Number(element.certifiedInventory + element.monthlyCertifiedSalesCount) !== 0) {
        salesAvail = Number(((element.monthlyCertifiedSalesCount) /
          (element.certifiedInventory + element.monthlyCertifiedSalesCount) * 100));
      } else {
        salesAvail = 0;
      }
      // let totalRDR = element.totalRDRCount;
      // let totalReversal = element.totalReversalCount;
      // let totalInv = totalRDR - totalReversal;
      // let invCount = element.invCount;*/
      let monthReversalCount = element.monthReversalCount;
      let monthCertSaleCount = element.monthCertSalesCount;
      let monthlyCertSaleEffective = Math.abs(monthCertSaleCount - monthReversalCount);
      if (!isNil(element.regionName)) {
        let item: any = {
          'dealerCd': element.dealerCode,
          'regionNumber': element.regionNumber,
          'regionName': element.regionName,
          'modelDescription': element.modelDescription,
          'dailyCertifiedSalesCount': element.dailyCertifiedSalesCount,
          'monthlyCertifiedSalesCount': element.monthlyCertifiedSalesCount,
          'yearlyCertifiedSalesCount': element.yearlyCertifiedSalesCount,
          'customCertifiedSalesCount': element.customCertifiedSalesCount,
          'dailyCertifiedInventory': element.dailyCertifiedInventory,
          'monthlyCertifiedInventory': element.monthlyCertifiedInventory,
          'yearlyCertifiedInventory': element.yearlyCertifiedInventory,
          'customCertifiedInventory': element.customCertifiedInventory,
          'totalInvCount': element.invCount,
          'dailyCertifiedReversalCount': element.dailyCertifiedReversalCount,
          'monthlyCertifiedReversalCount': element.monthlyCertifiedReversalCount,
          'yearlyCertifiedReversalCount': element.yearlyCertifiedReversalCount,
          'customCertifiedReversalCount': element.customCertifiedReversalCount
          // 'inventoryLessThan60Days': element.inventoryLessThan60Days,
          // 'certificationTotal': element.certifiedInventory,
          // 'inventoryTotal': element.inventoryTotal
          // 'salesObjective': element.currentMonthRegionObjective,
          // 'certificationObjective': Number.parseFloat(certificationObj).toFixed(0),
          // 'salesObjPercent': salesObjPercent,
          // 'certObjPercent': certObjPercent,
          // 'totalLexusDaily': totalLexusDaily,
          // 'totalLexusMonthly': totalLexusMonthly,
          // 'lexusTotalObj': element.currentMonthRegionObjective,
          // 'salesAvail': salesAvail,
          // 'certifiedInventory': element.certifiedInventory,
          // 'monthlyNonCertifiedSalesCount': element.monthlyNonCertifiedSalesCount,
          // 'dailyNonCertifiedSalesCount': element.dailyNonCertifiedSalesCount,
        };
        tempArray.push(item);
      }
    });

    const groupBy = key => array =>
      tempArray.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});
    const groupByRegion = groupBy('dealerCd');
    let regionGrouping = groupByRegion(tempArray);
    let regionGroupKeys = Object.keys(regionGrouping);
    let regionGroupValues: any[] = Object.values(regionGrouping);
    console.log(groupByRegion);
    console.log(regionGrouping);
    console.log(regionGroupKeys);
    console.log(regionGroupValues);

    for (let i = 0; i < regionGroupKeys.length; i++) {
      if (regionGroupValues[i][0].regionNumber === '83')
        continue;
      let item1: any = {
        'dealerCd': regionGroupValues[i][0].dealerCd

      };
      for (let j = 0; j < regionGroupValues[i].length; j++) {
        item1['regionName'] = regionGroupValues[i][j].regionName;
        item1['dealerCd'] = regionGroupValues[i][j].dealerCd;
        /*item1['salesObjective'] =
          regionGroupValues[i][j].salesObjective;

        item1['certificationObjective'] =
          regionGroupValues[i][j].certificationObjective;

        item1['lexusTotalObj'] =
          regionGroupValues[i][j].lexusTotalObj;*/

        if (item1.hasOwnProperty('dailyCertifiedSalesCount')) {
          item1['dailyCertifiedSalesCount'] +=
            (regionGroupValues[i][j].dailyCertifiedSalesCount -
              regionGroupValues[i][j].dailyCertifiedReversalCount);
        } else {
          item1['dailyCertifiedSalesCount'] =
            (regionGroupValues[i][j].dailyCertifiedSalesCount -
              regionGroupValues[i][j].dailyCertifiedReversalCount);
        }

        if (item1.hasOwnProperty('monthlyCertifiedSalesCount')) {
          item1['monthlyCertifiedSalesCount'] +=
            (regionGroupValues[i][j].monthlyCertifiedSalesCount -
              regionGroupValues[i][j].monthlyCertifiedReversalCount);
        } else {
          item1['monthlyCertifiedSalesCount'] =
            (regionGroupValues[i][j].monthlyCertifiedSalesCount -
              regionGroupValues[i][j].monthlyCertifiedReversalCount);
        }

        if (item1.hasOwnProperty('yearlyCertifiedSalesCount')) {
          item1['yearlyCertifiedSalesCount'] +=
            (regionGroupValues[i][j].yearlyCertifiedSalesCount -
              regionGroupValues[i][j].yearlyCertifiedReversalCount);
        } else {
          item1['yearlyCertifiedSalesCount'] =
            (regionGroupValues[i][j].yearlyCertifiedSalesCount -
              regionGroupValues[i][j].yearlyCertifiedReversalCount);
        }

        if (item1.hasOwnProperty('customCertifiedSalesCount')) {
          item1['customCertifiedSalesCount'] +=
            (regionGroupValues[i][j].customCertifiedSalesCount -
              regionGroupValues[i][j].customCertifiedReversalCount);
        } else {
          item1['customCertifiedSalesCount'] =
            (regionGroupValues[i][j].customCertifiedSalesCount -
              regionGroupValues[i][j].customCertifiedReversalCount);
        }

        if (item1.hasOwnProperty('dailyCertifiedInventory')) {
          item1['dailyCertifiedInventory'] +=
            regionGroupValues[i][j].dailyCertifiedInventory;
        } else {
          item1['dailyCertifiedInventory'] =
            regionGroupValues[i][j].dailyCertifiedInventory;
        }

        if (item1.hasOwnProperty('monthlyCertifiedInventory')) {
          item1['monthlyCertifiedInventory'] +=
            regionGroupValues[i][j].monthlyCertifiedInventory;
        } else {
          item1['monthlyCertifiedInventory'] =
            regionGroupValues[i][j].monthlyCertifiedInventory;
        }

        if (item1.hasOwnProperty('yearlyCertifiedInventory')) {
          item1['yearlyCertifiedInventory'] +=
            regionGroupValues[i][j].yearlyCertifiedInventory;
        } else {
          item1['yearlyCertifiedInventory'] =
            regionGroupValues[i][j].yearlyCertifiedInventory;
        }

        if (item1.hasOwnProperty('customCertifiedInventory')) {
          item1['customCertifiedInventory'] +=
            regionGroupValues[i][j].customCertifiedInventory;
        } else {
          item1['customCertifiedInventory'] =
            regionGroupValues[i][j].customCertifiedInventory;
        }

        if (item1.hasOwnProperty('totalInvCount')) {
          item1['totalInvCount'] +=
            regionGroupValues[i][j].totalInvCount;
        } else {
          item1['totalInvCount'] =
            regionGroupValues[i][j].totalInvCount;
        }

        /*if (item1.hasOwnProperty('certificationTotal')) {
          item1['certificationTotal'] +=
            regionGroupValues[i][j].certificationTotal;
        } else {
          item1['certificationTotal'] =
            regionGroupValues[i][j].certificationTotal;
        }

        if (item1.hasOwnProperty('inventoryTotal')) {
          item1['inventoryTotal'] +=
            regionGroupValues[i][j].inventoryTotal;
        } else {
          item1['inventoryTotal'] =
            regionGroupValues[i][j].inventoryTotal;
        }

        if (item1.hasOwnProperty('inventoryLessThan60Days')) {
          item1['inventoryLessThan60Days'] +=
            regionGroupValues[i][j].inventoryLessThan60Days;
        } else {
          item1['inventoryLessThan60Days'] =
            regionGroupValues[i][j].inventoryLessThan60Days;
        }

        if (item1.hasOwnProperty('totalLexusDaily')) {
          item1['totalLexusDaily'] +=
            (regionGroupValues[i][j].dailyCertifiedSalesCount +
              regionGroupValues[i][j].dailyNonCertifiedSalesCount);
        } else {
          item1['totalLexusDaily'] =
            (regionGroupValues[i][j].dailyCertifiedSalesCount +
              regionGroupValues[i][j].dailyNonCertifiedSalesCount);
        }

        if (item1.hasOwnProperty('totalLexusMonthly')) {
          item1['totalLexusMonthly'] +=
            ((regionGroupValues[i][j].monthlyCertifiedSalesCount +
              regionGroupValues[i][j].monthlyNonCertifiedSalesCount) -
              regionGroupValues[i][j].monthlyCertifiedReversalCount);
        } else {
          item1['totalLexusMonthly'] =
            ((regionGroupValues[i][j].monthlyCertifiedSalesCount +
              regionGroupValues[i][j].monthlyNonCertifiedSalesCount) -
              regionGroupValues[i][j].monthlyCertifiedReversalCount);
        }

        if (item1.hasOwnProperty('salesAvail')) {
          item1['salesAvail'] =
            (regionGroupValues[i][j].certifiedInventory +
              regionGroupValues[i][j].monthlyCertifiedSalesCount) !== 0 ?
              Number(Number(item1['salesAvail']) +
                Number((regionGroupValues[i][j].monthlyCertifiedSalesCount /
                  (regionGroupValues[i][j].certifiedInventory +
                    regionGroupValues[i][j].monthlyCertifiedSalesCount)) * 100)).toFixed(1) : 0;
        } else {
          item1['salesAvail'] =
            (regionGroupValues[i][j].certifiedInventory +
              regionGroupValues[i][j].monthlyCertifiedSalesCount) !== 0 ?
              Number((regionGroupValues[i][j].monthlyCertifiedSalesCount /
                (regionGroupValues[i][j].certifiedInventory +
                  regionGroupValues[i][j].monthlyCertifiedSalesCount)) * 100).toFixed(1) : 0;
        }

        if (item1.hasOwnProperty('lexusTotalObj')) {
          item1['lexusTotalObj'] =
            regionGroupValues[i][j].salesObjective !== 0 ?
              Number(Number(item1['lexusTotalObj']) +
                Number((regionGroupValues[i][j].totalLexusMonthly /
                  regionGroupValues[i][j].salesObjective) * 100)).toFixed(1) : 0;
        } else {
          item1['lexusTotalObj'] =
            regionGroupValues[i][j].salesObjective !== 0 ?
              Number((regionGroupValues[i][j].totalLexusMonthly /
                regionGroupValues[i][j].salesObjective) * 100).toFixed(1) : 0;
        }

        if (item1.hasOwnProperty('salesObjPercent')) {
          item1['salesObjPercent'] =
            regionGroupValues[i][j].salesObjective !== 0 ?
              Number(Number(item1['salesObjPercent']) +
                Number(((regionGroupValues[i][j].monthlyCertifiedSalesCount -
                  regionGroupValues[i][j].monthlyCertifiedReversalCount) /
                  regionGroupValues[i][j].salesObjective) * 100)).toFixed(1) : 0;
        } else {
          item1['salesObjPercent'] =
            regionGroupValues[i][j].salesObjective !== 0 ?
              Number(((regionGroupValues[i][j].monthlyCertifiedSalesCount -
                regionGroupValues[i][j].monthlyCertifiedReversalCount) /
                regionGroupValues[i][j].salesObjective) * 100).toFixed(1) : 0;
        }

        if (item1.hasOwnProperty('certObjPercent')) {
          item1['certObjPercent'] = regionGroupValues[i][j].certificationObjective !== '0' ?
            Number(Number(item1['certObjPercent']) +
              Number((regionGroupValues[i][j].monthlyCertifiedInventory /
                regionGroupValues[i][j].certificationObjective) * 100)).toFixed(1) : 0;
        } else {
          item1['certObjPercent'] = regionGroupValues[i][j].certificationObjective !== '0' ?
            Number((regionGroupValues[i][j].monthlyCertifiedInventory /
              regionGroupValues[i][j].certificationObjective) * 100).toFixed(1) : 0;
        }*/
      }

      returnArray.push(item1);
    }

    let copyReturnArray;

    copyReturnArray = returnArray;

    let item2: any = {
      'dealerCd': ''
    };
    for (let j = 0; j < copyReturnArray.length; j++) {
      // item2['regionName'] = 'NATIONAL TOT';
      item2['dealerCd'] = 'TOTAL';


      /* if (item2.hasOwnProperty('salesObjective')) {
         item2['salesObjective'] +=
           copyReturnArray[j].salesObjective;
       } else {
         item2['salesObjective'] =
           copyReturnArray[j].salesObjective;
       }
       if (item2.hasOwnProperty('certificationObjective')) {
         item2['certificationObjective'] =
           Number(Number(item2['certificationObjective']) +
             Number(copyReturnArray[j].certificationObjective)).toFixed(0);
       } else {
         item2['certificationObjective'] =
           copyReturnArray[j].certificationObjective;
       }
       if (item2.hasOwnProperty('lexusTotalObj')) {
         item2['lexusTotalObj'] =
           Number(Number(item2['lexusTotalObj']) +
             Number(copyReturnArray[j].lexusTotalObj) / copyReturnArray.length).toFixed(0);
       } else {
         item2['lexusTotalObj'] =
           (copyReturnArray[j].lexusTotalObj / copyReturnArray.length);
       }
       if (item2.hasOwnProperty('salesAvail')) {
         item2['salesAvail'] =
           Number(Number(item2['salesAvail']) +
             Number(copyReturnArray[j].salesAvail) / copyReturnArray.length).toFixed(0);
       } else {
         item2['salesAvail'] =
           (copyReturnArray[j].salesAvail / copyReturnArray.length);
       }*/

      if (item2.hasOwnProperty('dailyCertifiedSalesCount')) {

        item2['dailyCertifiedSalesCount'] +=
          copyReturnArray[j].dailyCertifiedSalesCount;
      } else {
        item2['dailyCertifiedSalesCount'] =
          copyReturnArray[j].dailyCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('monthlyCertifiedSalesCount')) {
        item2['monthlyCertifiedSalesCount'] +=
          copyReturnArray[j].monthlyCertifiedSalesCount;
      } else {
        item2['monthlyCertifiedSalesCount'] =
          copyReturnArray[j].monthlyCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('yearlyCertifiedSalesCount')) {
        item2['yearlyCertifiedSalesCount'] +=
          copyReturnArray[j].yearlyCertifiedSalesCount;
      } else {
        item2['yearlyCertifiedSalesCount'] =
          copyReturnArray[j].yearlyCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('customCertifiedSalesCount')) {
        item2['customCertifiedSalesCount'] +=
          copyReturnArray[j].customCertifiedSalesCount;
      } else {
        item2['customCertifiedSalesCount'] =
          copyReturnArray[j].customCertifiedSalesCount;
      }

      if (item2.hasOwnProperty('dailyCertifiedInventory')) {
        item2['dailyCertifiedInventory'] +=
          copyReturnArray[j].dailyCertifiedInventory;
      } else {
        item2['dailyCertifiedInventory'] =
          copyReturnArray[j].dailyCertifiedInventory;
      }

      if (item2.hasOwnProperty('monthlyCertifiedInventory')) {
        item2['monthlyCertifiedInventory'] +=
          copyReturnArray[j].monthlyCertifiedInventory;
      } else {
        item2['monthlyCertifiedInventory'] =
          copyReturnArray[j].monthlyCertifiedInventory;
      }

      if (item2.hasOwnProperty('yearlyCertifiedInventory')) {
        item2['yearlyCertifiedInventory'] +=
          copyReturnArray[j].yearlyCertifiedInventory;
      } else {
        item2['yearlyCertifiedInventory'] =
          copyReturnArray[j].yearlyCertifiedInventory;
      }

      if (item2.hasOwnProperty('customCertifiedInventory')) {
        item2['customCertifiedInventory'] +=
          copyReturnArray[j].customCertifiedInventory;
      } else {
        item2['customCertifiedInventory'] =
          copyReturnArray[j].customCertifiedInventory;
      }

      if (item2.hasOwnProperty('totalInvCount')) {
        item2['totalInvCount'] +=
          copyReturnArray[j].totalInvCount;
      } else {
        item2['totalInvCount'] =
          copyReturnArray[j].totalInvCount;
      }

      /*if (copyReturnArray[j].hasOwnProperty('reginalPercent')) {
        copyReturnArray[j]['reginalPercent'] = item2['monthlyCertifiedInventory'] !== 0 ?
          Number(Number(copyReturnArray[j]['monthlyCertifiedInventory'] /
            item2['monthlyCertifiedInventory']) * 100).toFixed(1) : 0;
      } else {
        copyReturnArray[j]['reginalPercent'] = item2['monthlyCertifiedInventory'] !== 0 ?
          Number(Number(copyReturnArray[j]['monthlyCertifiedInventory'] /
            item2['monthlyCertifiedInventory']) * 100).toFixed(1) : 0;
      }

      if (item2.hasOwnProperty('totalLexusDaily')) {

        item2['totalLexusDaily'] +=
          copyReturnArray[j].totalLexusDaily;
      } else {
        item2['totalLexusDaily'] =
          copyReturnArray[j].totalLexusDaily;
      }

      if (item2.hasOwnProperty('totalLexusMonthly')) {
        item2['totalLexusMonthly'] +=
          copyReturnArray[j].totalLexusMonthly;
      } else {
        item2['totalLexusMonthly'] =
          copyReturnArray[j].totalLexusMonthly;
      }

      if (item2.hasOwnProperty('certificationTotal')) {
        item2['certificationTotal'] +=
          copyReturnArray[j].certificationTotal;
      } else {
        item2['certificationTotal'] =
          copyReturnArray[j].certificationTotal;
      }

      if (item2.hasOwnProperty('inventoryTotal')) {
        item2['inventoryTotal'] +=
          copyReturnArray[j].inventoryTotal;
      } else {
        item2['inventoryTotal'] =
          copyReturnArray[j].inventoryTotal;
      }

      if (item2.hasOwnProperty('inventoryLessThan60Days')) {
        item2['inventoryLessThan60Days'] +=
          copyReturnArray[j].inventoryLessThan60Days;
      } else {
        item2['inventoryLessThan60Days'] =
          copyReturnArray[j].inventoryLessThan60Days;
      }

      if (item2.hasOwnProperty('salesObjPercent')) {
        item2['salesObjPercent'] =
          Number(Number(item2['salesObjPercent']) +
            Number(copyReturnArray[j].salesObjPercent)).toFixed(1);
      } else {
        item2['salesObjPercent'] =
          Number(copyReturnArray[j].salesObjPercent).toFixed(1);
      }

      if (item2.hasOwnProperty('certObjPercent')) {
        item2['certObjPercent'] =
          Number(Number(item2['certObjPercent']) +
            Number(copyReturnArray[j].certObjPercent)).toFixed(1);
      } else {
        item2['certObjPercent'] =
          Number(copyReturnArray[j].certObjPercent).toFixed(1);
      }*/
    }

    /*for (let j = 0; j < copyReturnArray.length; j++) {
      if (copyReturnArray[j].hasOwnProperty('reginalPercent')) {
        copyReturnArray[j]['reginalPercent'] = item2['monthlyCertifiedSalesCount'] !== 0 ?
          Number(Number(copyReturnArray[j]['monthlyCertifiedSalesCount'] /
            item2['monthlyCertifiedSalesCount']) * 100).toFixed(1) : 0;
      } else {
        copyReturnArray[j]['reginalPercent'] = item2['monthlyCertifiedSalesCount'] !== 0 ?
          Number(Number(copyReturnArray[j]['monthlyCertifiedSalesCount'] /
            item2['monthlyCertifiedSalesCount']) * 100).toFixed(1) : 0;
      }

      if (item2.hasOwnProperty('reginalPercent')) {
        item2['reginalPercent'] =
          Number(Number(item2['reginalPercent']) +
            Number(copyReturnArray[j].reginalPercent)).toFixed(1);
      } else {
        item2['reginalPercent'] =
          Number(copyReturnArray[j].reginalPercent).toFixed(1);
      }
    }

    if (item2.hasOwnProperty('salesObjPercent')) {
      item2['salesObjPercent'] =
        item2['salesObjective'] !== 0 ?
          Number(Number(item2['salesObjPercent']) +
            Number((item2['monthlyCertifiedSalesCount'] /
              item2['salesObjective']) * 100)).toFixed(1) : 0;
    } else {
      item2['salesObjPercent'] =
        item2['salesObjective'] !== 0 ?
          Number((item2['monthlyCertifiedSalesCount'] /
            item2['salesObjective']) * 100).toFixed(1) : 0;
    }

    if (item2.hasOwnProperty('certObjPercent')) {
      item2['certObjPercent'] = item2['certificationObjective'] !== '0' ?
        Number(Number(item2['certObjPercent']) +
          Number((item2['monthlyCertifiedInventory'] /
            item2['certificationObjective']) * 100)).toFixed(1) : 0;
    } else {
      item2['certObjPercent'] = item2['certificationObjective'] !== '0' ?
        Number((item2['monthlyCertifiedInventory'] /
          item2['certificationObjective']) * 100).toFixed(1) : 0;
    }*/

    // returnArray.push(item2);
  } else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '30')) {
    inventories.reportData = inventories.reportData.data;
    for (let i = 0; i < inventories.reportData.length; i++) {
      let totalRDR = (inventories.reportData[i].lastMonthRDR +
        inventories.reportData[i].monthBeforeLastMonthRDR +
        inventories.reportData[i].twoMonthBeforeLastMonthRDR);
      let totalReversal = (inventories.reportData[i].lastMonthReversal +
        inventories.reportData[i].monthBeforeLastMonthReversal +
        inventories.reportData[i].twoMonthBeforeLastMonthReversal);
      let item: any = {
        'dealerCd': inventories.reportData[i].dealerCode,
        'dealerName': null,
        'regionName': inventories.reportData[i].regionName,
        'regionNumber': inventories.reportData[i].regionNumber,
        'lastMonthRDR': inventories.reportData[i].lastMonthRDR,
        'monthBeforeLastMonthRDR': inventories.reportData[i].monthBeforeLastMonthRDR,
        'twoMonthBeforeLastMonthRDR': inventories.reportData[i].twoMonthBeforeLastMonthRDR,
        'totalRDR': totalRDR,
        'totalReversal': totalReversal,
        'total': (totalRDR - totalReversal),
        'avgRDR': Number((totalRDR - totalReversal) / 3).toFixed(1)
      };
      returnArray.push(item);
    }
  } 
  else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '34')) {

    inventories.reportData = inventories.reportData.data;
    inventories.reportData.map(inventory => {
      let obj: UCInventorySummaryReport = {};
      let invObj = inventory;

      obj.dealerCd = invObj.dealerCode;
      obj.districtNumber = invObj.districtNumber;
      obj.dealerName = invObj.dealerName;
      obj.regionName = invObj.regionName;
      obj.regionNumber = invObj.regionNumber;

      obj.dailyCertifiedInventory = invObj.dailyCertifiedInventory;
      obj.silverDailyCertifiedInventory = invObj.silverDailyCertifiedInventory;
      obj.goldDailyCertifiedInventory = invObj.goldDailyCertifiedInventory;

      obj.invCount = invObj.invCount;
      obj.silverInvCount = invObj.silverInvCount;
      obj.goldInvCount = invObj.goldInvCount;

      obj.certifiedSalesCount = invObj.certifiedSalesCount;
      obj.silverCertifiedSalesCount = invObj.silverCertifiedSalesCount;
      obj.goldCertifiedSalesCount = invObj.goldCertifiedSalesCount;

      obj.dailyCertifiedSalesCount = invObj.dailyCertifiedSalesCount;
      obj.goldDailyCertifiedSalesCount = invObj.goldDailyCertifiedSalesCount;
      obj.silverDailyCertifiedSalesCount = invObj.silverDailyCertifiedSalesCount;


      obj.certifiedInventory = invObj.certifiedInventory;
      obj.silverCertifiedInventory = invObj.silverCertifiedInventory;
      obj.goldCertifiedInventory = invObj.goldCertifiedInventory;

      returnArray.push(obj);
    });

    let copyReturnArray;
    copyReturnArray = returnArray;

    let item2: any = {
      'dealerCd': ''
    };
    // for (let j = 0; j < copyReturnArray.length; j++) {
    //   item2['dealerCd'] = 'TOTAL';

    //   if (item2.hasOwnProperty('invCount')) {
    //     item2['invCount'] +=
    //       copyReturnArray[j].invCount;
    //   } else {
    //     item2['invCount'] =
    //       copyReturnArray[j].invCount;
    //   }
    //   if (item2.hasOwnProperty('silverInvCount')) {
    //     item2['silverInvCount'] +=
    //       copyReturnArray[j].silverInvCount;
    //   } else {
    //     item2['silverInvCount'] =
    //       copyReturnArray[j].silverInvCount;
    //   }

    //   if (item2.hasOwnProperty('goldInvCount')) {
    //     item2['goldInvCount'] +=
    //       copyReturnArray[j].goldInvCount;
    //   } else {
    //     item2['goldInvCount'] =
    //       copyReturnArray[j].goldInvCount;
    //   }

    //   if (item2.hasOwnProperty('certifiedInventory')) {
    //     item2['certifiedInventory'] +=
    //       copyReturnArray[j].certifiedInventory;
    //   } else {
    //     item2['certifiedInventory'] =
    //       copyReturnArray[j].certifiedInventory;
    //   }

    //   if (item2.hasOwnProperty('silverCertifiedInventory')) {
    //     item2['silverCertifiedInventory'] +=
    //       copyReturnArray[j].silverCertifiedInventory;
    //   } else {
    //     item2['silverCertifiedInventory'] =
    //       copyReturnArray[j].silverCertifiedInventory;
    //   }

    //   if (item2.hasOwnProperty('goldCertifiedInventory')) {
    //     item2['goldCertifiedInventory'] +=
    //       copyReturnArray[j].goldCertifiedInventory;
    //   } else {
    //     item2['goldCertifiedInventory'] =
    //       copyReturnArray[j].goldCertifiedInventory;
    //   }

    //   if (item2.hasOwnProperty('dailyCertifiedInventory')) {
    //     item2['dailyCertifiedInventory'] +=
    //       copyReturnArray[j].dailyCertifiedInventory;
    //   } else {
    //     item2['dailyCertifiedInventory'] =
    //       copyReturnArray[j].dailyCertifiedInventory;
    //   }

    //   if (item2.hasOwnProperty('silverDailyCertifiedInventory')) {
    //     item2['silverDailyCertifiedInventory'] +=
    //       copyReturnArray[j].silverDailyCertifiedInventory;
    //   } else {
    //     item2['silverDailyCertifiedInventory'] =
    //       copyReturnArray[j].silverDailyCertifiedInventory;
    //   }

    //   if (item2.hasOwnProperty('goldDailyCertifiedInventory')) {
    //     item2['goldDailyCertifiedInventory'] +=
    //       copyReturnArray[j].goldDailyCertifiedInventory;
    //   } else {
    //     item2['goldDailyCertifiedInventory'] =
    //       copyReturnArray[j].goldDailyCertifiedInventory;
    //   }

    //   if (item2.hasOwnProperty('dailyCertifiedSalesCount')) {
    //     item2['dailyCertifiedSalesCount'] +=
    //       copyReturnArray[j].dailyCertifiedSalesCount;
    //   } else {
    //     item2['dailyCertifiedSalesCount'] =
    //       copyReturnArray[j].dailyCertifiedSalesCount;
    //   }

    //   if (item2.hasOwnProperty('silverDailyCertifiedSalesCount')) {
    //     item2['silverDailyCertifiedSalesCount'] +=
    //       copyReturnArray[j].silverDailyCertifiedSalesCount;
    //   } else {
    //     item2['silverDailyCertifiedSalesCount'] =
    //       copyReturnArray[j].silverDailyCertifiedSalesCount;
    //   }

    //   if (item2.hasOwnProperty('goldDailyCertifiedSalesCount')) {
    //     item2['goldDailyCertifiedSalesCount'] +=
    //       copyReturnArray[j].goldDailyCertifiedSalesCount;
    //   } else {
    //     item2['goldDailyCertifiedSalesCount'] =
    //       copyReturnArray[j].goldDailyCertifiedSalesCount;
    //   }

    //   if (item2.hasOwnProperty('certifiedSalesCount')) {
    //     item2['certifiedSalesCount'] +=
    //       copyReturnArray[j].certifiedSalesCount;
    //   } else {
    //     item2['certifiedSalesCount'] =
    //       copyReturnArray[j].certifiedSalesCount;
    //   }

    //   if (item2.hasOwnProperty('silverCertifiedSalesCount')) {
    //     item2['silverCertifiedSalesCount'] +=
    //       copyReturnArray[j].silverCertifiedSalesCount;
    //   } else {
    //     item2['silverCertifiedSalesCount'] =
    //       copyReturnArray[j].silverCertifiedSalesCount;
    //   }

    //   if (item2.hasOwnProperty('goldCertifiedSalesCount')) {
    //     item2['goldCertifiedSalesCount'] +=
    //       copyReturnArray[j].goldCertifiedSalesCount;
    //   } else {
    //     item2['goldCertifiedSalesCount'] =
    //       copyReturnArray[j].goldCertifiedSalesCount;
    //   }
    // }
    // returnArray.push(item2);
    return returnArray;
  }
  else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '35')) {

    inventories.reportData = inventories.reportData.data;
    inventories.reportData.map(inventory => {
      let obj: UCInventorySummaryReport = {};
      let invObj = inventory;

      obj.dealerCd = invObj.dealerCode;
      obj.districtNumber = invObj.districtNumber;
      obj.dealerName = invObj.dealerName;
      obj.regionName = invObj.regionName;
      obj.regionNumber = invObj.regionNumber;
      obj.certifiedInventory = invObj.certifiedInventory;
      obj.certifiedSalesCount = invObj.certifiedSalesCount;
      obj.invCount = invObj.invCount;

      obj.certChange = invObj.certChange;
      obj.certChangePercent = invObj.certChangePercent;
      obj.currentYearObjective = invObj.currentYearObjective;
      obj.prevYearlyCertifiedInventory = invObj.prevYearlyCertifiedInventory;
      obj.prevYearlyCertifiedSalesCount = invObj.prevYearlyCertifiedSalesCount;
      obj.prevYearlyInvCount = invObj.prevYearlyInvCount;
      obj.salesChange = invObj.salesChange;
      obj.salesChangePercent = invObj.salesChangePercent;

      returnArray.push(obj);
    });

    let copyReturnArray;
    copyReturnArray = returnArray;
  
    return returnArray;
  }
  else if (!isNil(inventories) && !isNil(inventories.type) &&
    (inventories.type === '36')) {

    inventories.reportData = inventories.reportData.data;
    inventories.reportData.map(inventory => {
      let obj: UCInventorySummaryReport = {};
      let invObj = inventory;

      obj.dealerCd = invObj.dealerCode;
      obj.districtNumber = invObj.districtNumber;
      obj.dealerName = invObj.dealerName;
      obj.regionName = invObj.regionName;
      obj.regionNumber = invObj.regionNumber;
      obj.certAfterSaleCount = invObj.certAfterSaleCount;
      obj.certAfterSalePercent = invObj.certAfterSalePercent;
      obj.sameDayCertCount = invObj.sameDayCertCount;
      obj.sameDayCertPercent = invObj.sameDayCertPercent;
      obj.totSalesCount = invObj.totSalesCount;
      obj.totalPercent = invObj.totalPercent;

      returnArray.push(obj);
    });

    let copyReturnArray;
    copyReturnArray = returnArray;
  
    return returnArray;
  }
   else {
    inventories.reportData = inventories.reportData.data;
    inventories.reportData.map(inventory => {
      let obj: UCInventorySummaryReport = {};
      let invObj = inventory._source;
      obj.vin = invObj.vin;
      obj.makeCode = invObj.makeCode;
      obj.count = invObj.count;
      obj.model = {};
      if (!isNil(invObj.type)) {
        obj.type = invObj.type;
      }
      if (!isNil(invObj.model)) {
        obj.model.modelNumber = invObj.model.modelNumber;
        obj.model.modelYear = invObj.model.modelYear;
        obj.model.modelDescription = invObj.model.modelDescription;
        obj.model.modelSeries = invObj.model.modelSeries;
        obj.modelDesc = invObj.model.modelDescription;
      }
      obj.dealerCd = invObj.dealerCd;
      obj.dis = invObj.daysInStock;
      obj.areaRegionNo = null;
      obj.dealerName = null;
      obj.regionName = null;
      obj.extColor = {};
      if (!isNil(invObj.extColor)) {
        obj.extColor.colorCd = invObj.extColor.colorCd;
        obj.extColor.colorDesc = invObj.extColor.colorDesc;
      }
      obj.intColor = {};
      if (!isNil(invObj.intColor)) {
        obj.intColor.colorCd = invObj.intColor.colorCd;
        obj.intColor.colorDesc = invObj.intColor.colorDesc;
      }

      obj.rdrDetail = {};
      if (!isNil(invObj.rdrDetail)) {
        obj.rdrDetail.soldAmount = invObj.rdrDetail.soldAmount;
        obj.rdrDetail.rdrStatus = invObj.rdrDetail.rdrStatus;
        obj.rdrDetail.retailDate = convertDateDisplay(invObj.rdrDetail.retailDate);
        obj.rdrDetail.mileage = invObj.rdrDetail.mileage;
        obj.rdrDetail.createdAt = convertDateDisplay(invObj.rdrDetail.createdAt);
        obj.rdrDetail.saleType = invObj.rdrDetail.saleType;
        obj.rdrDetail.salesPersonId = invObj.rdrDetail.salesPersonId;
        obj.rdrDetail.financeInsuranceManagerId = invObj.rdrDetail.financeInsuranceManagerId;
        obj.rdrDetail.buyer = {};
        obj.rdrDetail.reversalDate = invObj.rdrDetail.reversalDate ?
          convertDateDisplay(invObj.rdrDetail.reversalDate) : '-';
        if (!isNil(invObj.rdrDetail.buyer)) {
          if (!isNil(invObj.rdrDetail.buyer.firstName)) {
            obj.rdrDetail.buyer.firstName =
              invObj.rdrDetail.buyer.firstName + ' ' + invObj.rdrDetail.buyer.lastName;
          } else {
            obj.rdrDetail.buyer.firstName = invObj.rdrDetail.buyer.lastName;
          }
        }

      }
      // i guess its here
      obj.certification = {};
      if (!isNil(invObj.certification)) {
        obj.certification.certificationStatus = invObj.certification.certificationStatus;
        obj.certification.deCertifyReason = invObj.certification.deCertifyReason;
        obj.certification.warrantyCode = invObj.certification.warrantyCode;
        obj.certification.certificationNumber = invObj.certification.certificationNumber;
        if (!isNil(invObj.certification.certificationDate)) {
          obj.certification.certificationDate = inventories.type === '4' ?
            invObj.certification.certificationDate
            : convertDateDisplay(invObj.certification.certificationDate);
        }
        if (!isNil(invObj.certification.warrantyExpiryDate)) {
          obj.certification.warrantyExpiryDate =
            convertDateDisplay(invObj.certification.warrantyExpiryDate);
        }
        if ((String(invObj.certification.forceCertificationFlag).toLowerCase() === 'false')
          || (String(invObj.certification.forceCertificationFlag).toLowerCase()
            === 'null')) {
          obj.certification.forceCertificationFlag = 'NORMAL';
        } else if
          (String(invObj.certification.forceCertificationFlag).toLowerCase() === 'true') {
          obj.certification.forceCertificationFlag = 'FORCE CERTIFIED';
        } else {
          obj.certification.forceCertificationFlag =
            invObj.certification.forceCertificationFlag;
        }
      }
      obj.price = {};
      if (!isNil(invObj.price)) {
        obj.price.acvPrice = Number(invObj.price.acvPrice).toString();
        obj.price.retailAmount = Number(invObj.price.retailAmount).toString();
      }
      obj.inspection = {};
      if (!isNil(invObj.inspection)) {
        obj.inspection.inspectionFormNumber = invObj.inspection.inspectionFormNumber;
      }
      obj.repairOrder = {};
      if (!isNil(invObj.repairOrder)) {
        obj.repairOrder.repairOrderNumber1 = invObj.repairOrder.repairOrderNumber1;
        obj.repairOrder.repairOrderNumber2 = invObj.repairOrder.repairOrderNumber2;
        obj.repairOrder.dateofFirstRepairOrder =
          convertDateDisplay(invObj.repairOrder.dateofFirstRepairOrder);
        obj.repairOrder.dateofSecondRepairOrder =
          convertDateDisplay(invObj.repairOrder.dateofSecondRepairOrder);
        obj.repairOrder.reconditionCost = invObj.repairOrder.reconditionCost;
      }
      if (String(invObj.openCampaignStatus).toLowerCase() === 'false') {
        obj.openCampaignStatus = 'N';
      } else if (String(invObj.openCampaignStatus).toLowerCase() === 'true') {
        obj.openCampaignStatus = 'Y';
      } else {
        obj.openCampaignStatus = invObj.openCampaignStatus;
      }
      obj.acquiredDate = invObj.acquiredDate;
      if (!isNil(invObj.certification) && !isNil(invObj.acquiredDate)) {
        let _certificationDate = convertDateDisplay(invObj.certification.certificationDate);
        const acquiredDate = invObj.acquiredDate;
        obj.acquiredTOCert = convertToDays(acquiredDate, _certificationDate, 'acquiredTOCert');
        if (!isNil(invObj.rdrDetail)) {
          const retailDate = moment(invObj.rdrDetail.retailDate, 'YYYY-MM-DD').format('MM-DD-YYYY');
          obj.certTOSale = convertToDays(_certificationDate, retailDate, 'certTOSale');
        }
      }
      let certificationDate;
      if (!isNil(invObj.certification)) {
        certificationDate = convertDateDisplay(invObj.certification.certificationDate);
        obj.rdrDetail.rtVehicle = !isNil(invObj.certification.warrantyCode)
          ? invObj.certification.warrantyCode.toLowerCase() === 'mps' ? 'RT Vehicle' : 'Non-RT Vehicle'
          : 'Non-RT Vehicle';

      }
      obj.rdrDetail.sameDayCert = !isNil(invObj.rdrDetail && invObj.rdrDetail.retailDate) ?
        dateComp(certificationDate, convertDateDisplay(invObj.rdrDetail.retailDate)) : '';
      obj.rdrDetail.saleMonth = !isNil(invObj.rdrDetail && invObj.rdrDetail.retailDate) ?
        saleMonth_Year(invObj.rdrDetail.retailDate) : '';
      if (!isNil(invObj.rdrDetail && invObj.rdrDetail.mileage)) {
        const modelYear = !isNil(invObj.model && invObj.model.modelYear) 
        ? invObj.model.modelYear : 0;
        obj.rdrDetail.overlap = isOverlap(invObj.rdrDetail.mileage, modelYear);
      }
      if (!isNil(invObj.rdrDetail && invObj.rdrDetail.retailDate)) {
        const retailDate = moment(invObj.rdrDetail.retailDate, 'YYYY-MM-DD').format('MM-DD-YYYY');
        let _createdAt = convertDateDisplay(invObj.rdrDetail.createdAt);
        obj.rdrDetail.saleDateToRdrDate = convertToDays(retailDate, _createdAt, 'saleToRDR');
      }


      obj.mileage = invObj.mileage;
      if (!isNil(invObj.stockNumber)) {
        obj.stockNumber = invObj.stockNumber;
      }
      if (!isNil(invObj.updatedAt)) {
        let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        let firstDate = new Date(convertDateDisplay(new Date()));
        let secondDate = new Date(convertDateDisplay(invObj.updatedAt));
        obj.lotDays =
          Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
      }
      obj.dofu = moment(invObj.dofu, 'YYYY-MM-DD').format('MM-DD-YYYY');
      obj.comments = invObj.comments;
      obj.deCertifyReason = invObj.deCertifyReason;
      obj.accessoriesDetails = invObj.accessories;
      obj.accessories = '';
      obj.certificationStatus = invObj.certificationStatus;
      if (!isNil(invObj.certificationType)) {
        obj.certificationType = invObj.certificationType;
      } else {
        obj.certificationType = 'GOLD';
      }
      obj.deCertifyReason = invObj.deCertifyReason;
      obj.campaignNumber = invObj?.campaignCode || '';
      obj.certificationNumber = invObj.certificationNumber;
      obj.stockNumber = invObj.stockNumber;
      if (inventoryType === '5')
        obj.reason = getReason(invObj);
      if (!isNil(invObj.updatedBy)) {
        obj.createdBy = invObj.updatedBy;
        obj.updatedAt = convertDateDisplay(invObj.updatedAt);
      } else {
        obj.createdBy = invObj.createdBy;
        obj.updatedAt = convertDateDisplay(invObj.updatedAt);
      }

      if (!isNil(invObj.decertifiedAt))
        obj.decertifiedAt = convertDateDisplay(invObj.decertifiedAt);

      if (!isNil(invObj) && !isNil(invObj.accessories) && invObj.accessories.length > 0) {
        invObj.accessories.map(acc => {
          if (!isNil(acc.accessssoriesCode)) {
            if (!isNil(obj.accessories) && obj.accessories === '') {
              obj.accessories += acc.accessssoriesCode.trim();
            } else {
              obj.accessories += ' ' + acc.accessssoriesCode.trim();
            }
          }
        });
      }

      returnArray.push(obj);
    });
  }

  return returnArray;
}

function zeroPad(num, places) {
  let zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

function getReason(inventories: UCInventorySummaryReport) {
  if (!isNil(inventories.mileage) && Number(inventories.mileage) > Number(84999)
    && inventories.makeCode === 'TOYOTA') {
    return 'Mileage greater than 84999';
  } else if (!isNil(inventories.mileage) && Number(inventories.mileage) > Number(80000)
    && inventories.makeCode === 'LEXUS') {
    return 'Mileage greater than 80000';
  } else if (!isNil(inventories.model) && !isNil(inventories.model.modelYear) &&
    (new Date().getFullYear() - Number(inventories.model.modelYear)) > 6) {
    return 'Difference between Model year and current year greater than 6';
  } else if (!isNil(inventories.price) && !isNil(inventories.price.retailAmount)
    && Number(inventories.price.retailAmount) < Number('7000')) {
    return 'Retail amount less than 7000';
  } else if (!isNil(inventories.price) && !isNil(inventories.price.retailAmount)
    && Number(inventories.price.retailAmount) > Number('99000')) {
    return 'Retail amount greater than 99000';
  } else if (!isNil(inventories.certification)
    && !isNil(inventories.certification.certificationDate)) {
    let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    let firstDate = new Date(convertDateDisplay(new Date()));
    let secondDate =
      new Date(convertDateDisplay(inventories.certification.certificationDate));
    let diff =
      Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (oneDay)));
    if (diff > 60)
      return 'Difference between certified date and current date greater than 60';
  }
}

function convertDateDisplay(fromdate: any) {
  if (!isNil(fromdate)) {
    const sampleDate = new Date(fromdate);
    if(isNaN(sampleDate?.getTime())) {
      return '';
    }
    let date = new Date(fromdate).toISOString().substring(0, 10);
    let year = date.substr(0, 4);
    let month: any = date.substr(5, 2);
    let dt: any = date.substr(8, 2);

    return month + '-' + dt + '-' + year;
  } else return fromdate;

}

function dateComp(d1: any, d2: any) {
  const res = d1 === d2 ? 'Yes' : 'No';
  return res;
}

function saleMonth_Year(saleDate: any) {
  if (!isNil(saleDate)) {
    const date = new Date(saleDate);
    const monthYear1 = getMonthYearString(date);
    return monthYear1;
  } else return '';
}

function getMonthYearString(date: Date): string {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[date.getUTCMonth()]; // Use getUTCMonth()
  const year = date.getUTCFullYear(); // Use getUTCFullYear()
  return `${month} ${year}`;
}

function isOverlap(mileage: any, modelYear:any) {
  const mil = Number(mileage);
  let ageofVeh = Number(new Date().getFullYear()) - Number(modelYear);
  const res = mil >= 60000 && mil <= 85000  && ageofVeh <= 6 ? 'Yes' : 'No';    
  return res;
}

function daysInThisMonth() {
  let now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

function sortObj(arr, region) {
  let tuples = [];

  for (let key in arr)

    if (key !== 'type' && key !== 'metric' && key !== 'TOTAL' &&
      parseFloat(arr[key]) !== 0)
      tuples.push([key, parseFloat(arr[key])]);

  tuples.sort(function (a, b) {
    a = a[1];
    b = b[1];

    return b - a;
  });

  // return tuples;

  let result;

  for (let j = 0; j < tuples.length; j++) {
    if (tuples[j].indexOf(region) === 0 && parseFloat(tuples[j][1]) !== 0) {
      result = j;
    }
  }

  return result;
}

function convertToDays(d1: any, d2: any, type: string) {

  const date1 = new Date(d1);
  const date2 = new Date(d2);
  let differenceInTime: number;

  // To calculate the time difference of two dates
  if (type === 'acquiredTOCert') {
    differenceInTime = Math.abs(date2.getTime() - date1.getTime());
  } else {
    differenceInTime = Math.ceil(date2.getTime() - date1.getTime());
  }

  // To calculate the no. of days between two dates
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return Math.ceil(differenceInDays).toString();

}

