import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import * as messageActions from '../../../actions/message-data.action';
import * as messageDataActions from '../../../actions/message-data.action';
import * as vehicleReportActions from '../../../actions/vehicle-report.action';
import * as fromRoot from '../../../reducers';
import { ExportToExcelService } from '../../../services/common/excel-service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import * as models from '../../../services/model/models';
import { UCInventorySummaryView } from '../../../services/view-model/inventory-summary-view';
import * as viewModels from '../../../services/view-model/view-models';
import { ViewConstants } from '../../../shared/shared.constants';
import { InventorySummaryGridColumns } from './json/UCReportGridColumns';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment'
import * as alasql from 'alasql';

@Component({
  selector: 'uc-inventory',
  templateUrl: 'recall-report-page.component.html'
})

export class UCRecallPageReportComponent implements OnInit, OnDestroy {
  // Observable to save inventory screen data
  inventorySummaryData$: Observable<UCInventorySummaryView>;
  // Subscriber for Inventory Data
  inventorySummarySubscriber: any;
  dealerTotal: any;
  // Array variable used to store columns
  public columnDefs: any[] = [];
  public columnDefsTotal: any[] = [];
  dealerCodes: Array<any> = [];
  // Array variable to keep the original data for filter purpose
  originalData: Array<models.UCInventorySummary>;
  // Array variable to keep the filtered data for filter purpose
  filteredData: Array<models.UCInventorySummary>;
  // Array to hold the filters applied in the grid
  public filterArray: Array<models.Filter> = [];
  // Boolean to reveal whether the dealer code is selected or not
  isInventorySelected: boolean = false;
  // certificationStatus
  selectedCertificationStatus: string = '';
  // makeCode
  selectedMakeCode: string = '';
  // Dealer code entered in search toolbar
  enteredDealerCode: string;
  // vin entered in search toolbar
  enteredvin: string;
  // To store seleted vehicle index
  public selectedVehicleIndex: number = 0;
  // Boolean variable to show/hide the clear all button
  showClearAllButton: boolean;
  // Variable to display the inventories count
  totalInventoriesCount: any;

  // To hold the last sort event
  currentSortEvent: any;
  reportType: string;
  fromDate: string;
  toDate: string;
  // to clear the current sorting for all columns
  clearSort: boolean = false;
  totalSaleCount: number;
  reportStartDate: string;
  reportEndDate: string;
  regionSelection: string;
  districtSelection: string;
  labelText: string = 'Decertified Date';
  // Observable to obtain VehicleDetailView object
  vehicleDetails$: Observable<viewModels.VehicleDetailView>;
  // Observable to obtain RdrDetailView object
  rdrDetails$: Observable<viewModels.RdrDetailView>;
  // Private array variable to display the rows in grid
  private _rows: Array<models.UCInventorySummary>;
  // Property to set and get the rows
  get rows(): Array<models.UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<models.UCInventorySummary>) {
    if (value.length === 1 && value[0].vin === '') {
      this.totalInventoriesCount = 0;
    } else {
      if (!isNil(this.totalSaleCount)) {
        this.totalInventoriesCount =
          value.length + ' ( ' + this.totalSaleCount + ' )';
      } else {
        this.totalInventoriesCount = value.length;
      }
    }
    this._rows = value;
  }
  // private moment = require('moment');
  // selected Disclaimer
  private row: models.UCInventorySummary = {};
  /**
   * Constructor for UCInventoryPageComponent
   */
  constructor(private store: Store<fromRoot.AppState>,
    public sharedFuncService: SharedFunctionService,
    public sharedDataService: SharedDataService,
    public router: Router,
    private viewContainerRef: ViewContainerRef,
    private exportToExcelService: ExportToExcelService) {
    this.inventorySummaryData$ = store.select(fromRoot.getVehicleReport);
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.sharedDataService.paginationCount = 1;
    this.regionSelection = this.sharedDataService?.isGSTUser ? 'GULF STATES TOYOTA DISTRIBUTOR' :
      this.sharedDataService?.isSETUser ? 'SOUTHEAST TOYOTA DISTRIBUTOR' : 'ALL';
    this.districtSelection = 'ALL';
    this.onLoad('m');
    this.dealerTotal = [];
    this.totalSaleCount = 0;
    this.inventorySummarySubscriber = this.inventorySummaryData$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        this.refreshFilters();
        this.enteredDealerCode = '';
        this.enteredDealerCode = (<any>Object).assign(this.enteredDealerCode, '');
        this.enteredvin = '';
        this.enteredvin = (<any>Object).assign(this.enteredvin, '');
        this.isInventorySelected = false;
        this.selectedCertificationStatus = '';
        this.selectedMakeCode = '';
        if (!isNil(data.message)) {
          this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
            data.message
          ));
        } else {
          if (data.inventories.length > 0) {
            let copyData = data.inventories;
            this.reportStartDate = data.startDate;
            this.reportEndDate = data.endDate;
            this.totalSaleCount = data.count;
            if (!isNil(this.totalSaleCount)) {
              this.totalInventoriesCount =
                this.totalInventoriesCount + ' ( ' + this.totalSaleCount + ' )';
            }
            if (data.inventories.length !== 5000) {
              this.sharedDataService.isNextVisible = false;
            } else {
              this.sharedDataService.isNextVisible = true;
            }
            if (!isNil(this.sharedDataService.dealerData)) {
              copyData.forEach(element => {
                if (this.sharedDataService.dealerData
                  .filter(t => t.dealerCode === element.dealerCd).length > 0) {
                  element.dealerName = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode === element.dealerCd)[0].dealerName;
                  element.areaRegionNo = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode === element.dealerCd)[0].areaRegionNo;
                  element.regionName = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode
                      === element.dealerCd)[0].areaRegionName;
                  element.districtNo = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode
                      === element.dealerCd)[0].districtNo;
                } else {
                  element.dealerName = '';
                  element.areaRegionNo = '';
                  element.regionName = '';
                  element.districtNo = '';
                }
              });
            }
            this.dealerTotal = this.generateTotalGridData(copyData);
            this.rows = cloneDeep(sortBy(copyData, 'VIN'));
            this.originalData = cloneDeep(copyData);
            let message: models.Message = {};
            message.message = 'By default current month filter applied in the report grid';
            message.type = models.MessageType.WARNING;
            this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
          } else {
            this.rows = cloneDeep([]);
          }
        }
      }
    });
  }
  /**
   * onLoad method
   */
  onLoad(event: any) {
    this.reportType = event;
    this.regionSelection = this.sharedDataService?.isGSTUser ? 'GULF STATES TOYOTA DISTRIBUTOR' :
      this.sharedDataService?.isSETUser ? 'SOUTHEAST TOYOTA DISTRIBUTOR' : 'ALL';
    this.districtSelection = 'ALL';
    this.dealerTotal = [];
    this.totalSaleCount = 0;
    let regFilter = 0;
    let distFilter = 0;
    if (this.sharedDataService.isGSTUser || this.sharedDataService.isSETUser) {
      if ((this.regionSelection === 'ALL' || this.regionSelection === undefined))
        regFilter = 0;
      else regFilter = 1;
      if (this.districtSelection === 'ALL' || this.districtSelection === undefined)
        distFilter = 0;
      else distFilter = 1;
    }

    if (!isNil(this.sharedDataService.dealerData)) {
      if (this.sharedDataService.isGSTUser || this.sharedDataService.isSETUser) {
        this.sharedDataService.dealerData.map(e => {
          if (regFilter === 0 && distFilter === 0) {
            this.dealerCodes.push(e.dealerCode);
          } else if (regFilter === 1 && distFilter === 0) {
            if (e.areaRegionName === this.regionSelection) {
              this.dealerCodes.push(e.dealerCode);
            }
          } else if ((regFilter === 0 && distFilter === 1)) {
            if (e.districtNo === this.districtSelection) {
              this.dealerCodes.push(e.dealerCode);
            }
          } else {
            if (e.districtNo === this.districtSelection
              && e.areaRegionName === this.regionSelection) {
              this.dealerCodes.push(e.dealerCode);
            }
          }
        });
      }
      else {
        this.sharedDataService.dealerData.map(e => {
          this.dealerCodes.push(e.dealerCode);
        });
      }
    }
    let date = new Date();
    let dd = date.getDate().toString();
    let mm = (date.getMonth() + 1).toString();
    let yyyy = date.getFullYear();
    if (dd < '10') {
      dd = '0' + dd.toString;
    }
    if (mm < '10') {
      mm = '0' + mm;
    }
    if (this.reportType === 'd') {
      this.fromDate = mm + '/' + dd + '/' + yyyy;
      this.toDate = mm + '/' + dd + '/' + yyyy;
    } else if (this.reportType === 'm') {
      this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
      this.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
    } else if (this.reportType === 'y') {
      this.fromDate = '01' + '/' + '01' + '/' + yyyy;
      this.toDate = '12' + '/' + '31' + '/' + yyyy;
    }
    this.sharedFuncService.setCurrentScreenName(
      ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
    // this.store.dispatch(new dealerActions.LoadAllDealersAction({}));
    this.columnDefs = InventorySummaryGridColumns.gridColumns;
    this.columnDefsTotal = InventorySummaryGridColumns.totalGridColumns;
    this.sharedDataService.setSelectedVehicle('');
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    let report = {
      reportMode: '28',
      fromDate: null,
      toDate: null,
      from: 0,
      makeCode: this.sharedDataService.brandName,
      dealerCodes: this.dealerCodes
    };
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
    this.sharedDataService.paginationCount = 1;
    this.sharedDataService.isNextVisible = false;
  }

  reportLoad(event: any) {
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    this.reportType = 'i';
    this.fromDate = event.fromDate;
    this.toDate = event.toDate;
    this.regionSelection = event.region;
    this.districtSelection = event.district;
    this.dealerCodes = [];
    let distFilter = 0;
    this.dealerTotal = [];
    let indexNum = 0;
    let distNo;
    let regName;
    let regFilter = 0;
    this.dealerCodes = [];
    if ((this.regionSelection === 'ALL' || this.regionSelection === undefined))
      regFilter = 0;
    else regFilter = 1;
    if (this.districtSelection === 'ALL' || this.districtSelection === undefined)
      distFilter = 0;
    else distFilter = 1;

    if (!isNil(this.sharedDataService.dealerData)) {
      this.sharedDataService.dealerData.map(e => {
        if (regFilter === 0 && distFilter === 0) {
          this.dealerCodes.push(e.dealerCode);
        } else if (regFilter === 1 && distFilter === 0) {
          if (e.areaRegionName === this.regionSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        } else if ((regFilter === 0 && distFilter === 1)) {
          if (e.districtNo === this.districtSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        } else {
          if (e.districtNo === this.districtSelection
            && e.areaRegionName === this.regionSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        }
      });
    }
    let report = {
      reportMode: '28',
      fromDate: event.fromDate,
      toDate: event.toDate,
      makeCode: this.sharedDataService.brandName,
      from: 0,
      dealerCodes: this.dealerCodes
    };
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
    this.sharedDataService.paginationCount = 1;
    this.sharedDataService.isNextVisible = false;
  }

  refreshFilters() {
    this.showClearAllButton = false;
    if (!isNil(this.currentSortEvent)) {
      this.applyInventorySort(this.currentSortEvent);
    }
    this.clearSort = false;
    InventorySummaryGridColumns.gridColumns.forEach(column => {
      column.filterValue = '';
    });
  }

  applyInventorySort(event: any) {
    this.currentSortEvent = event;
    this.rows =
      cloneDeep(this.sharedFuncService.applySort(this.rows, event));
  }

  countIncreaseEvent(event: any) {
    let requestArray: Array<any> = [];
    while (Number(this.sharedDataService.paginationCount) < this.totalSaleCount) {
      let report = {
        reportMode: '28',
        fromDate: this.fromDate,
        toDate: this.toDate,
        makeCode: this.sharedDataService.brandName,
        from: this.sharedDataService.paginationCount - 1,
        dealerCodes: this.dealerCodes
      };
      requestArray.push(report);
      this.sharedDataService.paginationCount += 5000;
    }

    // requestArray.splice(requestArray.length - 1);

    if (requestArray.length > 1) {
      this.store.dispatch(
        new vehicleReportActions.GetInventoryReport(requestArray));
    }

  }

  countDecreaseEvent(event: any) {
    this.sharedDataService.paginationCount -= 5000;
    // let saledata: any = {};
    // saledata.dealerCd = this.sharedDataService.dealerCode;
    // saledata.paginationCount = this.sharedDataService.paginationCount - 1;
    // saledata.type = 'InitialLoad';
    let report = {
      reportMode: '28',
      fromDate: this.fromDate,
      toDate: this.toDate,
      makeCode: this.sharedDataService.brandName,
      from: this.sharedDataService.paginationCount - 1,
      dealerCodes: this.dealerCodes
    };
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
  }

  ApplyFilter(filter: models.Filter) {
    let tempFilter: models.Filter = null;
    let existingFilterInArrayIndex: number = 0;
    if (this.filterArray !== null && this.filterArray !== undefined) {
      this.filterArray.forEach(data => {
        if (data.filterType === filter.filterType
          && data.columnname === filter.columnname) {
          tempFilter = data;
        }
        if (tempFilter === null) {
          existingFilterInArrayIndex++;
        }
      });
      if (tempFilter !== null && tempFilter !== undefined) {
        this.filterArray.splice(existingFilterInArrayIndex, 1);
      }
      if (filter.filterValue !== null && filter.filterValue !== undefined
        && filter.filterValue.trim() !== '') {
        tempFilter = filter;
        this.filterArray.push(tempFilter);
      }
      this.filteredData = cloneDeep(this.originalData);
      this.filterArray.forEach(arrayElement => {
        if (!isNil(arrayElement.filterValue) && arrayElement.filterValue !== undefined &&
          arrayElement.filterValue.trim() !== '') {
          if (arrayElement.filterType === models.FilterType.CONTAINS) {
            this.applyContainsFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.STARTS_WITH) {
            this.applyStartsWithFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.SPACES) {
            this.applySpacesFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.PRICE_COLUMN) {
            this.applyPriceFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.DATE_SYMBOL) {
            this.applyDateFilter(arrayElement);
          }
        }
      });
      if (this.filterArray.length > 0) {
        this.showClearAllButton = true;
      } else {
        this.showClearAllButton = false;
      }
      // if (this.filteredData.length === 0) {
      //     let test: models.UCInventorySummary = {};
      //     test.vin = '';
      //     this.filteredData.push(test);
      // }
      this.totalSaleCount = this.filteredData.length;
      this.rows = cloneDeep(this.filteredData);
    }
  }

  applyContainsFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = String(this.accessProperties(inventory, filterElement.columnname));
      return !isNil(propertyValue) && propertyValue.toLowerCase().indexOf(filterValue) > -1;
    });
  }

  applyStartsWithFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = this.accessProperties(inventory, filterElement.columnname);
      return !isNil(propertyValue) &&
        String(propertyValue).toLowerCase()
          .startsWith(filterValue);
    });
  }

  applySpacesFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      return !isNil(inventory.accessoriesDetails)
        && inventory.accessoriesDetails !== undefined
        && this.accessoriesFilter(inventory.accessoriesDetails, filterValue);
    });
  }

  accessoriesFilter(accessories: models.AccessoryDetails[], value: string): boolean {
    let filterValues = String(value).split(' ');
    let returnValue = false;
    let positiveCount = 0;
    if (!isNil(accessories) && accessories.length > 0) {
      filterValues.forEach(filter => {
        if (accessories.filter(acc =>
          acc.accessssoriesCode.trim().toLowerCase() === filter ||
          acc.accessssoriesCode.trim().toLowerCase().startsWith(filter)).length > 0) {
          positiveCount++;
        }
      });
    }
    if (positiveCount === filterValues.length) {
      return true;
    }
    return false;
  }

  applyPriceFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    // if (fullFilterValue.length > 1) {
    let firstLetter = fullFilterValue.substr(0, 1);
    let filterVal = Number.parseFloat(fullFilterValue.substr(1, fullFilterValue.length));
    this.filteredData = this.filteredData.filter(inventory => {
      let propValue = this.accessProperties(inventory, filterElement.columnname);
      if (!isNil(propValue)) {
        if (firstLetter === '<') {
          return Number.parseFloat(propValue) <= filterVal;
        } else if (firstLetter === '>') {
          return Number.parseFloat(propValue) >= filterVal;
        } else if (firstLetter === '=') {
          return Number.parseFloat(propValue) === filterVal;
        } else {
          filterVal = Number.parseFloat(fullFilterValue.
            substr(0, fullFilterValue.length));
          return Number.parseFloat(propValue) === filterVal;
        }
      }
      return true;
    });
    // }
  }

  applyDateFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    if (fullFilterValue.length > 1) {
      let firstLetter = fullFilterValue.substr(0, 1);
      let filterVal = fullFilterValue.substr(1, fullFilterValue.length);
      this.filteredData = this.filteredData.filter(inventory => {
        let propValue = this.accessProperties(inventory, filterElement.columnname);
        if (!isNil(propValue)) {
          if (firstLetter === '<') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') <= d;
            }
          } else if (firstLetter === '>') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') >= d;
            }

          } else if (firstLetter === '=') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }

          } else {
            filterVal = fullFilterValue.
              substr(0, fullFilterValue.length);
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }
          }
        }
        return true;
      });
    }
  }

  accessProperties(object: any, str: string) {
    let propertiesString = str.split('.');
    let i = 0;
    propertiesString.forEach(val => {
      object = object[propertiesString[i]];
      i++;
    });
    return object;
  }

  clearAllFilters() {
    this.rows = cloneDeep(this.originalData);
    this.filteredData = cloneDeep(this.originalData);
    this.filterArray = [];
    this.refreshFilters();
  }

  getFormattedTime() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let h = today.getHours();
    let mi = today.getMinutes();
    let s = today.getSeconds();
    return y + '' + m + '' + d + '' + h + '' + mi + '' + s;
  }

  getFormattedDateForDisplay() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    return m + '/' + d + '/' + y;
  }

  getDateRange(type: string): string {
    let date = new Date();
    if (type === 'm') {
      return this.sharedFuncService.convertDateDisplay(this.reportStartDate)
        + ' THRU ' + this.sharedFuncService.convertDateDisplay(this.reportEndDate);
    } else if (type === 'y') {
      return '01/01/' + date.getFullYear() + ' THRU ' + '12/31/' + date.getFullYear();
    } else if (type === 'd') {
      return this.convertDateDisplay(date) + ' THRU ' + this.convertDateDisplay(date);
    } else if (type === 'i') {
      return this.convertDateDisplay(this.fromDate) + ' THRU ' +
        this.convertDateDisplay(this.toDate);
    }
  }

  convertDateDisplay(fromdate: any) {
    let date = new Date(fromdate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return month + '/' + dt + '/' + year;
  }

  // To print the Inventory grid data
  downLoadPdf() {
    setTimeout(() => {
      this.sharedDataService.showLoader = true;
    }, 0);
    let doc: any = null;
    let fileName = 'RecallReport' + this.getFormattedTime();
    let reportHeaderText = 'RECALL REPORT';
    let inventoryData = this.inventoryGridData();
    let inventoryTotalData = this.inventoryTotalGridData();
    if (!isNil(inventoryData) && !isNil(inventoryData.columns)) {
      if (inventoryData.columns.length <= 5) {
        doc = new jsPDF('landscape', 'mm', 'a4');
      } else if (inventoryData.columns.length <= 10) {
        doc = new jsPDF('landscape', 'mm', 'a3');
      } else if (inventoryData.columns.length <= 15) {
        doc = new jsPDF('landscape', 'mm', 'a2');
      } else if (inventoryData.columns.length <= 20) {
        doc = new jsPDF('landscape', 'mm', 'a1');
      } else {
        doc = new jsPDF('landscape', 'mm', 'a0');
      }
    }
    if (doc !== null) {
      let totalPagesExp = '{total_pages_count_string}';
      doc.setFontSize(15);
      doc.text('Report Generated Date: ' + this.getFormattedDateForDisplay(), 500, 22);
      doc.setFontSize(20);
      doc.text(170, 15, ViewConstants.APPLICATION_TITLE_FOR_REPORT);
      doc.setFontSize(15);
      doc.text(180, 25, reportHeaderText);
      doc.text(230, 32, this.getDateRange(this.reportType));

      let pageContent = function (data) {
        let str = 'Page ' + doc.internal.getNumberOfPages();
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' of ' + totalPagesExp;
        }
        let pageSize = doc.internal.pageSize;
        let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.setFontSize(12);
        doc.text(str, 5, pageHeight - 10);
      };
      doc.autoTable(inventoryTotalData.columns, inventoryTotalData.rows, {
        startY: 40,
        styles: { overflow: 'linebreak', fontSize: 13 },
        columnStyles: {
          0: { columnWidth: 100 },
          1: { columnWidth: 80 },
          2: { columnWidth: 80 }
        },
        addPageContent: pageContent
      });
      let finalY = doc.autoTable.previous.finalY;
      doc.autoTable(inventoryData.columns, inventoryData.rows, {
        startY: finalY + 20,
        styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
        columnStyles: { text: { columnWidth: 'auto' } },
        addPageContent: pageContent
      });

      if (typeof doc.putTotalPages === 'function') {
        doc.putTotalPages(totalPagesExp);
      }
      doc.save(fileName + '.pdf');
    }

    setTimeout(() => {
      this.sharedDataService.showLoader = false;
    }, 0);
  }

  // To download the xlsx in inventory screen
  downLoadXlsx() {
    let reportHeaderText = 'RECALL REPORT';
    let exportData = {
      'title': reportHeaderText,
      'range': this.getDateRange(this.reportType),
      'header': this.inventoryGridData().columns,
      'data': this.inventoryGridData().rows,
      'isTotalGrid': false,
      'totalHeader': this.inventoryTotalGridData().columns,
      'totalData': this.inventoryTotalGridData().rows,
      'generationDate': this.getFormattedDateForDisplay(),
      'fileName': 'RecallReport'
    };
    this.exportToExcelService.exportAsExcelFile(exportData);

    this.sharedDataService.showLoader = false;

  }

  // To download the csv in inventory screen
  downLoadCsv() {
    let tempData: any;
    let inventoryDataNew: Array<any> = [];
    this.rows.forEach(row => {
      tempData = {};
      InventorySummaryGridColumns.gridColumns.forEach(column => {
        let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
        tempData[column.key] = columnField;
      });
      inventoryDataNew.push(tempData);
    });
    alasql('SELECT * INTO CSV("inventorySummary.csv",{headers:true, separator:","}) FROM ?',
      [inventoryDataNew] as any);
    this.sharedDataService.showLoader = false;

  }

  // To get inventory grid data
  inventoryGridData(): any {
    let columns: Array<any> = [];
    let rows: Array<any> = [];
    if (InventorySummaryGridColumns.gridColumns && this.rows) {
      InventorySummaryGridColumns.gridColumns.forEach(column => {
        columns.push(column.key);
      });
      let tempRows: Array<any> = [];
      this.rows.forEach(row => {
        tempRows = [];
        InventorySummaryGridColumns.gridColumns.forEach(column => {
          let columnField =
            this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
          tempRows.push(columnField);
        });
        rows.push(tempRows);
      });
    }
    let temps: any = {};
    temps.rows = rows;
    temps.columns = columns;
    return temps;
  }

  // To get inventory grid total data
  inventoryTotalGridData(): any {
    let columns: Array<any> = [];
    let rows: Array<any> = [];
    if (InventorySummaryGridColumns.totalGridColumns && this.dealerTotal) {
      InventorySummaryGridColumns.totalGridColumns.forEach(column => {
        columns.push(column.key);
      });
      let tempRows: Array<any> = [];
      this.dealerTotal.forEach(row => {
        tempRows = [];
        InventorySummaryGridColumns.totalGridColumns.forEach(column => {
          let columnField =
            this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
          tempRows.push(columnField);
        });
        rows.push(tempRows);
      });
    }
    let temps: any = {};
    temps.rows = rows;
    temps.columns = columns;
    return temps;
  }

  generateTotalGridData(data: any) {

    console.log('dATA to get TOTAL GRID TABLE');
    console.log(data);
    let totalData = [];
    const result1 = data.reduce((total, value) => {
      let a1: any;
      let a2: any;

      total[value.dealerCd] = (total[value.dealerCd] || 0) + 1;
      total[value.districtNo] = (total[value.districtNo] || 0) + 1;

      return total;
    }, {});

    totalData = Object.keys(result1).map(element => {

      if (element.length < 3) return;
      return {
        dealer: element,
        total: result1[element],

        dealerName:
          data.filter(e => e.dealerCd === element)[0].dealerName,
        regionName:
          data.filter(e => e.dealerCd === element)[0].regionName,
        districtNo:
          data.filter(e => e.dealerCd === element)[0].districtNo,
        districtTotal: result1[data.filter(e => e.dealerCd === element)[0].districtNo]

      };
    });

    console.log('TOTAL GRID DATA GENERATED');
    console.log(totalData);
    totalData = totalData.filter(value => {
      if (value !== undefined) {
        return value;
      }
    });
    console.log(totalData);
    return totalData;
  }

  ngOnDestroy() {
    if (this.inventorySummarySubscriber) {
      this.inventorySummarySubscriber.unsubscribe();
    }
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
  }

}


