import { Injectable } from '@angular/core';
import { SharedDataService } from './shared-data.service';
import * as models from '../model/models';
import { isNil, sortBy, cloneDeep } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { Logger } from '../../shared/components/helpers/Logger';
import { ScreenMessages } from '../../shared/master-data/messages';
import { ViewConstants } from '../../shared/shared.constants';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class SharedFunctionService {
  /**
   * Variable is used to can Rethrow the error message
   */
  public canRethrowError: boolean = false;

  /***
   * Constructor for Shared Data Service
   */
  constructor(private logger: Logger, private sharedDataService: SharedDataService) {
  }
  /**
   *
   * @param screenName method for setting currentScreenName's dynamic
   */
  public setCurrentScreenName(screenName: string) {
    setTimeout(() => {
      this.sharedDataService.currentScreenName = screenName;
      if (screenName === ViewConstants.INVENTORY_SUMMARY_SCREEN_NAME ||
        screenName === ViewConstants.RDR_SUMMARY_SCREEN_NAME
        || screenName === ViewConstants.SALE_SUMMARY_SCREEN_NAME ||
        screenName === ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME) {
        this.sharedDataService.pageTitle = screenName;
      }
    }, 0);
  }

  /***
   * Method to increment the service call count
   */
  public IncrementServiceCallCount() {
    this.sharedDataService.serviceCallCount++;
    setTimeout(() => {
      this.sharedDataService.showLoader = true;
    }, 0);
  }

  /***
   * Method to decrement the service call count
   */
  DecrementServiceCallCount() {
    this.sharedDataService.serviceCallCount--;
    if (this.sharedDataService.serviceCallCount === 0) {
      setTimeout(() => {
        this.sharedDataService.showLoader = false;
      }, 0);
    }
  }

  /***
   * Method to decrement the service call count
   */
  setDealerDetails(dealerDetails: models.DealerDetailsModel) {
    if (!isNil(dealerDetails)) {
      this.sharedDataService.dealerName = dealerDetails.dealerName;
      this.sharedDataService.dealerNameCopy = this.add3Dots(dealerDetails.dealerName, 15);
      this.sharedDataService.dealerCode = dealerDetails.dealerCode;
      this.sharedDataService.selectedDealer = dealerDetails;
      // this.sharedDataService.brandName = dealerDetails.brand;
    }
  }

  convertDateDisplay(fromdate: any) {
    if (!isNil(fromdate)) {
      let date = new Date(fromdate).toISOString().substring(0, 10);
      let year = date.substr(0, 4);
      let month: any = date.substr(5, 2);
      let dt: any = date.substr(8, 2);

      return month + '-' + dt + '-' + year;
    } else return fromdate;

  }

  convertDateDisplayForRetailDate(fromdate: any) {
    let date = new Date(fromdate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return year + '-' + month + '-' + dt;
  }

  getMonth() {
    let date = new Date();
    if (date.getMonth() === 12) {
      return 1;
    } else {
      return date.getMonth() + 1;
    }
  }

  getCertificationType(type: string) {
    if (type === 'C') {
      return 'CERTIFIED';
    } else if (type === 'U') {
      return 'NON CERTIFIED';
    } else return '';
  }
  /***
   * Method to hanlde error
   */
  handleError(error: HttpErrorResponse | string): Observable<any> {
    let errMsg: string;

    if(this.canRethrowError || typeof error === 'string') {
      this.canRethrowError = false;
      if (error instanceof HttpErrorResponse) {
        errMsg = `${error.status} - ${error.statusText || ''} ${error.error}`;
      } else {
        errMsg =  error.toString();
      }
      return throwError(errMsg);
    }

    if (error.status === 404 || error.error === ScreenMessages.NO_INFO_MESSAGE) {
      return throwError(ScreenMessages.NO_INFO_MESSAGE);
    } else if (error.status === 0 || error.error === ScreenMessages.SERVICE_FAILURE_MESSAGE) {
      return throwError(ScreenMessages.SERVICE_FAILURE_MESSAGE);
    } else if (error.status === 403 || error.error === ScreenMessages.SESSION_EXPIRY_MESSAGE) {
      return throwError(ScreenMessages.SESSION_EXPIRY_MESSAGE);
    } else {
      let errorMsg = '';

      if (error.error?.errorDesc instanceof Array) {
        errorMsg = error.error.errorDesc.reduce((acc:string, curr:string )=> `${acc}, ${curr}`, '');
        let errorDetails = cloneDeep(error.error);
        errorDetails.errorDesc = null;
        errorDetails.errorDesc = errorMsg + '.';
        return throwError(errorDetails);
      } else if (error.error.errorDesc !== undefined) {
        error.error.errorDesc = error.error.errorDesc + '.';
        return throwError(error.error);
      } else {
        return throwError(ScreenMessages.SERVICE_FAILURE_MESSAGE);
      }

    }
    
  }

  /*
  * This method is used to obtain the values of nested property
  * for filtering the data of Vehicle Group pricing Grid
  */
  recurseObjProp(root, leafs, index): any {
    index = index ? index : 0;
    let upper = root;
    let leafChild = leafs.split('.');
    let lower: any;
    if (leafChild.length > 2) {
      // walk down one level :TODO - Hacked as index based traversing not working!
      if (!isNil(upper[leafChild[0]][leafChild[1]])) {
        lower = upper[leafChild[0]][leafChild[1]][leafChild[2]];
      } else {
        lower = null;
      }
    } else if (leafChild.length === 2) {
      // walk down one level :TODO - Hacked as index based traversing not working!
      lower = upper[leafChild[0]][leafChild[1]];
    } else {
      lower = upper[leafChild[0]];
    }
    // Check if last leaf has been hit by having gone one step too far.
    // If so, return result from last step.
    if (lower || lower === 0) {
      return lower;
    } else if (lower === '' || lower === null || lower === undefined) {
      return '';
    }
    // Else: recurse!
    index++;

    return this.recurseObjProp(lower, leafs, index);
  }

  /**
   * To get Column sort icon.
   */
  getColumnSortIcon(columnName: string, sortColumnsList: any[]): string {
    let sortOrder = '';
    if (sortColumnsList.findIndex(x => x.columnName === columnName) > -1) {
      sortOrder = sortColumnsList.find(x => x.columnName === columnName).sortOrder;
    }
    return sortOrder;
  }

  /**
   * To apply Sorting
   */
  applySort(rows: any[], event: any): any[] {
    if (event.sortOrder === 'ASC') {
      if (event.columnName === 'avgCertPrice')
        return sortBy(rows, item => parseFloat(item.avgCertPrice.slice(1)), event.columnName);
      else if (event.columnName === 'avgNonCertPrice')
        return sortBy(rows, item => parseFloat(item.avgNonCertPrice.slice(1)), event.columnName);
      else
        return sortBy(rows, event.columnName);
    } else {
      if (event.columnName === 'avgCertPrice')
        return sortBy(
          rows, item => parseFloat(item.avgCertPrice.slice(1)), event.columnName).reverse();
      else if (event.columnName === 'avgNonCertPrice')
        return sortBy(
          rows, item => parseFloat(item.avgNonCertPrice.slice(1)), event.columnName).reverse();
      else
        return sortBy(rows, event.columnName).reverse();
    }
  }

  formatDatevalue(dateValue: string): string {
    let date = new Date(dateValue);
    let day = this.appendZeroToDate(date.getDate());
    let month = this.appendZeroToDate(date.getMonth() + 1);
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = this.appendZeroToDate(date.getMinutes());
    let h = (hours % 12) || 12;
    let ampm = hours < 12 ? 'AM' : 'PM';
    let timeString = this.appendZeroToDate(h) + ':' + minutes.toString() + ' ' + ampm;
    // let formattedDate = timeString + ' ' +
    //     month + day.toString() + year.toString().substr(2, 2);
    let formattedDate =
      month + '/' + day.toString() + year.toString();
    return formattedDate;
  }

  /**
   * Method is used to add 0 for single digit numbers
   */
  appendZeroToDate(value: number): string {
    return value > 9 ? '' + value : '0' + value;
  }

  add3Dots(stringVal: string, limit: number) {
    let dots = '...';
    if (stringVal.length > limit) {
      stringVal = stringVal.substring(0, limit) + dots;
    }
    return stringVal;
  }
}
