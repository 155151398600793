import { CurrencyPipe, PercentPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import * as messageActions from '../../../actions/message-data.action';
import * as messageDataActions from '../../../actions/message-data.action';
import * as vehicleReportActions from '../../../actions/vehicle-report.action';
import * as fromRoot from '../../../reducers';
import { ExportToExcelService } from '../../../services/common/excel-service';
import { SharedDataService } from '../../../services/common/shared-data.service';
import { SharedFunctionService } from '../../../services/common/shared-function.service';
import * as models from '../../../services/model/models';
import { UCInventorySummaryView } from '../../../services/view-model/inventory-summary-view';
import * as viewModels from '../../../services/view-model/view-models';
import { DealerDetailsDialogService } from '../../../shared/components/dealer-dialog/dealer-details-dialog.service';
import { ViewConstants } from '../../../shared/shared.constants';
import { RegionalModelLineReportGridColumns } from './json/RegionalModelLineReportGridColumns';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as alasql from 'alasql';
import * as moment from 'moment'

@Component({
  selector: 'uc-regional-model-line-rdr-report',
  templateUrl: 'regional-model-line-rdr-report-page.component.html'
})

export class UCRegionalModelLineRdrReportPageReportComponent implements OnInit, OnDestroy {
  // Observable to save inventory screen data
  inventorySummaryData$: Observable<UCInventorySummaryView>;
  // Subscriber for Inventory Data
  inventorySummarySubscriber: any;
  // Route param subscriber to get the VIN to be loaded
  routeParamSubscriber: any;
  // Array variable used to store columns
  public columnDefs: any[] = [];
  // Array variable to keep the original data for filter purpose
  originalData: Array<models.UCInventorySummary>;
  // Array variable to keep the filtered data for filter purpose
  filteredData: Array<models.UCInventorySummary>;
  // Array to hold the filters applied in the grid
  public filterArray: Array<models.Filter> = [];
  // Boolean to reveal whether the dealer code is selected or not
  isInventorySelected: boolean = false;
  // certificationStatus
  selectedCertificationStatus: string = '';
  // makeCode
  selectedMakeCode: string = '';
  // To store seleted vehicle index
  public selectedVehicleIndex: number = 0;
  // Boolean variable to show/hide the clear all button
  showClearAllButton: boolean;
  // Variable to display the inventories count
  totalInventoriesCount: Number = 0;
  // Dealer code entered in search toolbar
  enteredDealerCode: string;
  // vin entered in search toolbar
  enteredvin: string;
  // To hold the last sort event
  currentSortEvent: any;
  // to clear the current sorting for all columns
  clearSort: boolean = false;
  reportType: string;
  // Observable to obtain VehicleDetailView object
  vehicleDetails$: Observable<viewModels.VehicleDetailView>;

  dealerCodes: Array<any> = [];

  regionSelection: string;

  certificationSelection: string;

  reportMode: string;

  reportHeaderText: string = '';

  reportFileName: string = '';

  fromDate: string;

  toDate: string;

  reportSelection: string;
  reportStartDate: string;
  reportEndDate: string;

  // Observable to obtain RdrDetailView object
  rdrDetails$: Observable<viewModels.RdrDetailView>;
  // Private array variable to display the rows in grid
  private _rows: Array<models.UCInventorySummary>;
  // Property to set and get the rows
  get rows(): Array<models.UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<models.UCInventorySummary>) {
    if (value.length === 1 && value[0].vin === '') {
      this.totalInventoriesCount = 0;
    } else {
      this.totalInventoriesCount = value.length;
    }
    this._rows = value;
  }
  // private moment = require('moment');
  // selected Disclaimer
  private row: models.UCInventorySummary = {};
  /**
   * Constructor for UCInventoryPageComponent
   */
  constructor(private store: Store<fromRoot.AppState>,
    public sharedFuncService: SharedFunctionService,
    public sharedDataService: SharedDataService,
    public router: Router,
    private viewContainerRef: ViewContainerRef,
    public activeRouter: ActivatedRoute,
    private cb: CurrencyPipe,
    private pr: PercentPipe,
    private dealerDetailsDialogService: DealerDetailsDialogService,
    private exportToExcelService: ExportToExcelService) {
    this.inventorySummaryData$ = store.select(fromRoot.getVehicleReport);
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    this.columnDefs = RegionalModelLineReportGridColumns.gridColumns;

    this.routeParamSubscriber = this.activeRouter.params.subscribe(params => {
      if (!isNil(params['type'])) {
        this.reportType = params['type'];
        if (this.reportType === '8m')
          this.reportLoad('m');
        else if (this.reportType === '9') {
          if (this.sharedDataService.brandName === 'TOYOTA') {
            this.enteredDealerCode = '31119';
          } else {
            this.enteredDealerCode = '64204';
          }
          let data = {
            region: undefined,
            reportType: 'm',
            dealerCode: this.enteredDealerCode
          };
          this.reportLoad(data);
        } else if (this.reportType !== '9')
          this.onLoad('m');
      }
    });
    this.inventorySummarySubscriber = this.inventorySummaryData$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        this.refreshFilters();
        this.enteredvin = '';
        this.enteredvin = (<any>Object).assign(this.enteredvin, '');
        this.isInventorySelected = false;
        this.selectedCertificationStatus = '';
        this.selectedMakeCode = '';
        if (!isNil(data.message)) {
          this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
            data.message
          ));
        } else {
          if (data.inventories.length > 0) {
            data.inventories.forEach(element => {
              if (element.avgTransPrice !== '0' && element.avgTransPrice !== '$0.00')
                element.avgTransPrice =
                  this.cb.transform(element.avgTransPrice.toString(), 'USD', true, '1.0-0');
              else element.avgTransPrice = '$0';
              if (element.avgReconCost !== '0' && element.avgReconCost !== '$0.00')
                element.avgReconCost =
                  this.cb.transform(element.avgReconCost.toString(), 'USD', true, '1.0-0');
              else element.avgReconCost = '$0';
              if (element.mixPercent !== '0' && element.mixPercent !== '$0.00')
                element.mixPercent = this.pr.transform(element.mixPercent, '2.2-2');
              else element.mixPercent = '0.00%';
              if (element.inventoryMix !== '0' && element.inventoryMix !== '$0.00')
                element.inventoryMix = this.pr.transform(element.inventoryMix, '2.2-2');
              else element.inventoryMix = '0.00%';
            });
            let copyData = data.inventories;
            this.reportStartDate = data.startDate;
            this.reportEndDate = data.endDate;
            if (!isNil(this.sharedDataService.dealerData)) {
              copyData.forEach(element => {
                if (this.sharedDataService.dealerData
                  .filter(t => t.dealerCode === element.dealerCd).length > 0) {
                  element.dealerName = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode === element.dealerCd)[0].dealerName;
                  element.areaRegionNo = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode === element.dealerCd)[0].areaRegionNo;
                  element.regionName = this.sharedDataService.dealerData
                    .filter(t => t.dealerCode
                      === element.dealerCd)[0].areaRegionName;
                } else {
                  element.dealerName = '';
                  element.areaRegionNo = '';
                  element.regionName = '';
                }
              });
            }
            this.rows = cloneDeep(sortBy(copyData, 'VIN'));
            this.originalData = cloneDeep(copyData);
            if (this.reportType === '8m' && this.regionSelection === undefined) {
              let message: models.Message = {};
              message.message =
                'By default current month and all region filter applied in the report grid';
              message.type = models.MessageType.WARNING;
              this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
            }
          } else {
            this.rows = cloneDeep([]);
            if (this.reportType === '8m' && this.regionSelection === undefined) {
              let message: models.Message = {};
              message.message =
                'By default current month and all region filter applied in the report grid';
              message.type = models.MessageType.WARNING;
              this.store.dispatch(new messageActions.SetDisplayMessageAction(message));
            }
          }
        }
      }
    });
    this.regionSelection = this.sharedDataService?.isGSTUser ? 'GULF STATES TOYOTA DISTRIBUTOR' :
    this.sharedDataService?.isSETUser ? 'SOUTHEAST TOYOTA DISTRIBUTOR' : 'ALL';
  }

  displayData(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    if (this.reportType === '8m' || this.reportType === '9') {
      this.reportLoad(event);
    } else {
      this.onLoad(event);
    }
  }
  /**
   * onLoad method
   */
  onLoad(event: any) {
    this.regionSelection = this.sharedDataService?.isGSTUser ? 'GULF STATES TOYOTA DISTRIBUTOR' :
    this.sharedDataService?.isSETUser ? 'SOUTHEAST TOYOTA DISTRIBUTOR' : 'ALL';
    this.reportType = event;
    let date = new Date();
    let dd = date.getDate().toString();
    let mm = (date.getMonth() + 1).toString();
    let yyyy = date.getFullYear();
    if (dd < '10') {
      dd = '0' + dd.toString;
    }
    if (mm < '10') {
      mm = '0' + mm;
    }
    if (this.reportType === 'd') {
      this.fromDate = mm + '/' + dd + '/' + yyyy;
      this.toDate = mm + '/' + dd + '/' + yyyy;
    } else if (this.reportType === 'm') {
      this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString();
      this.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString();
    } else if (this.reportType === 'y') {
      this.fromDate = '01' + '/' + '01' + '/' + yyyy;
      this.toDate = '12' + '/' + '31' + '/' + yyyy;
    }
    this.sharedFuncService.setCurrentScreenName(
      ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
    // this.columnDefs = RegionalModelLineReportGridColumns.gridColumns;
    this.sharedDataService.setSelectedVehicle('');
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    let report: any;
    if (this.reportType === 'y') {
      report = {
        reportMode: '7',
        currentYear: date.getFullYear(),
        makeCode: this.sharedDataService.brandName
      };
    } else if (this.reportType === '8') {
      if (this.sharedDataService.dealerData !== undefined) {
        report = {
          reportMode: '8',
          currentMonth: this.sharedFuncService.getMonth(),
          currentYear: date.getFullYear(),
          makeCode: this.sharedDataService.brandName,
          certificationStatus: this.certificationSelection,
          dealerCodes: this.sharedDataService.dealerData.map(e => {
            if (e.areaRegionName === this.regionSelection) {
              return e.dealerCode;
            }
          })
        };
      } else {
        report = {
          reportMode: '8',
          currentMonth: this.sharedFuncService.getMonth(),
          currentYear: date.getFullYear(),
          makeCode: this.sharedDataService.brandName,
          dealerCodes: []
        };
      }

    } else {
      report = {
        reportMode: '6',
        currentMonth: this.sharedFuncService.getMonth(),
        currentYear: date.getFullYear(),
        makeCode: this.sharedDataService.brandName
      };
    }
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
  }

  reportLoad(event: any) {
    this.regionSelection = event.region;
    this.certificationSelection = event.certification;
    if (event === 'm') {
      this.reportSelection = event;
      this.regionSelection = 'ALL';
      this.reportMode = event;
      this.certificationSelection = 'C';
    } else {
      this.reportMode = event.reportType;
      this.reportSelection = event.reportType;
      this.certificationSelection = event.certification;
    }
    let date = new Date();
    this.store.dispatch(
      new vehicleReportActions.ClearAllAction());
    this.dealerCodes = [];
    if (this.reportType === '9') {
      this.dealerCodes.push(event.dealerCode);
    } else if (this.reportType === '8m' &&
      (this.regionSelection === undefined || this.regionSelection === 'ALL')) {
      if (!isNil(this.sharedDataService.dealerData)) {
        this.sharedDataService.dealerData.map(e => {
          this.dealerCodes.push(e.dealerCode);
        });
      }
    } else {
      if (!isNil(this.sharedDataService.dealerData)) {
        this.sharedDataService.dealerData.map(e => {
          if (e.areaRegionName === this.regionSelection) {
            this.dealerCodes.push(e.dealerCode);
          }
        });
      }
    }

    let report;
    if (this.reportMode === 'm' ||
      (this.reportType === '8m' && this.regionSelection === undefined)) {
      report = {
        reportMode: '8',
        currentMonth: this.sharedFuncService.getMonth(),
        currentYear: date.getFullYear(),
        makeCode: this.sharedDataService.brandName,
        certificationStatus: this.certificationSelection,
        dealerCodes: this.dealerCodes
      };
    } else if (this.reportMode === 'm' &&
      (this.reportType === '8m' && this.regionSelection === 'ALL')) {
      report = {
        reportMode: '6',
        currentMonth: this.sharedFuncService.getMonth(),
        currentYear: date.getFullYear(),
        certificationStatus: this.certificationSelection,
        makeCode: this.sharedDataService.brandName
      };
    } else if (this.reportMode === 'y' &&
      (this.reportType === '8m' && this.regionSelection === 'ALL')) {
      report = {
        reportMode: '7',
        currentYear: date.getFullYear(),
        certificationStatus: this.certificationSelection,
        makeCode: this.sharedDataService.brandName
      };
    } else {
      report = {
        reportMode: '9',
        currentYear: date.getFullYear(),
        makeCode: this.sharedDataService.brandName,
        certificationStatus: 'C',
        dealerCodes: this.dealerCodes
      };
    }
    this.store.dispatch(
      new vehicleReportActions.GetInventoryReport(report));
  }

  refreshFilters() {
    this.showClearAllButton = false;
    if (!isNil(this.currentSortEvent)) {
      this.applyInventorySort(this.currentSortEvent);
    }
    this.clearSort = false;
    RegionalModelLineReportGridColumns.gridColumns.forEach(column => {
      column.filterValue = '';
    });
  }

  applyInventorySort(event: any) {
    this.currentSortEvent = event;
    this.rows =
      cloneDeep(this.sharedFuncService.applySort(this.rows, event));
  }

  ApplyFilter(filter: models.Filter) {
    let tempFilter: models.Filter = null;
    let existingFilterInArrayIndex: number = 0;
    if (this.filterArray !== null && this.filterArray !== undefined) {
      this.filterArray.forEach(data => {
        if (data.filterType === filter.filterType
          && data.columnname === filter.columnname) {
          tempFilter = data;
        }
        if (tempFilter === null) {
          existingFilterInArrayIndex++;
        }
      });
      if (tempFilter !== null && tempFilter !== undefined) {
        this.filterArray.splice(existingFilterInArrayIndex, 1);
      }
      if (filter.filterValue !== null && filter.filterValue !== undefined
        && filter.filterValue.trim() !== '') {
        tempFilter = filter;
        this.filterArray.push(tempFilter);
      }
      this.filteredData = cloneDeep(this.originalData);
      this.filterArray.forEach(arrayElement => {
        if (!isNil(arrayElement.filterValue) && arrayElement.filterValue !== undefined &&
          arrayElement.filterValue.trim() !== '') {
          if (arrayElement.filterType === models.FilterType.CONTAINS) {
            this.applyContainsFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.STARTS_WITH) {
            this.applyStartsWithFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.SPACES) {
            this.applySpacesFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.PRICE_COLUMN) {
            this.applyPriceFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.CURRENCY_COLUMN) {
            this.applyCurrencyFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.DATE_SYMBOL) {
            this.applyDateFilter(arrayElement);
          }
        }
      });
      if (this.filterArray.length > 0) {
        this.showClearAllButton = true;
      } else {
        this.showClearAllButton = false;
      }
      // if (this.filteredData.length === 0) {
      //     let test: models.UCInventorySummary = {};
      //     test.vin = '';
      //     this.filteredData.push(test);
      // }
      this.rows = cloneDeep(this.filteredData);
    }
  }

  applyContainsFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = String(this.accessProperties(inventory, filterElement.columnname));
      return !isNil(propertyValue) && propertyValue.toLowerCase().indexOf(filterValue) > -1;
    });
  }

  applyStartsWithFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = this.accessProperties(inventory, filterElement.columnname);
      return !isNil(propertyValue) &&
        String(propertyValue).toLowerCase()
          .startsWith(filterValue);
    });
  }

  applySpacesFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      return !isNil(inventory.accessoriesDetails)
        && inventory.accessoriesDetails !== undefined
        && this.accessoriesFilter(inventory.accessoriesDetails, filterValue);
    });
  }

  accessoriesFilter(accessories: models.AccessoryDetails[], value: string): boolean {
    let filterValues = String(value).split(' ');
    let returnValue = false;
    let positiveCount = 0;
    if (!isNil(accessories) && accessories.length > 0) {
      filterValues.forEach(filter => {
        if (accessories.filter(acc =>
          acc.accessssoriesCode.trim().toLowerCase() === filter ||
          acc.accessssoriesCode.trim().toLowerCase().startsWith(filter)).length > 0) {
          positiveCount++;
        }
      });
    }
    if (positiveCount === filterValues.length) {
      return true;
    }
    return false;
  }

  applyPriceFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    // if (fullFilterValue.length > 1) {
    let firstLetter = fullFilterValue.substr(0, 1);
    let filterVal = Number.parseFloat(fullFilterValue.substr(1, fullFilterValue.length));
    this.filteredData = this.filteredData.filter(inventory => {
      let propValue = this.accessProperties(inventory, filterElement.columnname);
      if (!isNil(propValue)) {
        if (firstLetter === '<') {
          return Number.parseFloat(propValue) <= filterVal;
        } else if (firstLetter === '>') {
          return Number.parseFloat(propValue) >= filterVal;
        } else if (firstLetter === '=') {
          return Number.parseFloat(propValue) === filterVal;
        } else {
          filterVal = Number.parseFloat(fullFilterValue.
            substr(0, fullFilterValue.length));
          return Number.parseFloat(propValue) === filterVal;
        }
      }
      return true;
    });
    // }
  }

  applyCurrencyFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    // if (fullFilterValue.length > 1) {
    let firstLetter = fullFilterValue.substr(0, 1);
    let filterVal = Number.parseFloat(fullFilterValue.substr(1, fullFilterValue.length));
    this.filteredData = this.filteredData.filter(inventory => {
      let propValue = this.accessProperties(inventory, filterElement.columnname);
      propValue = Number(propValue.replace(/[^0-9.-]+/g, ''));
      if (!isNil(propValue)) {
        if (firstLetter === '<') {
          return Number.parseFloat(propValue) <= filterVal;
        } else if (firstLetter === '>') {
          return Number.parseFloat(propValue) >= filterVal;
        } else if (firstLetter === '=') {
          return Number.parseFloat(propValue) === filterVal;
        } else {
          filterVal = Number.parseFloat(fullFilterValue.
            substr(0, fullFilterValue.length));
          return Number.parseFloat(propValue) === filterVal;
        }
      }
      return true;
    });
    // }
  }

  applyDateFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    if (fullFilterValue.length > 1) {
      let firstLetter = fullFilterValue.substr(0, 1);
      let filterVal = fullFilterValue.substr(1, fullFilterValue.length);
      this.filteredData = this.filteredData.filter(inventory => {
        let propValue = this.accessProperties(inventory, filterElement.columnname);
        if (!isNil(propValue)) {
          if (firstLetter === '<') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') <= d;
            }
          } else if (firstLetter === '>') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') >= d;
            }

          } else if (firstLetter === '=') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }

          } else {
            filterVal = fullFilterValue.
              substr(0, fullFilterValue.length);
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }
          }
        }
        return true;
      });
    }
  }

  accessProperties(object: any, str: string) {
    let propertiesString = str.split('.');
    let i = 0;
    propertiesString.forEach(val => {
      object = object[propertiesString[i]];
      i++;
    });
    return object;
  }

  clearAllFilters() {
    this.rows = cloneDeep(this.originalData);
    this.filteredData = cloneDeep(this.originalData);
    this.filterArray = [];
    this.refreshFilters();
  }

  // To print the Inventory grid data
  // downLoadPdf() {
  //   let inventoryData = this.inventoryGridData();
  //   let result = inventoryData.rows.reduce(function (r, a) {
  //     a.forEach(function (s, i) {
  //       let key = i === 0 ? 'headers' : 'column' + i;
  //       r[key] || (r[key] = []);
  //       r[key].push(s);
  //     });

  //     return r;
  //   }, {});

  //   const uniquers = Array.from(new Set(result.headers));

  //   let doc = new jsPDF('landscape', 'mm', 'a2');
  //   let inventoryDataCopy;
  //   uniquers.forEach(item => {
  //     inventoryDataCopy = inventoryData.rows.filter(e => e[0] === item);
  //     // doc.text(7, 15, "Overflow 'ellipsize' (default)");
  //     doc.autoTable(inventoryData.columns, inventoryDataCopy, {
  //       startY: doc.autoTable.previous.finalY + 5,
  //       styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
  //       columnStyles: { text: { columnWidth: 'auto' } },
  //       drawRow: function (row, data) {
  //         // Colspan
  //         doc.setFontStyle('bold');
  //         doc.setFontSize(12);
  //         if (row.index === 0) {
  //           doc.setTextColor(200, 0, 0);
  //           doc.rect(data.settings.margin.left, row.y, data.table.width, 10, 'S');
  //           doc.autoTableText(item,
  //             data.settings.margin.left + data.table.width / 2, row.y + row.height / 2, {
  //               halign: 'center',
  //               valign: 'middle'
  //             });
  //           data.cursor.y += 10;
  //         }
  //         // adding page
  //         if (row.index % 5 === 0) {
  //           let posY = row.y + row.height * 6 + data.settings.margin.bottom;
  //           if (posY > doc.internal.pageSize.height) {
  //             data.addPage();
  //           }
  //         }
  //       },
  //     });
  //   });
  //   doc.deletePage(1);
  //   doc.save('nationalModelWiseRDRReport.pdf');
  //   this.sharedDataService.showLoader = false;
  // }

  getReportInitData() {
    if (this.reportType === '8m' && this.reportMode === 'y' &&
      this.sharedDataService.brandName === 'TOYOTA' && this.regionSelection === 'ALL') {
      this.reportHeaderText
        = 'Toyota Certified Used Vehicles National Model Wise Yearly RDR Summary Report - [R0955C]';
      this.reportFileName = 'TCUVNationalModelWiseYearlyRDRSummaryReport' + this.getFormattedTime();
    } else if (this.reportType === '8m' && this.reportMode === 'm' &&
      this.sharedDataService.brandName === 'TOYOTA' && this.regionSelection === 'ALL') {
      this.reportHeaderText
        = 'Toyota Certified Used Vehicles National Model Wise' +
        ' Monthly RDR Summary Report - [R0954C]';
      this.reportFileName =
        'TCUVNationalModelWiseMonthlyRDRSummaryReport' + this.getFormattedTime();
    } else if (this.reportType === '8m' && this.reportMode === 'y' &&
      this.sharedDataService.brandName === 'LEXUS' && this.regionSelection === 'ALL') {
      this.reportHeaderText =
        'Lexus Certified Preowned vehicles National Model Wise Yearly' +
        ' RDR Summary Report - [R22851]';
      this.reportFileName =
        'LCPONationalModelWiseYearlyRDRSummaryReport' + this.getFormattedTime();
    } else if (this.reportType === '8m' && this.reportMode === 'm' &&
      this.sharedDataService.brandName === 'LEXUS' && this.regionSelection === 'ALL') {
      this.reportHeaderText =
        'Lexus Certified Preowned vehicles National Model Wise Monthly' +
        ' RDR Summary Report - [R22850]';
      this.reportFileName =
        'LCPONationalModelWiseMonthlyRDRSummaryReport' + this.getFormattedTime();
    } else if (this.reportType === '8m' && this.reportMode === 'm'
      && this.sharedDataService.brandName === 'LEXUS') {
      this.reportHeaderText =
        'Lexus Certified Preowned Vehicles Regional Model Line Monthly RDR Report - [R22850]';
      this.reportFileName = 'LCPORegionalModelLineMonthlyRDRReport' + this.getFormattedTime();
    } else if (this.reportType === '8m' && this.reportMode === 'm'
      && this.sharedDataService.brandName === 'TOYOTA') {
      this.reportHeaderText =
        'Toyota Certified Used Vehicles Regional Model Line Monthly RDR Report - [R0954C]';
      this.reportFileName = 'TCUVRegionalModelLineMonthlyRDRReport' + this.getFormattedTime();
    } else if (this.reportType === '8m' && this.reportMode === 'y'
      && this.sharedDataService.brandName === 'LEXUS') {
      this.reportHeaderText =
        'Lexus Certified Preowned Vehicles Regional Model Line Yearly RDR Report - [R22851]';
      this.reportFileName = 'LCPORegionalModelLineYearlyRDRReport' + this.getFormattedTime();
    } else if (this.reportType === '8m' && this.reportMode === 'y'
      && this.sharedDataService.brandName === 'TOYOTA') {
      this.reportHeaderText =
        'Toyota Certified Used Vehicles Regional Model Line Yearly RDR Report - [R0955C]';
      this.reportFileName = 'TCUVRegionalModelLineYearlyRDRReport' + this.getFormattedTime();
    } else if (this.reportType === '9' && this.reportMode === 'm'
      && this.sharedDataService.brandName === 'TOYOTA') {
      this.reportHeaderText =
        'Toyota Certified Used Vehicles Regional Model Line Monthly'
        + ' RDR Report By Dealer - [R0954D]';
      this.reportFileName = 'TCUVRegionalModelLineMonthlyRDRReportByDealer'
        + this.getFormattedTime();
    } else if (this.reportType === '9' && this.reportMode === 'y'
      && this.sharedDataService.brandName === 'TOYOTA') {
      this.reportHeaderText =
        'Toyota Certified Used Vehicles Regional Model Line Yearly RDR Report By Dealer - [R0955D]';
      this.reportFileName = 'TCUVRegionalModelLineYearlyRDRReportByDealer'
        + this.getFormattedTime();
    }
  }

  getFormattedDateForDisplay() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    return m + '/' + d + '/' + y;
  }

  getDateRange(type: string): string {
    let date = new Date();
    if (type === 'm') {
      return this.sharedFuncService.convertDateDisplay(this.reportStartDate)
        + ' THRU ' + this.sharedFuncService.convertDateDisplay(this.reportEndDate);
    } else if (type === 'y') {
      return '01/01/' + date.getFullYear() + ' THRU ' + '12/31/' + date.getFullYear();
    } else if (type === 'd') {
      return this.convertDateDisplay(date) + ' THRU ' + this.convertDateDisplay(date);
    }
  }

  convertDateDisplay(fromdate: any) {
    let date = new Date(fromdate);
    let year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }

    return month + '/' + dt + '/' + year;
  }

  downLoadPdf() {
    this.getReportInitData();
    this.reportHeaderText = this.reportHeaderText.toUpperCase();
    let inventoryData = this.inventoryGridData();
    let doc = new jsPDF('landscape', 'mm', 'a2');
    let totalPagesExp = '{total_pages_count_string}';
    doc.setFontSize(15);
    doc.text('Report Generated Date: ' + this.getFormattedDateForDisplay(), 500, 22);
    doc.setFontSize(20);
    doc.text(170, 15, ViewConstants.APPLICATION_TITLE_FOR_REPORT);
    doc.setFontSize(15);
    doc.text(180, 25, this.reportHeaderText);
    if (!isNil(this.regionSelection) && !isNil(this.certificationSelection)) {
      doc.text(230, 35, this.getDateRange(this.reportMode) + ' - ' + this.regionSelection
        + ' - ' + this.sharedFuncService.getCertificationType(this.certificationSelection));
    } else if (!isNil(this.regionSelection)) {
      doc.text(230, 35, this.getDateRange(this.reportMode) + ' - ' + this.regionSelection);
    } else {
      doc.text(230, 35, this.getDateRange(this.reportMode));
    }

    let pageContent = function (data) {
      let str = 'Page ' + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' of ' + totalPagesExp;
      }
      let pageSize = doc.internal.pageSize;
      let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.setFontSize(12);
      doc.text(str, 5, pageHeight - 10);
    };

    doc.autoTable(inventoryData.columns, inventoryData.rows, {
      startY: 40,
      styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
      columnStyles: { text: { columnWidth: 'auto' } },
      addPageContent: pageContent
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(this.reportFileName + '.pdf');
    this.sharedDataService.showLoader = false;
  }

  getFormattedTime() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let h = today.getHours();
    let mi = today.getMinutes();
    let s = today.getSeconds();
    return y + '' + m + '' + d + '' + h + '' + mi + '' + s;
  }

  // To download the xlsx in inventory screen
  downLoadXlsx() {
    this.getReportInitData();
    let filterText;
    if (!isNil(this.regionSelection)) {
      filterText = this.getDateRange(this.reportMode) + ' - ' + this.regionSelection;
    } else {
      filterText = this.getDateRange(this.reportMode);
    }
    let exportData = {
      'title': this.reportHeaderText.toUpperCase(),
      'range': filterText,
      'header': this.inventoryGridData().columns,
      'data': this.inventoryGridData().rows,
      'isTotalGrid': false,
      'totalHeader': null,
      'totalData': null,
      'generationDate': this.getFormattedDateForDisplay(),
      'fileName': 'TCUVNationalModelRDRSummary'
    };
    this.exportToExcelService.exportAsExcelFile(exportData);
    this.sharedDataService.showLoader = false;
  }

  // To download the csv in inventory screen
  downLoadCsv() {
    let tempData: any;
    let inventoryDataNew: Array<any> = [];
    this.rows.forEach(row => {
      tempData = {};
      RegionalModelLineReportGridColumns.gridColumns.forEach(column => {
        let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
        tempData[column.key] = columnField;
      });
      inventoryDataNew.push(tempData);
    });
    alasql('SELECT * INTO CSV("exceptionReport.csv",{headers:true, separator:","}) FROM ?',
      [inventoryDataNew] as any);
    this.sharedDataService.showLoader = false;

  }

  convertToNumberFromCurrency(date: string) {
    if (!isNil(date)) {
      let output;
      date = date.replace('$', '');
      date = date.replace(',', '');
      output = Number.parseFloat(date);
      return output;
    }
  }

  // To get inventory grid data
  inventoryGridData(): any {
    let columns: Array<any> = [];
    let rows: Array<any> = [];
    if (RegionalModelLineReportGridColumns.gridColumns && this.rows) {
      RegionalModelLineReportGridColumns.gridColumns.forEach(column => {
        columns.push(column.key);
      });
      let tempRows: Array<any> = [];
      this.rows.forEach(row => {
        tempRows = [];
        RegionalModelLineReportGridColumns.gridColumns.forEach(column => {
          let columnField =
            this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
          tempRows.push(columnField);
        });
        rows.push(tempRows);
      });
    }
    let temps: any = {};
    temps.rows = rows;
    temps.columns = columns;
    return temps;
  }

  findDealer() {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    this.dealerDetailsDialogService.open(
      ViewConstants.SELECT_DEALER_HEADER, this.viewContainerRef
      , this, this.loadDealerDetails).subscribe(() => {
        this.sharedFuncService.setCurrentScreenName(
          ViewConstants.INVENTORY_REPORT_SUMMARY_SCREEN_NAME);
      });
  }

  loadDealerDetails(event: any) {
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
    event.componentInstance.parent.enteredDealerCode = event.componentInstance.selectedDealerCode;

  }


  ngOnDestroy() {
    if (this.inventorySummarySubscriber) {
      this.inventorySummarySubscriber.unsubscribe();
    }
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
  }

}
