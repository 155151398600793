import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { UCRDRSummaryJson } from '../../../mock-data/RDRSummaryJson';
import { HttpClient } from '../../shared/components/helpers/http-client';
import { SharedFunctionService } from '../common/shared-function.service';
import { UCInventorySummary } from '../model/InventorySummary';
import { HttpHeaders } from '@angular/common/http';

/**
 * To invoke service method to obtain the inventory response
 */
@Injectable()
export class UCRdrSummaryService {
  // To populate the default headers
  public defaultHeaders = new HttpHeaders();;

  // To populate the Base Path
  //   protected basePath = 'http://localhost:3004/dealer-inventory';
  protected basePath = '/dealer-inventory';
  protected dealerInventoryBasePath = environment.DEALER_INVENTORY_SERVICE_URL;
  /**
   * Constructor method used to create new instance of VehicleInventoryService class
   */
  constructor(
    private sharedFuncService: SharedFunctionService
    , protected httpClient: HttpClient
  ) { }
  /**
   *
   * Returns all inventories created for the dealer
   * @param dealer Dealer code to filter by
   */
  public getInventorySummary(dealerCd: string)
    : Observable<Array<UCInventorySummary>> {
    const path = this.dealerInventoryBasePath + dealerCd + '/usedvehicles/search?mode=rdr';

    let queryParameters = new URLSearchParams();

    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //      headerParams.append('Authorization','Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions  = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };
    let CAN_MOCK: boolean = false;
    if (CAN_MOCK) {
      let tempData =
        cloneDeep(UCRDRSummaryJson.inventories.filter(inv => inv.dealerCd === dealerCd));
      return new Observable(observer => {
        observer.next(tempData);
        observer.complete();
      });
    } else {
      return this.httpClient.get(path, requestOptions)
      .pipe(map((response: any) => {
          // Hiding loader
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }), catchError((err: any) => {
          return this.sharedFuncService.handleError(err);
        }));
    }
  }
}
