import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { forkJoin, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { UCInventorySummaryJson } from '../../../mock-data/DailyRDRReportJson';
import { UCRDRReportJson } from '../../../mock-data/RDRReportJson';
import { HttpClient } from '../../shared/components/helpers/http-client';
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedFunctionService } from '../common/shared-function.service';
import { UCInventorySummaryReport } from '../model/InventorySummaryReport';
import {map} from 'rxjs/operators'
import { HttpHeaders } from '@angular/common/http';

/**
 * To invoke service method to obtain the inventory response
 */
@Injectable()
export class UCInventoryReportService {
  // To populate the default headers
  public defaultHeaders = new HttpHeaders();

  // To populate the Base Path
  //   protected basePath = 'http://localhost:3004/dealer-inventory';
  protected basePath = '/dealer-inventory';
  protected dealerInventoryBasePath = environment.DEALER_INVENTORY_SERVICE_URL;
  protected vehicleReportServiceURL = environment.VEHICLE_REPORT_SERVICE_URL;
  protected getGstSetDataURL = environment.GET_GSTSET_DATA_URL;
  /**
   * Constructor method used to create new instance of VehicleInventoryService class
   */
  constructor(
     private sharedFuncService: SharedFunctionService
    , protected httpClient: HttpClient
    , private logger: Logger
  ) { }

  public getGSTSETData(report: any): Observable<Array<UCInventorySummaryReport>> {
    this.logger.log('Executing Get All GSTSET DATA method');
    const path = environment.GET_GSTSET_DATA_URL;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;

    let requestArray: Array<any> = [];
    let requestInstance: any = {};

    let requestOptions = {
      method: 'POST',
      headers: headerParams,
      search: queryParameters
    };

    requestInstance = this.httpClient.post(path, report, requestOptions)
    .pipe(map((res) => {
        let response = res.body;
        // Hiding loader
        if (res.status === 200 || res.status === 201) {
          let tempVehicle: any = {};
          tempVehicle.type = report.reportMode;
          tempVehicle.reportData = response;
          return tempVehicle;
        } else {
          return false;
        }
      }));
    requestArray.push(requestInstance);
    return forkJoin(requestArray);
  }

  /**
   *
   * Returns all inventories created for the dealer
   * @param dealer Dealer code to filter by
   */
  public getInventoryReport(report: any)
    : Observable<Array<UCInventorySummaryReport>> {
    const path = this.vehicleReportServiceURL;

    let queryParameters = new URLSearchParams();
    let requestArray: Array<any> = [];
    let requestInstance: any = {};

    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //      headerParams.append('Authorization','Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'POST',
      headers: headerParams,
      search: queryParameters
    };
    let CAN_MOCK: boolean = false;
    if (CAN_MOCK) {
      return new Observable(observer => {
        observer.next(UCInventorySummaryJson.inventories);
        observer.complete();
      });
    } else {
      if (report.length !== undefined) {
        report.map(element => {
          let reportData: any;
          if (element.reportMode === '31' || element.reportMode === '29' || element.reportMode === '28' ||
            element.reportMode === '4' || element.reportMode === '5') {
            reportData = {
              reportMode: element.reportMode,
              fromDate: element.fromDate,
              toDate: element.toDate,
              makeCode: element.makeCode,
              from: element.from,
              dealerCodes: element.dealerCodes,
              district: element.districtSelection,
              region: element.regionSelection
            };
          } else if (element.reportMode === '33' || element.reportMode === '34') {
            reportData = {
              reportMode: element.reportMode,
              fromDate: element.fromDate,
              toDate: element.toDate,
              makeCode: element.makeCode,
              from: element.from,
              district: element.districtSelection,
              region: element.regionSelection,
              dealerCodes: element.dealerCodes,
              currentMonth: element.currentMonth,
              currentYear: element.currentYear,
              reportType: element.reportType
            };
          } else if (element.reportMode === '1' || element.reportMode === '2') {
            reportData = {
              reportMode: element.reportMode,
              reportType: element.reportType,
              fromDate: element.fromDate,
              toDate: element.toDate,
              makeCode: element.makeCode,
              from: element.from
            };
          } else {
            reportData = {
              reportMode: element.reportMode,
              fromDate: element.fromDate,
              toDate: element.toDate,
              makeCode: element.makeCode,
              from: element.from
            };
          }
          requestInstance = this.httpClient.post(path, reportData, requestOptions)
          .pipe(map((res) => {
              let response = res.body;
              // Hiding loader
              if (res.status === 200 || res.status === 201) {
                let tempVehicle: any = {};
                tempVehicle.type = report[0].reportMode;
                tempVehicle.reportData = response;
                return tempVehicle;
              } else {
                return false;
              }
            }));
          requestArray.push(requestInstance);
        });
        return forkJoin(
          requestArray
        );
      } else {
        let CAN_MOCK_NEW: boolean = false;
        if (CAN_MOCK_NEW) {
          let tempData: Array<any> = [];
          let reportData = {
            count: (UCRDRReportJson.data).length.toString(),
            data: cloneDeep(UCRDRReportJson.data),
            startDate: null,
            endDate: null
          };
          let copyData = {
            reportData: reportData
          };

          tempData.push(copyData);
          return new Observable(observer => {
            observer.next(tempData[0]);
            observer.complete();
          });
        } else {
          return this.httpClient.post(path, report, requestOptions)
          .pipe(map((res) => {
              let response = res.body;
              // Hiding loader
              if (res.status === 200 || res.status === 201) {
                let tempVehicle: any = {};
                tempVehicle.type = report.reportMode;
                tempVehicle.reportData = response;
                return tempVehicle;
              } else {
                return false;
              }
            }));
        }
      }
    }
  }
}
