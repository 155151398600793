import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep, isNil, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import * as dealerActions from '../../actions/dealer-detail.action';
import * as messageDataActions from '../../actions/message-data.action';
import * as vehicleObjectiveActions from '../../actions/vehicle-objective.action';
import * as fromRoot from '../../reducers';
import { ExportToExcelService } from '../../services/common/excel-service';
import { SharedDataService } from '../../services/common/shared-data.service';
import { SharedFunctionService } from '../../services/common/shared-function.service';
import * as models from '../../services/model/models';
import { VehicleObjectiveView } from '../../services/view-model/vehicle-objective-view';
import * as viewModels from '../../services/view-model/view-models';
import { ScreenMessages } from '../../shared/master-data/messages';
import { ViewConstants } from '../../shared/shared.constants';
import { InventorySummaryGridColumns } from './json/UCInventorySummaryGridColumns';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as moment from 'moment'
import * as alasql from 'alasql';

@Component({
  selector: 'uc-inventory',
  templateUrl: 'region-objective-page.component.html'
})

export class UCRegionObjectivePageComponent implements OnInit, OnDestroy {
  // Observable to save inventory screen data
  vehicleObjectiveData$: Observable<VehicleObjectiveView>;
  // Subscriber for Inventory Data
  inventorySummarySubscriber: any;
  // Array variable used to store columns
  public columnDefs: any[] = [];
  // Array variable to keep the original data for filter purpose
  originalData: Array<models.UCInventorySummary>;
  // Array variable to keep the filtered data for filter purpose
  filteredData: Array<models.UCInventorySummary>;
  // Array to hold the filters applied in the grid
  public filterArray: Array<models.Filter> = [];
  // Boolean to reveal whether the dealer code is selected or not
  isInventorySelected: boolean = false;
  // certificationStatus
  selectedCertificationStatus: string = '';
  // makeCode
  selectedMakeCode: string = '';
  // To store seleted vehicle index
  public selectedVehicleIndex: number = 0;
  // Boolean variable to show/hide the clear all button
  showClearAllButton: boolean;
  // Variable to display the inventories count
  totalInventoriesCount: Number = 0;
  // Dealer code entered in search toolbar
  enteredDealerCode: string;
  // vin entered in search toolbar
  enteredvin: string;
  // To hold the last sort event
  currentSortEvent: any;
  isDownloadVisible: boolean = false;
  // to clear the current sorting for all columns
  clearSort: boolean = false;
  // Observable to obtain VehicleDetailView object
  vehicleDetails$: Observable<viewModels.VehicleDetailView>;
  // Observable to obtain RdrDetailView object
  rdrDetails$: Observable<viewModels.RdrDetailView>;
  // Observable to obtain DealerDetailView object
  dealerDetailsData$: Observable<viewModels.DealerDetailView>;

  regionItems: Array<any>;
  regionRowItems: Array<any>;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  selectedMonth: string;
  selectedYear: string;
  // Private array variable to display the rows in grid
  private _rows: Array<models.UCInventorySummary>;
  // Property to set and get the rows
  get rows(): Array<models.UCInventorySummary> {
    return this._rows;
  }
  set rows(value: Array<models.UCInventorySummary>) {
    if (value.length === 1 && value[0].vin === '') {
      // this.totalInventoriesCount = 0;
    } else {
      // this.totalInventoriesCount = value.length;
    }
    this._rows = value;
  }
  // private moment = require('moment');
  // selected Disclaimer
  private row: models.UCInventorySummary = {};
  /**
   * Constructor for UCInventoryPageComponent
   */
  constructor(private store: Store<fromRoot.AppState>,
    public sharedFuncService: SharedFunctionService,
    public sharedDataService: SharedDataService,
    public router: Router,
    private viewContainerRef: ViewContainerRef,
    private cb: CurrencyPipe,
    private exportToExcelService: ExportToExcelService) {
    try {
      this.vehicleObjectiveData$ = store.select(fromRoot.getVehicleObjective);
      if (isNil(this.sharedDataService.dealerData)) {
        this.dealerDetailsData$ = store.select(fromRoot.getDealerData);
      }
      let date = new Date();
      this.selectedMonth = (date.getMonth() + 1).toString();
      this.selectedYear = (date.getFullYear()).toString();
    } catch (error) {
    } finally {
    }
  }
  /**
   * ngOnInit
   */
  ngOnInit() {
    this.onLoad();
    this.inventorySummarySubscriber = this.vehicleObjectiveData$.subscribe((data) => {
      if (!isNil(data) && data !== undefined) {
        this.refreshFilters();
        this.enteredDealerCode = '';
        this.enteredDealerCode = (<any>Object).assign(this.enteredDealerCode, '');
        this.enteredvin = '';
        this.enteredvin = (<any>Object).assign(this.enteredvin, '');
        this.isInventorySelected = false;
        this.selectedCertificationStatus = '';
        this.selectedMakeCode = '';
        if (!isNil(data.message)) {
          if (data.message.message === ScreenMessages.NO_DATA) {
            this.populateRegionData();
          } else {
            this.store.dispatch(new messageDataActions.SetDisplayMessageAction(
              data.message
            ));
          }
        } else {
          if (data.vehicleData.length > 0) {
            let copyData = data.vehicleData;
            // this.rows = cloneDeep(sortBy(copyData, 'VIN'));
            this.regionRowItems = cloneDeep(sortBy(this.formatGridData(copyData), 'name'));
            this.originalData = cloneDeep(this.regionRowItems);
            this.totalInventoriesCount = this.regionRowItems.length;
          } else {
            this.regionRowItems = cloneDeep([]);
          }
        }
      }
    });
  }

  formatGridData(data: any) {
    let dataCopy = [];
    data.forEach(element => {
      let item;
      if (this.sharedDataService.dealerData
        .filter(t => t.areaRegionNo === element.regionCode).length > 0) {
        item = {
          objective: element.objective,
          code: element.regionCode,
          makeCode: element.makeCode,
          name: this.sharedDataService.dealerData
            .filter(t => t.areaRegionNo === element.regionCode)[0].areaRegionName
        };
      } else {
        item = {
          objective: element.objective,
          code: element.regionCode,
          makeCode: element.makeCode,
          name: null
        };
      }
      dataCopy.push(item);
    });
    return dataCopy;
  }
  /**
   * onLoad method
   */
  onLoad() {
    this.columnDefs = InventorySummaryGridColumns.gridColumns;
    this.store.dispatch(new vehicleObjectiveActions.ClearAllAction());
    let defData = {
      month: this.selectedMonth,
      year: this.selectedYear
    };
    this.sharedDataService.setSelectedVehicle('');
    if (isNil(this.sharedDataService.dealerData)) {
      this.store.dispatch(new dealerActions.LoadAllDealersAction({}));
      this.dealerDetailsData$.subscribe(data => {
        if (!isNil(data) && data !== undefined) {
          if (!isNil(data.allDealers) && data.allDealers.length > 0) {
            this.sharedDataService.dealerData = cloneDeep(data.allDealers);
            this.dataLoad(defData);
            // this.populateRegionData();
          }
        }
      });
    } else {
      // this.dataLoad();
      this.dataLoad(defData);
    }
  }

  populateRegionData() {
    let regionArrayCopy = new Array<any>();
    let regionUniqueArrayCopy = new Array<any>();
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          let data = {
            name: element.areaRegionName,
            code: element.areaRegionNo,
            makeCode: element.brand
          };
          regionArrayCopy.push(data);
        }
      });
    }
    this.regionItems =
      regionArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);

    for (let i = 0; i < this.regionItems.length; i++) {
      let data = {
        name: this.regionItems[i],
        code: regionArrayCopy
          .filter(t => t.name === this.regionItems[i])[0].code,
        objective: 0
      };
      regionUniqueArrayCopy.push(data);
    }

    this.regionRowItems = sortBy(regionUniqueArrayCopy, 'name');
    this.originalData = cloneDeep(this.regionRowItems);
    this.totalInventoriesCount = this.regionRowItems.length;
  }

  dataLoad(defData: any) {
    this.regionRowItems = [];
    this.store.dispatch(new vehicleObjectiveActions.ClearAllAction());
    this.selectedMonth = defData.month;
    this.selectedYear = defData.year;
    // let d = new Date();
    let report = {
      month: this.monthNames[Number(defData.month) - 1],
      year: defData.year,
      makeCode: this.sharedDataService.brandName
    };
    this.store.dispatch(
      new vehicleObjectiveActions.GetRegionObjectiveAction(report));
  }

  saveRegionObjective() {
    this.regionRowItems = this.mergeArrays(this.originalData, this.regionRowItems);
    let request;
    request = {
      data: [],
      month: this.monthNames[Number(this.selectedMonth) - 1],
      year: this.selectedYear
    };
    this.regionRowItems.forEach(e => {
      let requestData = {
        regionCode: e.code,
        objective: e.objective,
        createdBy: this.sharedDataService.userName,
        makeCode: this.sharedDataService.brandName
      };
      request.data.push(requestData);
    });
    this.store.dispatch(
      new vehicleObjectiveActions.SaveRegionObjectiveAction(request));
  }

  refreshFilters() {
    this.showClearAllButton = false;
    if (!isNil(this.currentSortEvent)) {
      this.applyInventorySort(this.currentSortEvent);
    }
    this.clearSort = false;
    InventorySummaryGridColumns.gridColumns.forEach(column => {
      column.filterValue = '';
    });
  }

  applyInventorySort(event: any) {
    this.currentSortEvent = event;
    this.rows =
      cloneDeep(this.sharedFuncService.applySort(this.rows, event));
  }

  ApplyFilter(filter: models.Filter) {
    let tempFilter: models.Filter = null;
    let existingFilterInArrayIndex: number = 0;
    if (this.filterArray !== null && this.filterArray !== undefined) {
      this.filterArray.forEach(data => {
        if (data.filterType === filter.filterType
          && data.columnname === filter.columnname) {
          tempFilter = data;
        }
        if (tempFilter === null) {
          existingFilterInArrayIndex++;
        }
      });
      if (tempFilter !== null && tempFilter !== undefined) {
        this.filterArray.splice(existingFilterInArrayIndex, 1);
      }
      if (filter.filterValue !== null && filter.filterValue !== undefined
        && filter.filterValue.trim() !== '') {
        tempFilter = filter;
        this.filterArray.push(tempFilter);
      }
      this.filteredData = this.mergeArrays(this.originalData, this.regionRowItems);
      this.filterArray.forEach(arrayElement => {
        if (!isNil(arrayElement.filterValue) && arrayElement.filterValue !== undefined &&
          arrayElement.filterValue.trim() !== '') {
          if (arrayElement.filterType === models.FilterType.CONTAINS) {
            this.applyContainsFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.STARTS_WITH) {
            this.applyStartsWithFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.SPACES) {
            this.applySpacesFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.PRICE_COLUMN) {
            this.applyPriceFilter(arrayElement);
          } else if (arrayElement.filterType === models.FilterType.DATE_SYMBOL) {
            this.applyDateFilter(arrayElement);
          }
        }
      });
      if (this.filterArray.length > 0) {
        this.showClearAllButton = true;
      } else {
        this.showClearAllButton = false;
      }
      this.regionRowItems = cloneDeep(this.filteredData);
    }
  }

  applyContainsFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = String(this.accessProperties(inventory, filterElement.columnname));
      return !isNil(propertyValue) && propertyValue.toLowerCase().indexOf(filterValue) > -1;
    });
  }

  applyStartsWithFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      let propertyValue = this.accessProperties(inventory, filterElement.columnname);
      return !isNil(propertyValue) &&
        String(propertyValue).toLowerCase()
          .startsWith(filterValue);
    });
  }

  applySpacesFilter(filterElement: models.Filter) {
    let filterValue = filterElement.filterValue.toLowerCase().trim();
    this.filteredData = this.filteredData.filter(inventory => {
      return !isNil(inventory.accessoriesDetails)
        && inventory.accessoriesDetails !== undefined
        && this.accessoriesFilter(inventory.accessoriesDetails, filterValue);
    });
  }

  accessoriesFilter(accessories: models.AccessoryDetails[], value: string): boolean {
    let filterValues = String(value).split(' ');
    let returnValue = false;
    let positiveCount = 0;
    if (!isNil(accessories) && accessories.length > 0) {
      filterValues.forEach(filter => {
        if (accessories.filter(acc =>
          acc.accessssoriesCode.trim().toLowerCase() === filter ||
          acc.accessssoriesCode.trim().toLowerCase().startsWith(filter)).length > 0) {
          positiveCount++;
        }
      });
    }
    if (positiveCount === filterValues.length) {
      return true;
    }
    return false;
  }

  applyPriceFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    // if (fullFilterValue.length > 1) {
    let firstLetter = fullFilterValue.substr(0, 1);
    let filterVal = Number.parseFloat(fullFilterValue.substr(1, fullFilterValue.length));
    this.filteredData = this.filteredData.filter(inventory => {
      let propValue = this.accessProperties(inventory, filterElement.columnname);
      if (!isNil(propValue)) {
        if (firstLetter === '<') {
          return Number.parseFloat(propValue) <= filterVal;
        } else if (firstLetter === '>') {
          return Number.parseFloat(propValue) >= filterVal;
        } else if (firstLetter === '=') {
          return Number.parseFloat(propValue) === filterVal;
        } else {
          filterVal = Number.parseFloat(fullFilterValue.
            substr(0, fullFilterValue.length));
          return Number.parseFloat(propValue) === filterVal;
        }
      }
      return true;
    });
    // }
  }

  applyDateFilter(filterElement: models.Filter) {
    let fullFilterValue = filterElement.filterValue.toLowerCase().trim();
    if (fullFilterValue.length > 1) {
      let firstLetter = fullFilterValue.substr(0, 1);
      let filterVal = fullFilterValue.substr(1, fullFilterValue.length);
      this.filteredData = this.filteredData.filter(inventory => {
        let propValue = this.accessProperties(inventory, filterElement.columnname);
        if (!isNil(propValue)) {
          if (firstLetter === '<') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') <= d;
            }
          } else if (firstLetter === '>') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') >= d;
            }

          } else if (firstLetter === '=') {
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }

          } else {
            filterVal = fullFilterValue.
              substr(0, fullFilterValue.length);
            let d = moment(filterVal, 'MM/DD/YYYY').format('MM/DD/YYYY');
            let d1 = moment(filterVal, 'MM/DD/YYYY').isValid();
            if (d1) {
              return moment(propValue, 'MM/DD/YYYY').format('MM/DD/YYYY') === d;
            }
          }
        }
        return true;
      });
    }
  }

  accessProperties(object: any, str: string) {
    let propertiesString = str.split('.');
    let i = 0;
    propertiesString.forEach(val => {
      object = object[propertiesString[i]];
      i++;
    });
    return object;
  }

  clearAllFilters() {
    this.regionRowItems = this.mergeArrays(this.originalData, this.regionRowItems);
    this.originalData = cloneDeep(this.regionRowItems);
    this.filteredData = cloneDeep(this.originalData);
    this.filterArray = [];
    this.refreshFilters();
  }

  mergeArrays(arr1, arr2) {
    for (let i = 0, l = arr1.length; i < l; i++) {
      for (let j = 0, ll = arr2.length; j < ll; j++) {
        if (arr1[i].code === arr2[j].code) {
          arr1.splice(i, 1, arr2[j]);
          break;
        }
      }
    }
    return arr1;
  }

  getFormattedTime() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    let h = today.getHours();
    let mi = today.getMinutes();
    let s = today.getSeconds();
    return y + '' + m + '' + d + '' + h + '' + mi + '' + s;
  }

  getFormattedDateForDisplay() {
    let today = new Date();
    let y = today.getFullYear();
    let m = today.getMonth() + 1;
    let d = today.getDate();
    return m + '/' + d + '/' + y;
  }


  // To print the Inventory grid data
  downLoadPdf() {
    let fileName = 'ToyotaInventoryExceptionReportforcertifiedunits' + this.getFormattedTime();
    let inventoryData = this.inventoryGridData();
    let reportHeaderText = 'TOYOTA INVENTORY EXCEPTION REPORT FOR CERTIFIED UNITS - [R21912]';
    let doc = new jsPDF('landscape', 'mm', 'a2');
    let totalPagesExp = '{total_pages_count_string}';
    doc.setFontSize(15);
    doc.text('Report Generated Date: ' + this.getFormattedDateForDisplay(), 500, 22);
    doc.setFontSize(18);
    doc.text(180, 15, ViewConstants.APPLICATION_TITLE_FOR_REPORT);
    doc.setFontSize(15);
    doc.text(195, 25, reportHeaderText);

    let pageContent = function (data) {
      let str = 'Page ' + doc.internal.getNumberOfPages();
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' of ' + totalPagesExp;
      }
      let pageSize = doc.internal.pageSize;
      let pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      doc.setFontSize(12);
      doc.text(str, 5, pageHeight - 10);
    };

    doc.autoTable(inventoryData.columns, inventoryData.rows, {
      startY: 35,
      styles: { overflow: 'linebreak', fontSize: 13, columnWidth: 'auto' },
      columnStyles: { text: { columnWidth: 'auto' } },
      addPageContent: pageContent
    });

    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }

    doc.save(fileName + '.pdf');
    this.sharedDataService.showLoader = false;
  }

  // To download the xlsx in inventory screen
  downLoadXlsx() {
    // this.getReportInitData();
    let inventoryData = this.inventoryGridData();
    let title = 'REGION/AREA OBJECTIVE FOR ' + this.monthNames[Number(this.selectedMonth) - 1]
      + ' - ' + this.selectedYear;
    let exportData = {
      'title': title,
      'header': inventoryData.columns,
      'data': inventoryData.rows,
      'isTotalGrid': false,
      'totalHeader': null,
      'totalData': null,
      'generationDate': this.getFormattedDateForDisplay(),
      'fileName': 'regionObjective'
    };
    this.exportToExcelService.exportAsExcelFile(exportData);

    this.sharedDataService.showLoader = false;
  }

  // To download the csv in inventory screen
  downLoadCsv() {
    let tempData: any;
    let inventoryDataNew: Array<any> = [];
    this.rows.forEach(row => {
      tempData = {};
      InventorySummaryGridColumns.gridColumns.forEach(column => {
        let columnField = this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
        tempData[column.key] = columnField;
      });
      inventoryDataNew.push(tempData);
    });
    alasql('SELECT * INTO CSV("exceptionReport.csv",{headers:true, separator:","}) FROM ?',
      [inventoryDataNew] as any);
    this.sharedDataService.showLoader = false;

  }

  convertToNumberFromCurrency(date: string) {
    if (!isNil(date)) {
      let output;
      date = date.replace('$', '');
      date = date.replace(',', '');
      output = Number.parseFloat(date);
      return output;
    }
  }

  // To get inventory grid data
  inventoryGridData(): any {
    let columns: Array<any> = [];
    let rows: Array<any> = [];
    if (InventorySummaryGridColumns.gridColumns && this.regionRowItems) {
      InventorySummaryGridColumns.gridColumns.forEach(column => {
        columns.push(column.key);
      });
      let tempRows: Array<any> = [];
      this.regionRowItems.forEach(row => {
        tempRows = [];
        InventorySummaryGridColumns.gridColumns.forEach(column => {
          let columnField =
            this.sharedFuncService.recurseObjProp(row, column.fieldName, 0);
          tempRows.push(columnField);
        });
        rows.push(tempRows);
      });
    }
    let temps: any = {};
    temps.rows = rows;
    temps.columns = columns;
    return temps;
  }


  ngOnDestroy() {
    if (this.inventorySummarySubscriber) {
      this.inventorySummarySubscriber.unsubscribe();
    }
    this.store.dispatch(new vehicleObjectiveActions.ClearAllAction());
    this.store.dispatch(new messageDataActions.ClearAllDisplayMessageAction(null));
  }

}
