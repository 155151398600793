/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import * as models from '../model/models';
// TODO: correctly add the JSON so that it can be read
import { HttpClient } from '../../shared/components/helpers/http-client';
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedDataService } from '../common/shared-data.service';
// import { USE_MOCK_SERVICE } from '../constants';
import { VinInfoJson } from '../../../mock-data/VinInfoJson';
import { SharedFunctionService } from '../common/shared-function.service';
import { environment } from 'src/environments/environment';
import {catchError, map} from 'rxjs/operators'
import { HttpHeaders } from '@angular/common/http';

/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class RoleDetailService {
  public defaultHeaders = new HttpHeaders();;
  protected basePath = environment.BASE_SERVICE_URL;
  protected getRoleMappingServicePath = environment.GET_ROLE_MAPPING_SERVICE_URL;
  protected getRolesByMakeCodeServicePath = environment.GET_ROLES_BY_MAKECODE_SERVICE_URL;
  protected dealerParticipantServicePath = environment.SAVE_ROLE_MAPPING_SERVICE_URL;
  // To populate the Base Path
  // protected basePath = BASE_SERVICE_URL + '/dealer-inventory';
  /***
   * Constructor of Inventory Detail Service to inject the angluar http
   */
  constructor( private logger: Logger,
    public sharedDataService: SharedDataService,
    protected httpClient: HttpClient,
    private sharedFuncService: SharedFunctionService) { }

  /***
* This method is used to get the Vehicle Inventory Detail data
* for the given VIN number
*/
  public getRolesByMakeCode(): Observable<any[]> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path = this.getRolesByMakeCodeServicePath;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions  = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      let vehicleSummary =
        VinInfoJson.inventories.filter(veh =>
          veh.makeCode === this.sharedDataService.brandName);
      let vehicle = Object.assign({}, vehicleSummary);
      return new Observable<any[]>(observer => {
        observer.next(vehicle);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }), catchError(e => {
          if (e.status === 401) {
            return Observable.throw('Unauthorized');
          }
          // do any other checking for statuses here
        }));
    }
  }


  /***
* This method is used to get the Vehicle Inventory Detail data
* for the given VIN number
*/
  public getRoleConfiguration(role: string): Observable<any[]> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path = this.getRoleMappingServicePath + '?role=' + role;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions  = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      let vehicleSummary = VinInfoJson.inventories.filter(veh => veh.vin === role);
      let vehicle = Object.assign({}, vehicleSummary);
      return new Observable<any[]>(observer => {
        observer.next(vehicle);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
      .pipe(map((response: any) => {
          // this.logger.log(ViewConstants.VEHICLES_RESPONSE_RECEIVED_LOGGER + response);
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }), catchError(e => {
          if (e.status === 401) {
            return Observable.throw('Unauthorized');
          }
          // do any other checking for statuses here
        }));
    }
  }

  /***
 * This method is used to save the Vehicle Inventory Detail data
 * for the given VIN number
 */
  public saveRoleConfiguration(role: models.Role): Observable<models.DealerParticipant> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path = this.dealerParticipantServicePath;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions  = {
      method: 'PUT',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.DealerParticipant>(observer => {
        observer.next(role);
        observer.complete();
      });
    } else {
      return this.httpClient.put(path, role, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 201 || response.status === 200) {
            return response.body;
          } else
            return false;
        }));
    }
  }
}
