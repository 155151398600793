/***
 * Import statements required for Inventory Detail Service
 * for invoking services required for populating
 * all sections in the Vehicle Inventory Detail Screen
 */
import { Injectable } from '@angular/core';


import { Observable } from 'rxjs';
import * as models from '../model/models';
// TODO: correctly add the JSON so that it can be read
import { HttpClient } from '../../shared/components/helpers/http-client';
import { Logger } from '../../shared/components/helpers/Logger';
import { SharedDataService } from '../common/shared-data.service';
// import { USE_MOCK_SERVICE } from '../constants';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import { VinInfoJson } from '../../../mock-data/VinInfoJson';
import { SharedFunctionService } from '../common/shared-function.service';
import { environment } from 'src/environments/environment';
import {map, catchError} from 'rxjs/operators'
import { HttpHeaders } from '@angular/common/http';

/***
 * Class that is used to invoke the Services for populating
 * all the sections of Vehicle Inventory Detail screen
 */
@Injectable()
export class VehicleDetailsService {
  public defaultHeaders = new HttpHeaders();
  protected basePath = environment.BASE_SERVICE_URL;
  protected getVehicleServicePath = environment.GET_VEHICLE_SERVICE_URL;
  protected getVinInfoServicePath = environment.GET_VIN_INFO_SERVICE_URL;
  protected saveVehicleServicePath = environment.SAVE_VEHICLE_SERVICE_URL;
  protected updateVehicleServicePath = environment.UPDATE_VEHICLE_SERVICE_URL;
  protected deleteVehicleServicePath = environment.DELETE_VEHICLE_SERVICE_URL;
  protected certificationFromSale = environment.CERT_FROM_SALE_SERVICE_URL;
  // To populate the Base Path
  // protected basePath = BASE_SERVICE_URL + '/dealer-inventory';
  /***
   * Constructor of Inventory Detail Service to inject the angluar http
   */
  constructor( private logger: Logger,
    public sharedDataService: SharedDataService,
    protected httpClient: HttpClient,
    private sharedFuncService: SharedFunctionService) { }

  /***
   * This method is used to get the Vehicle Inventory Detail data
   * for the given VIN number
   */
  public getVehicleByVin(vehicle: models.Vehicle): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    let path = this.getVehicleServicePath + '?vin=' + vehicle.vin;
    if (!isNil(vehicle.dealerCd)) {
      path = path + '&dealerCd=' + vehicle.dealerCd;
    }
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      // let vehicleSummary =
      //     UCInventorySummaryJson.inventories.filter(veh => veh.vin === vehicleData.vin);
      // let vehicleData = Object.assign({}, vehicleSummary);
      // return new Observable<models.Vehicle>(observer => {
      //     observer.next(vehicleData);
      //     observer.complete();
      // });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }), catchError((err: any) => {
          return this.sharedFuncService.handleError(err);
        }));
    }
  }

  /***
  * This method is used to get the Vehicle Inventory Detail data
  * for the given VIN number
  */
  public getVinInfo(vin: string): Observable<any[]> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path = this.getVinInfoServicePath + vin;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      let vehicleSummary = VinInfoJson.inventories.filter(veh => veh.vin === vin);
      let vehicle = Object.assign({}, vehicleSummary);
      return new Observable<any[]>(observer => {
        observer.next(vehicle);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }));
    }
  }

  /***
 * This method is used to save the Vehicle Inventory Detail data
 * for the given VIN number
 */
  public saveVehicle(vehicle: models.Vehicle): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path = this.saveVehicleServicePath;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'POST',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.Vehicle>(observer => {
        observer.next(vehicle);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.post(path, vehicle, requestOptions)
      .pipe(map((res: any) => {
          let response = res.body;
          if (res.status === 201 || res.status === 200) {
            if (response.certificationNumber !== undefined) {
              let tempVehicle = cloneDeep(vehicle);
              tempVehicle.certificationNumber = response.certificationNumber;
              tempVehicle.warrantyCode = response.certification.warrantyCode;
              tempVehicle.certificationStatus =
                response.certification.certificationStatus;
              tempVehicle.deCertifyReason =
                response.certification.deCertifyReason;
              tempVehicle.isTradedVehicle = response.isTradedVehicle;
              if (isNil(tempVehicle.certification)) {
                tempVehicle.certification =
                  <models.CertificationDetails>new Object();
                tempVehicle.certification.certificationDate =
                  response.certification.certificationDate;
                tempVehicle.certification.forceCertificationFlag =
                  response.certification.forceCertificationFlag;
                tempVehicle.certification.warrantyCode =
                  response.certification.warrantyCode;
                tempVehicle.certification.warrantyExpiryDate =
                  response.certification.warrantyExpiryDate;
                if (this.sharedDataService.isTMEXUser) {
                  tempVehicle.certification.planAmount =
                    response.certification.planAmount;
                }
              } else {
                tempVehicle.certification.certificationDate =
                  response.certification.certificationDate;
                tempVehicle.certification.forceCertificationFlag =
                  response.certification.forceCertificationFlag;
                tempVehicle.certification.warrantyCode =
                  response.certification.warrantyCode;
                tempVehicle.certification.warrantyExpiryDate =
                  response.certification.warrantyExpiryDate;
                if (this.sharedDataService.isTMEXUser) {
                  tempVehicle.certification.planAmount =
                    response.certification.planAmount;
                }
              }

              if (!isNil(tempVehicle.repairOrder)) {
                tempVehicle.repairOrder.technicianId =
                  response.repairOrder.technicianId;
                tempVehicle.repairOrder.repairOrderNumber1 =
                  response.repairOrder.repairOrderNumber1;
                tempVehicle.repairOrder.reconditionalEndDate =
                  response.repairOrder.reconditionalEndDate;
                tempVehicle.repairOrder.reconditionCost =
                  response.repairOrder.reconditionCost;
              }

              return tempVehicle;
            } else {
              let tempVehicle = cloneDeep(vehicle);
              tempVehicle.certificationStatus = 'U';
              tempVehicle.deCertifyReason = '';
              return tempVehicle;
            }


          } else
            return false;
        }));
    }
  }

  /***
  * This method is used to update the Vehicle Inventory Detail data
  * for the given VIN number
  */
  public updateVehicle(vehicle: models.Vehicle): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    let path =
      this.updateVehicleServicePath + '?vin=' + vehicle.vin + '&dealerCd=' + vehicle.dealerCd;
    if (!isNil(vehicle.certificationNumber) && !isEmpty(String(vehicle.certificationNumber))) {
      path = path + '&certificateNumber=' + vehicle.certificationNumber;
    } else {
      path = path + '&certificateNumber=0';
    }

    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'PUT',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.Vehicle>(observer => {
        observer.next(vehicle);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.put(path, vehicle, requestOptions)
      .pipe(map((res: any) => {
          let response = res.body;
          if (res.status === 200 || res.status === 201) {
            if (response.certificationNumber !== undefined) {
              let tempVehicle = cloneDeep(vehicle);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              tempVehicle.certificationNumber =
                response.certificationNumber;
              tempVehicle.certificationStatus =
                response.certification.certificationStatus;
              tempVehicle.deCertifyReason =
                response.certification.deCertifyReason;

              if (isNil(tempVehicle.certification)) {
                tempVehicle.certification =
                  <models.CertificationDetails>new Object();
                tempVehicle.certification.certificationDate =
                  response.certification.certificationDate;
                tempVehicle.certification.forceCertificationFlag =
                  response.certification.forceCertificationFlag;
                tempVehicle.certification.warrantyCode =
                  response.certification.warrantyCode;
                tempVehicle.certification.warrantyExpiryDate =
                  response.certification.warrantyExpiryDate;
                if (this.sharedDataService.isTMEXUser) {
                  tempVehicle.certification.planAmount =
                    response.certification.planAmount;
                }
              } else {
                tempVehicle.certification.certificationDate =
                  response.certification.certificationDate;
                tempVehicle.certification.forceCertificationFlag =
                  response.certification.forceCertificationFlag;
                tempVehicle.certification.warrantyCode =
                  response.certification.warrantyCode;
                tempVehicle.certification.warrantyExpiryDate =
                  response.certification.warrantyExpiryDate;
                if (this.sharedDataService.isTMEXUser) {
                  tempVehicle.certification.planAmount =
                    response.certification.planAmount;
                }
              }

              if (!isNil(tempVehicle.repairOrder)) {
                tempVehicle.repairOrder.technicianId =
                  response.certification.technicianId;
              }

              return tempVehicle;
            } else {
              let tempVehicle = cloneDeep(vehicle);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              if (!isNil(response.Code)) {
                tempVehicle.code = response.Code;
                tempVehicle.desc = response.desc;
                tempVehicle.deCertifyReason = response.deCertifyReason;
              }
              return tempVehicle;
            }
          } else {
            return false;
          }
        }));
    }
  }

  /***
* This method is used to save the Vehicle Inventory Detail data
* for the given VIN number
*/
  public deleteVehicle(vehicle: models.Vehicle): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    const path =
      this.deleteVehicleServicePath + '?vin=' + vehicle.vin + '&dealerCd=' + vehicle.dealerCd;
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'DELETE',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.Vehicle>(observer => {
        observer.next(vehicle);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.delete(path, vehicle, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 201 || response.status === 200) {
            return response.body;
          } else
            return false;
        }));
    }
  }

  /***
   * This method is used to get the Vehicle Inventory Detail data
   * for the given VIN number
   */
  public getRdrByVin(rdr: models.Rdr): Observable<models.Vehicle> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    let path = this.getVehicleServicePath + '?vin=' + rdr.vin;
    if (!isNil(rdr.dealerCd)) {
      path = path + '&dealerCd=' + rdr.dealerCd;
    }
    path = path + '&mode=rdr';
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      // let vehicleSummary =
      //     UCInventorySummaryJson.inventories.filter(veh => veh.vin === vehicle.vin);
      // let vehicle = Object.assign({}, vehicleSummary);
      // return new Observable<models.Vehicle>(observer => {
      //     observer.next(vehicle);
      //     observer.complete();
      // });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }), catchError((err: any) => {
          return this.sharedFuncService.handleError(err);
        }));
    }
  }

  /***
   * This method is used to get the Vehicle Inventory Detail data
   * for the given VIN number
   */
  public getSaleByVin(rdr: models.Rdr): Observable<models.Rdr> {
    // this.logger.log(ViewConstants.VEHICLE_SERVICE_VIN_METHOD_LOGGER);
    let path = this.getVehicleServicePath + '?vin=' + rdr.vin;
    if (!isNil(rdr.dealerCd)) {
      path = path + '&dealerCd=' + rdr.dealerCd;
    }
    if (!isNil(rdr.retailDate)) {
      path = path + '&retailDate=' + rdr.retailDate;
    }
    path = path + '&mode=sold';
    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'GET',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      // let vehicleSummary =
      //     UCInventorySummaryJson.inventories.filter(veh => veh.vin === vehicle.vin);
      // let vehicle = Object.assign({}, vehicleSummary);
      // return new Observable<models.Vehicle>(observer => {
      //     observer.next(vehicle);
      //     observer.complete();
      // });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.get(path, requestOptions)
      .pipe(map((response: any) => {
          if (response.status === 204) {
            return undefined;
          } else {
            return response.body;
          }
        }), catchError((err: any) => {
          return this.sharedFuncService.handleError(err);
        }));
    }
  }

  /***
 * This method is used to update the Vehicle Inventory Detail data
 * for the given VIN number
 */
  public certifyVehicleFromSale(vehicle: models.Rdr): Observable<models.Rdr> {

    let path = this.certificationFromSale;

    let queryParameters = new URLSearchParams();
    let headerParams = this.defaultHeaders;
    // if (this.adalSvc.userInfo.authenticated) {
    //     headerParams.append('Authorization', 'Bearer ' + this.adalSvc.userInfo.token);
    // }
    let requestOptions = {
      method: 'PUT',
      headers: headerParams,
      search: queryParameters
    };

    let CAN_MOCK = false;
    if (CAN_MOCK) {
      return new Observable<models.Vehicle>(observer => {
        observer.next(vehicle);
        observer.complete();
      });
    } else {
      // this.logger.log(ViewConstants.GET_VEHICLES_BY_VIN_SERVICE_LOGGER + path);
      return this.httpClient.put(path, vehicle, requestOptions)
      .pipe(map((res: any) => {
          let response = res.body;
          if (res.status === 200 || res.status === 201) {
            if (response.certificationNumber !== undefined) {
              let tempVehicle = cloneDeep(vehicle);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              tempVehicle.certificationNumber =
                response.certificationNumber;
              tempVehicle.certificationStatus =
                response.certification.certificationStatus;
              tempVehicle.deCertifyReason =
                response.certification.deCertifyReason;

              if (isNil(tempVehicle.certification)) {
                tempVehicle.certification =
                  <models.CertificationDetails>new Object();
                tempVehicle.certification.certificationDate =
                  response.certification.certificationDate;
                tempVehicle.certification.forceCertificationFlag =
                  response.certification.forceCertificationFlag;
                tempVehicle.certification.warrantyCode =
                  response.certification.warrantyCode;
                tempVehicle.certification.warrantyExpiryDate =
                  response.certification.warrantyExpiryDate;
                if (this.sharedDataService.isTMEXUser) {
                  tempVehicle.certification.planAmount =
                    response.certification.planAmount;
                }
              } else {
                tempVehicle.certification.certificationDate =
                  response.certification.certificationDate;
                tempVehicle.certification.forceCertificationFlag =
                  response.certification.forceCertificationFlag;
                tempVehicle.certification.warrantyCode =
                  response.certification.warrantyCode;
                tempVehicle.certification.warrantyExpiryDate =
                  response.certification.warrantyExpiryDate;
                if (this.sharedDataService.isTMEXUser) {
                  tempVehicle.certification.planAmount =
                    response.certification.planAmount;
                }
              }

              if (!isNil(tempVehicle.repairOrder)) {
                tempVehicle.repairOrder.technicianId =
                  response.certification.technicianId;
              }

              return tempVehicle;
            } else {
              let tempVehicle = cloneDeep(vehicle);
              tempVehicle.code = undefined;
              tempVehicle.desc = undefined;
              if (!isNil(response.Code)) {
                tempVehicle.code = response.Code;
                tempVehicle.desc = response.desc;
                tempVehicle.deCertifyReason = response.deCertifyReason;
              }
              return tempVehicle;
            }
          } else {
            return false;
          }
        }));
    }
  }
}
